<template>
  <div class="header">
    <el-container>
      <el-header>
        <Nav></Nav>
      </el-header>
      <el-main style="overflow-x:hidden">
        <router-view></router-view>
        <Sidebar></Sidebar>
        <Top></Top>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import Sidebar from '@/components/Sidebar'
import Top from '@/components/Top.vue'
export default {
  components:{
    Nav,
    Footer,
    Sidebar,
    Top
  }
}
</script>
<style lang="less" scoped>
.el-container,
.el-header{
  padding: 0 !important;
  position: sticky;
  z-index: 9999;
  top: 0;
}
.el-main{
   max-width: 100%;
  min-width: 3300px;
  min-height:400px;
  margin-top: -150px;
  padding: 0 !important;
}
.el-footer{
  padding: 0 !important;
}

</style>