<template>
  <div class="subroute">
    <van-row gutter="24"  type="flex" justify="space-between">
      <van-col span="6">
        <router-link to="/system?active=one" @click.native="doSomething('one')" title="系统介绍"   :class="active==='one'?classActive:classUnActive" >系统介绍</router-link>
      </van-col>
      <van-col span="6">
        <router-link to="/waterway?active=two"  @click.native="doSomething('two')" title="水路供暖智控系统"    :class="active==='two'?classActive:classUnActive"  >水路供暖智控系统</router-link>
      </van-col>
      <van-col span="6">
        <router-link to="/circuit?active=three"  @click.native="doSomething('three')" title='电路供暖智控系统'    :class="active==='three'?classActive:classUnActive"  >电路供暖智控系统</router-link>
      </van-col>
    </van-row>                       
    <div class="main">    
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
 
  data(){
    return {
      active:this.$route.query.active,
      classActive:"classActive",
      classUnActive:"classUnActive"
    }
  },
  created(){

  },
 
  methods: {
    doSomething(st){
      this.active=st
    }
  },
}
</script>
<style lang="less" scoped>
.van-row{
  width: 1200Px;
  margin: 60px auto;
}
 a {
  color: #f56a00;
  background: #ffffff;
  border: 1px solid #f56a00;
  display: block;
  width: 100%;
  height: 98Px;
  line-height: 98Px;
  font-size: 22Px;
  text-align: center;
  border-radius: 10Px;
}
 a.router-link-exact-active{
	background-color: #f56a00;
	color: #fff;
}
.classActive:hover{
     background-color: #f56a00c2;
    color: #fff;
}
.classUnActive:hover{
    background-color: #f56a00;
    color: #fff;
}
</style>