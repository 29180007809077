// 燃气锅炉
<template>
    <div class="container">
        <div class="pho">
      <img :src="require('@/assets/css/banner/ditu.png')" alt="" class="ditu"><img :src="require('@/assets/css/banner/tubiao.png')" alt="" class="tubiao"><div class="div"><div class="box">低碳清洁供暖<br>LOW CARBON CLEAN HEATING</div><div class="p">分布式 多热源 冷暖一体化服务</div></div>
    </div>
        <Primary :title='title'></Primary>
        <Main :text='text'></Main>
        <Subroute></Subroute>
        <Secondary :title='title1'></Secondary>
        <Print :text='text1'></Print>
        <Pho :IMG='img3'></Pho>
        <Secondary :title='title2'></Secondary>
        <Print :text='text2'></Print>
        <Pho :IMG='img4'></Pho>
        <div class="P">
            <p>燃气锅炉智控系统可兼容全类型的燃气锅炉，包括与合作厂商共同研发的全预混低氮冷凝燃气锅炉。瑞海全预混低氮冷凝燃气锅炉通过对烟气冷凝热交换，降低排烟温度，从而回收热能。在水蒸气转化为液体的过程中，许多潜热被抓取且再次加热，提高热效率，所产生的热效率最高甚至可达109%。全预混低氮冷凝燃气锅炉采用的全混热燃烧技术与大尺寸的管道设计，即使长时间使用，也不用担心水垢导致的管道堵塞，降低维护成本同时，延长了使用寿命。<br>
            燃气锅炉智控系统，节能环保、热效率高，可同时满足用户的生活用水和供暖需求。在天然气充足的地区和大型集中供暖项目中优势十分明显，非常适用于居民区集中供暖、大型商场、大型仓库、大型会议中心等客户。<br>
            瑞海热控的锅炉低氮改造也是一大亮点。为达到低氮改造最好的结果，瑞海热控利用先进的全预混低氮冷凝技术。通过超前的冷凝热回收技术，最大限度利用燃烧产生的热能。在燃烧前，预先将燃料与空气按照最优配比混合，全面提升了燃烧效率，提供给锅炉以充足的动力。降低燃烧副产物中燃料带来的杂质，提高尾气纯度。同时利用将出口烟气中的热量最大化回收，大幅度提升热效率。
            </p>
        </div>
        <Secondary :title='title4'></Secondary>
        <van-row gutter="20" v-lazy-container="{ selector: 'img' }">
          <van-col span="12"><img :data-src='require("@/assets/css/燃气锅炉/居民住宅.png")'><p>无论是新旧居民区的集中供暖，燃气锅炉智控系统都是基于传统集中供暖最好的升级方案。兼容现有集中供暖的管道，无需复杂的重建工作，升级成本低。而瑞海燃气锅炉智控系统基于对热量消耗的精准计算，随时调整燃气锅炉机组的功率。可以做到使用成本低廉、温度控制精确和升级工程简单。</p></van-col>
          <van-col span="12"><img :data-src='require("@/assets/css/燃气锅炉/商场.png")'><p>对于大型商场、大型仓库、大型会议中心等大型客户，我们则推荐使用智能冷凝燃气锅炉系统。智能冷凝燃气锅炉在能耗上具有系统优势，发热效率更高、维护更轻松简便，这使得长期使用成本更加低廉。此外，还能做到同时供应高低温不同温度的热水，一站式满足商业用水和采暖用水的需求。</p></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/jiatinggongnuan.png")'>
             <b>家庭供暖</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/xizao.png")'>
             <b>洗澡热水</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/shenghuo.png")'>
             <b>日常生活</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/yiyuan.png")'>
             <b>医院</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/jiudian.png")'>
             <b>酒店</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/shangchang.png")'>
             <b>商场</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/燃气锅炉/xuexiao.png")'>
             <b>学校</b>
         </van-col>
        </van-row>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Main from '@/common/main.vue'
import Subroute from '../common/Subroute.vue'
import Secondary from '../common/secondary.vue'
import Print from '../common/print.vue'
import Pho from '../common/pho.vue'
export default {
    components:{
       Primary,
       Main,
       Subroute,
       Secondary,
       Print,
       Pho
    },
    data() {
        return {
            deviceType: '',
            // videoUrl:require('@/assets/clear.mp4'),
           activeName: 'gas',
           title:'清洁供暖',
        //    text:'瑞海热控的空气源热泵系统针对市场需求和智能化进步，不断革新升级，采用创新双极压缩机和喷气增焓技术，大幅提升了机组整体性能。实现了一台气源热泵机组，即可提供冷/热双供空调、地暖、热水供应、恒温储能等多功能一体化服务。',
           title1:'燃气锅炉系列',
           text1:'燃气锅炉系统解决方案采用全预混冷凝锅炉，冷凝燃气锅可充分回收烟气中的余热和地凝结潜热，热效率将大幅提高，满足国家低氮排放标准，具有高效节能、清洁环保两大优势。',
           img3:require('@/assets/css/燃气锅炉/data.png'),
           title2:'燃气锅炉智控',
           text2:'燃气锅炉智控系统能整合多个燃气锅炉机组和控制终端，协调各终端实际温度和设定温度，制定出终端热量供应方案。燃气锅炉智控系统根据实时监测的出/回水口温度、流速、流量等相关信息，调节燃气锅炉的运行数量，从而调整整体锅炉机组的燃烧功率和出水口温度，做到精确供暖，降低在各个环节的热损耗，杜绝由于过度加热而造成的浪费。',
           img4:require('@/assets/css/燃气锅炉/data1.png'),
           title4:'经典应用场景',
           img5:require('@/assets/css/燃气锅炉/RQGL.jpg')
        }
    },
    mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
    methods:{
         _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
    }
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin:auto;
    overflow: hidden;
    position: relative;
    .ditu{
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
     .tubiao{
      position: relative;
       width: 100%;
       height: 100%;
        top: -15Px;
        left: 0;
        bottom: 0;
        right: 0;
        margin-top: -10000px;
        z-index: 22;
    }
    .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    }
}
ul{
    width: 1200Px;
    margin: 0 auto;
    display: flex;
    
}
.L{
    margin-left: 15Px;
}
ul li{
    width: 130Px;
    float: left;
    text-align: center;
}
img{
  width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.van-row{
    width: 1200Px;
    margin: 20Px auto;
    text-align: center;
}
.van-row p{
    text-align: justify;
    text-align-last: left; 
    font-size: 14Px;
}
.van-row b{
    text-align: center;
    font-weight: normal;
    font-size: 14Px;
}
.P{
    width: 1200Px;
    margin: 0 auto;
}
i{
    text-align: center;
    font-style: normal;
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>