<template>
    <div>
        <div class="vishow">
            <video width="450" height="257" :src='videoUrl2' :poster=" vimg2 " controls ref="videoPlays"></video>
        </div>
    </div>
</template>
<script>


export default {
    data(){
        return{
            videoUrl2:require('@/assets/sxsw.mp4'),
        }
    }
    
}
</script>