// 标题+文字+图片
<template>
    <div class="main">
        <div class="m">
            <div class="title">
            <h1>{{Title}}</h1>
        </div>
        <div class="text">
            <p>{{Text}}</p>
        </div>
        <div class="img" v-lazy-container="{ selector: 'img' }">
            <img :data-src="IMG" alt="">
        </div>
        </div>
        
    </div>
</template>
<script>
export default {
  props: {
    Title: {
      type: String,
    },
    Text:{
        type:String
    },
    IMG: {
      type: String
    },
  }
}
</script>
<style lang="less" scoped>
.main{
    width: 100%;
    padding: 30Px 10Px 30Px 10PX;
    // height: 100%;
    box-sizing: border-box;
    
}
.m{
    width: 1200Px;
    margin: 0 auto;
}
.title h1{
    text-align: center;
 font-size: 22Px;
 color: #000000;
 font-weight: normal;
}
.text p{
    font-size: 14Px;
    text-align-last: left;
}
.img img{
    width: 90%;
    padding:20Px 5%;
    box-sizing: border-box;
    // width: 920Px;
}
</style>