<template>
  <div class="subroute">
    <van-row gutter="24"  type="flex" justify="space-between">
      <van-col span="6">
        <router-link to="/heating-cable?active=one" @click.native="doSomething('one')" title="发热电缆系列" name="heat"  :class="active==='one'?classActive:classUnActive">发热电缆系列</router-link>
      </van-col>
      <van-col span="6">
      <router-link to="/gas-boiler?active=two" @click.native="doSomething('two')" title="燃气锅炉系列" name="gas" :class="active==='two'?classActive:classUnActive" >燃气锅炉系列</router-link>
      </van-col>
      <van-col span="6">
      <router-link to="/air-source?active=three"  @click.native="doSomething('three')" title='空气源系列' name='air' :class="active==='three'?classActive:classUnActive">空气源系列</router-link>
      </van-col>
      </van-row>       
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
 
  data(){
    return {
      active:this.$route.query.active,
      classActive:"classActive",
      classUnActive:"classUnActive"
    }
  },
  created(){

  },
 
  methods: {
    doSomething(st){
      this.active=st
    }
  },
}
</script>
<style lang="less" scoped>
.van-row{
  width: 1200Px;
  margin: 60px auto;
}
 a {
  color: #f56a00;
  background: #ffffff;
  border: 1px solid #f56a00;
  display: block;
  width: 100%;
  height: 98Px;
  line-height: 98Px;
  font-size: 22Px;
  text-align: center;
  border-radius: 10Px;
}
 a.router-link-exact-active{
	background-color: #f56a00;
	color: #fff;
}
.classActive:hover{
     background-color: #f56a00c2;
    color: #fff;
}
.classUnActive:hover{
    background-color: #f56a00;
    color: #fff;
}
// .subroute {
//   width: 1200Px;
//   margin: 0 auto;
//   margin-bottom: 60Px;
//   margin-top: 60Px;
//   display: flex;
//   justify-content: space-evenly;
// }
// router-link{
//     float: left;
    
// }
//  a {
//   color: #f56a00;
//   float: left;
//   background: #ffffff;
//   border: 1px solid #f56a00;
//   width: 298Px;
//   height: 98Px;
//   line-height: 98Px;
//   font-size: 22Px;
//   text-align: center;
//   border-radius: 10Px;
// }
// .R{
//     margin-left: 150Px;
// }
//  a.router-link-exact-active{
// 	background-color: #f56a00;
// 	color: #fff;
// }
// a:hover {
//     background-color: #f56a00;
//     color: #fff;
// }
// .one:hover{
//   background-color: #56ba44;
//     color: #fff;
// }
</style>