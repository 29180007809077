// 精彩案例
<template>
    <div class="container">
        <!-- <div class="img">
            <img :src='require("@/assets/css/banner/1.png")'>
        </div> -->
              <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt="">
            <div class="div"><div class="box">精彩案例<br>CASE STUDY</div>
            <!-- <div class="p">空气源 发热电缆 燃气锅炉</div> -->
            </div>
                <!-- <div class="text">随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。</div></div> -->
            <!-- <img :src='require("@/assets/css/banner/1.png")'> -->
        </div>
        <Primary :title='title'></Primary>
        <Secondary :title="stitle1"></Secondary>
        <van-row type="flex" justify="space-between"  name='school' id='school'>
           <van-col span="12">
               <i>太原学院新校区综合供暖项目</i>
               <p>
                   <b>建筑类型：</b>综合学校<br>
<b>功能区域：</b>教学区，体育馆，食堂，图书馆，宿舍<br>
<b>项目特点：</b>鉴于新校区地理位置偏远，配套化设施建设和补给工程较为匮乏，各功能区域（食堂、宿舍、图书馆等）建筑材质和人员流动的差异性背景。整体供热建设方案需要在规避高投资、大工时、长距离输热管网的前提下，满足学院区域型、周期性、差异化、精细向、发展式用热需求。<br>
<b>使用成果：</b>瑞海热控作为国家级“校园清洁供热中心”，在太原学院采用的分布式多元化供热技术受获中国民族建筑研究会供热采暖委员会认证。同时技术从低碳性、先进性、成熟性、推广性等方面综合评定，均受山西省土木建筑学会评审认可，适宜在我省校园及综合型建筑区域大范围推广。
项目标志着融合型供热技术的成熟，规避了传统分布式供热在单一热源模式下无法满足复杂用热需求的窘境。为区域建设适配灵活的多元供热体系，形成协同运行的成功模式进行了深度探索。最大程度满足了不同建筑类型对供暖差异化、适配度、整体性要求，为综合场景分布式集中供热发展提供了技术成熟的样板范例。为我省奠定了经典的分布式多元化供热案例，是校园清洁供热顺应“双碳”目标发展的积极革新。<br>
               </p>
           </van-col>
          
           <van-col span="10"><img :src='img1' class="st"></van-col>
        </van-row>
         <van-row type="flex" justify="space-between">
           <van-col span="12"><img :src="img18" class="st"></van-col>
           <van-col span="12">
               <!-- <i>忻州市某医院</i> -->
               <p>
                   <b>热源建设方案: </b>瑞海热控公司经过大量的实地考察和方案推敲，在综合分析校区30万平方米供热覆盖范围内各建筑类型、人员使用情况及散热规律后，提出以分布式为手段，多元化适配的供热建设方案。并通过物联网云平台架构的智慧供热系统将智慧蓄热式发热电缆与智能控制空气源热泵两种清洁供热模式进行有机结合、统筹管理。整体供热系统不仅适配于校园现有建筑，也可匹配学院未来综合型发展规划。<br>
<b>热源方案优势：</b>在人员使用时间段较为固定的建筑区域，我们选择布设具有热效率高、清洁环保、可差异化定制、即开即停、智能分时分区供热的分布式智慧蓄热式发热电缆建设运行方案。在实际运行过程中，可根据具体区域使用时间段，由智控系统进行分析调整，按需精准供热。在校内保障型建筑区域，用热时间段无明显差异，需要全天恒温供热。在该区域类型我们采用了具有循环保障、节能性高、能效比显著、末端散热形式灵活的智能控制空气源热泵分布式集中供热方案。
上述两种供热方案，都具有蓄热属性，但由于散热模式差异，适配建筑类型也不相同。如在层高较高、单位热负荷需求较大、楼体散热较强的接待中心、图书馆等校园公共建筑类型区域，从初投资、散热形式、节能性及热效率综合分析，更适宜采用智能控制空气源热泵供热模式，并在末端配套风机盘管系统。而在层高较低、楼体保温性较好的建筑类型区域，适宜地暖散热模式的智慧蓄热式发热电缆模式。
               </p>
           </van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
           <van-col span="12">
               <!-- <i>太原学院新校区综合供暖项目</i> -->
               <p>
                   

<b>智控系统优势：</b>在太原学院项目中，瑞海热控采用自主研发的智慧供热管理系统将分布式、多元化供暖设备融会贯通，为太原学院提供了高质量、有保障的供暖服务。经过不断地适配化升级，目前智慧供热管理系统已完全兼容蓄热式发热电缆、空气源热泵在内的多种热源模式，以满足校园内多种应用场景的细分需求。由于不同热源模式在控制端的差异化较大，系统会根据热源模式的特性和供热场景的需求进行智能匹配，从规划模型解集中选出在经济性、节能性和舒适性角度综合适配的最优方案，不同热源之间灵活配置、相互拟补，以点面结合的形式完成供热立体辐射，完全满足了针对校园复杂场景热需求差异化的整体供给。<br>
瑞海热控创新性的为太原学院提供了两种智能供热模式：分时段智慧供热和响应式智慧供热。在分时段智慧供热模式中，智慧供热管理系统将对供热区域进行了智能划分，制定相应的分类行分时段的温度调节计划。分时段智慧供热可以结合区域时段间用热规律形成加热、蓄热逻辑， 充分分析材料蓄热系数，结合算法对区域分时段热需量进行平衡，达到增效节能目的。针对特殊供暖需求，智慧供热管理系统根据实时监测的温度与环境信息，采用均衡调节算法，提供响应式智慧供热。两种智慧供暖模式可以平衡项目的节能性与舒适度。智能运维模块与BIM可视化平台的融入，更加保障了供暖服务的稳定、周到、高效。<br>
               </p>
           </van-col>
          
           <van-col span="12"><img :src='img17' class="b"></van-col>
        </van-row>
        <Secondary :title="stitle2"></Secondary>
        <van-row type="flex" justify="space-between">
           <van-col span="10"><img :src='img2'></van-col>
           <van-col span="12">
               <i>忻州市某医院</i>
               <p><b>建筑类型：</b>综合型医院<br>
               <b>功能区域:</b>住院部、康复部、化验室、办公室、手术室<br>
                   <b>项目特点:</b> 该医院是一所集医疗、保健、急救、康复为一体的综合型医院。不同的场景对环境温度有精细的调控需求。医院除常规制冷、采暖需求外，还需配套提供生活热水及医用消毒蒸汽等服务。多能统筹应用下的精细化服务保障，是该项目主要难点。<br>
<b>使用效果:</b> 项目采用空气源热泵作为冷、热源设备，以分布式为手段，区域化为途径，满足了不同区域精细化供热需求同时，配套实现了热水全天候供应，在特属区域消毒蒸汽由小型蒸汽锅炉提供保障。热源设备因地制宜、布置紧凑、节约用地，智慧云平台架构运行下的系统整体稳定，环保效益突出，受到医务工作者和患者的一致好评。</p>
           </van-col>
        </van-row>
        
        <van-row type="flex" justify="space-between">
           <van-col span="12">
               <i>运城某购物中心</i>
               <p>
                   <b>建筑类型：</b>大型综合性商场<br>
                   <b>功能区域:</b>酒店、购物、娱乐、餐饮<br>
<b>项目特点: </b>建筑层高较高，散热较大，如采用粗放型中央空调系统，能源浪费将极为严重，峰谷平电价也会导致运行成本激增。鉴于该购物中心夜间用电负荷小，可充分利用低谷富余电力，采用空气源热泵配套蓄热模块即可满足供暖需求，夏季供冷同比中央空调运行价格也将稍低。<br>
            
            <b>使用效果: </b>项目采用200余台高密度高稳定性纳米复合相变材料储热热库单元，放置于地下室，占地面积200㎡，与传统配置水蓄热罐相比，可节省82%的空间。基于其优质的蓄热属性，日间仅需少量补热即可满足商业综合体冬季用热需求。在夏季制冷运行期间，水箱隔热性与地下室自来水网低温属性使得节能性具有显著提升。整体运行经济性同比最优，为商业体业主带来了实际的优惠。
               </p>
           </van-col>
           <van-col span="10"><img :src='img3'></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
           <van-col span="10"><img :src='img4'></van-col>
           <van-col span="12">
               <i>太原市某厂区</i>
               <p><b>建筑类型：</b>厂区<br>
            <b>功能区域：</b>员工宿舍、办公楼、厂房、澡堂<br>
            <b>项目特点: </b>原先该厂区采用燃气锅炉，能源转换率低、设计管线长、排放较高、能源费用居高不下。<br>
           
            <b>使用效果: </b>项目采用空气源热泵设备对原先老旧锅炉进行替代，采用分布式建设方案，并对既有管网进行了整体改造。整体完成后整体运行效果良好，在节能基础上提升了厂区职工用热保障和热水服务质量。</p>
           </van-col>
        </van-row>
       <Secondary :title="stitle3"></Secondary>
        <!-- 沈阳 -->
        <van-row type="flex" justify="space-between"  name='xinzhou' id='xinzhou'>
           <van-col span="12">
               <i>忻州同煤大厦</i>
               <p>
                   <b>建筑类型：</b>写字楼<br>
                   <b>功能区域：</b>办公室，会议室，食堂，接待中心<br>
<b>项目特点: </b>忻州同煤大厦是集办公、会议于一体的综合办公楼，单体项目建筑面积巨大，由于各区域人员使用规律不同，空间与散热属性也差异较大，需要通过精细化手段满足区域供暖差异化需求。<br>
            <b>使用效果： </b>瑞海热控综合分析建筑布局设计、蓄热属性、区域用热规律、空置率占比后，适配性建设了具有云平台智能控制系统加持的蓄热式发热电缆供热系统。可根据各个房间和其他供暖部位用热需求，随时调整供暖时间与供暖温度。在十余年应用期间，精细化供暖效果良好，维修费用低廉，操作简便，为不同区域的办公人员提供了周到精心的用热体验。
               </p>
           </van-col>
           <van-col span="10"><img :src='img5'></van-col>
        </van-row>
        <van-row type="flex" justify="space-between"  name='nengyuan' id='nengyuan'>
           <van-col span="10"><img :src='img6'></van-col>
           <van-col span="12">
               <i>太原国际能源中心</i>
               <p><b>建筑类型：</b>商业综合体<br>
            <b>功能区域：</b>酒店、购物、娱乐、餐饮、电影院<br>
            <b>项目特点：</b>太原国际能源中心是作为综合型商业综合体，单体项目建筑面积较大，空间与散热属性也差异较大，由于不同区域用户的用热需求和用热时间各不相同，需要通过精细化手段满足区域供暖差异化需求。<br>
            <b>使用效果: </b>瑞海热控通过利用蓄热式发热电缆即开即合、分时分区、精准供热的特性，以智慧供热云平台作为操作架构，精确分析各个房间和其他供暖部位用热需求，随时根据热用户需求调整供暖时间与供暖温度，保障了各类型功能区域良好的用热体验。在十余年运营期间，精细化供暖效果良好，故障预警系统保障了供暖0事故服务，获得了商家与业主的高度赞扬。<br></p>
           </van-col>
        </van-row>
        
        <!-- 萝卜 -->
        <van-row type="flex" justify="space-between" name='power' id='power'>
           <van-col span="12">
               <i>国家电网太原分公司高层宿舍</i>
               <p>
                   <b>建筑类型：</b>商业综合体<br>
            <b>功能区域：</b>住宅<br>
            <b>项目特点：</b>由于建筑材料不过硬，导致保温性较差，且单体项目建筑面积大，同比散热也称几何性增长。<br>
            <b>使用效果: </b>瑞海热控采用蓄热式发热电缆作为热源模式，针对楼体本身存在的施工问题，为了不影响建筑物实际采暖效果，我公司对楼体内保温层进行了加厚，并对房屋的门窗等散热较高区域进行了二次隔热处理。结合楼体蓄热属性与智慧供热云平台智能分析运行，项目在实际运行的12年间整体供暖服务质量良好，配套运维体系周到，住户认可度极高。<br>
               </p>
           </van-col>
           <van-col span="10"><img :src='img7'></van-col>
        </van-row>
        <Secondary :title="stitle4"></Secondary>
        <van-row type="flex" justify="space-between">
           <van-col span="10"><img :src='img8'  name='datong' id='datong'></van-col>
           <van-col span="12">
               <i>大同市世家康养小镇</i>
               <p><b>建筑类型：</b>装配式建筑<br>
               <b>功能区域：</b>住宅、商住混合<br>
           
            <b>项目特点：</b>康养小镇是以康养产业为中心形成的关联城市建设、生态环境、民风民俗、科技信息、文化教育、社会安全等行业的综合产业小镇。由于采用装配式建筑，节能标准高。项目原先采用传统铁质锅炉，燃烧效率低、氮排放量大、占地面积大、可供区域有限。<br>
            <b>使用效果: </b>由瑞海公司为其提供低氮真空锅炉改造服务，改造后锅炉运行效率得到显著提升，且氮排放量低于20mg，采暖季降低能源成本约为40%。</p>
           </van-col>
        </van-row>
        
        <!-- 印刷厂 -->
        <van-row type="flex" justify="space-between">
           <van-col span="12">
               <i>太原某科技写字楼</i>
               <p>
                   <b>建筑类型：</b>公共建筑<br>
            <b>功能区域：</b>办公楼、厨房生活热水<br>
            <b>项目特点：</b>项目涵盖供暖及生活热水服务保障，该建筑虽然属于公共建筑，但节能属性较好，精细化供暖运行下，整体运维效果提升明显。<br>
            <b>使用效果：</b>由瑞海公司为其提供低氮全预混燃气锅炉供暖服务，项目投入运维至今为4年，通过瑞海智慧供热云平台整体把控，运行耗气量较低；可视化运行数据显示大大降低了维护难度，业主对供热服务效果十分满意。
               </p>
           </van-col>
           <van-col span="10"><img :src='img9'></van-col>
        </van-row>
        <Secondary :title="stitle5"></Secondary>
        <van-row type="flex" justify="space-between"  name='changzhi' id='changzhi'>
           <van-col span="10"><img :src='img10'></van-col>
           <van-col span="12" >
               <i >长治交通职业技术学院</i>
               <p><b>建筑类型：</b><br>
            <b>功能区域：</b>教学区，体育馆，食堂，图书馆，宿舍<br>
            <b>项目特点：</b>该校区属于新建校区，周边配套设施较差，随着主体建筑完工，如何解决师生对生活热水与直饮水需求，成为校方的一大困扰。<br>
            <b>使用效果：</b>长治市交通科技学校生活热水项目在2022年2月顺利落成，整体施工、布设配套仅用时20天。作为生活保障性服务，不容丝毫差错。良好且稳定的热水供应服务，是品牌实力的考验，对策则来自终端产品、技术服务能力与系统集成方案的有机结合。从实际结果来看，长治市交通科技学校舒适用水已经被作为了一项系统性工程，在节能降耗的前提下，实现了稳定供应。整体而言，瑞海热控凭借终端方案、技术服务的综合能力，在热水供应方面契合了学校“清洁”“安全”“以人为本”的内在要求。</p>
           </van-col>
        </van-row>
        <Secondary :title="stitle6"></Secondary>
        <Case></Case>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Secondary from '@/common/secondary.vue'
import Case from '@/common/case.vue'
export default {
   
    components:{
        Primary,
        Secondary,
        Case,
        // Gls,
    },
    created() {
        this.$nextTick(()=>{this.toLocal()})
    },
    mounted() {
        let _this=this;
        _this.$nextTick(function(){
                window.addEventListener('scroll',_this.handleScroll)
        })			
    },
    methods: {
        toLocal(){
            //查找存储的锚点id
            let Id=localStorage.getItem('toId');
            let toElement=document.getElementById(Id);
            //锚点存在跳转
            if(Id){
                toElement.scrollIntoView(false)
            }
            localStorage.removeItem('toId')
        }
    },
//用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面

    data() {
        return {
            title:'精彩案例',
            // title1:'太原学院新校区综合供暖项目',
            img1:require('@/assets/css/case/1.png'),
            // title2:'国家电网太原分公司高层宿舍集中供暖项目',
            img2:require('@/assets/css/case/2.png'),
            // title3:'北京假日阳光别墅供暖升级项目',
            img3:require('@/assets/css/case/3.png'),
            // title4:'山西忻州同煤大厦智能化供暖项目',
            img4:require('@/assets/css/case/4.png'),
            // title5:'沈阳新世纪家园别墅智能化供暖项目',
            img5:require('@/assets/css/case/5.png'),
            // title6:'北京国家体育总局供暖项目',
            img6:require('@/assets/css/case/6.png'),
            // title7:'黑龙江萝北富元小区供暖项目',
            img7:require('@/assets/css/case/7.png'),
            // title8:'长治交通职业技术学院生活热水项目',
            img8:require('@/assets/css/case/8.png'),
            // title9:'山西臣功印刷包装有限公司燃气锅炉项目',
            img9:require('@/assets/css/case/9.png'),
            // title10:'太原中国知网大厦（清华同方央企）燃气锅炉项目',
            img10:require('@/assets/css/case/10.png'),
            // title11:'太原中国知网大厦（清华同方央企）燃气锅炉项目',
            // img11:require('@/assets/css/case/11.png'),
            // // title12:'大同市世家小镇燃气供热项目',
            // img12:require('@/assets/css/case/12.png'),
            // // title13:'太原阳光国际商务中心',
            // img13:require('@/assets/css/case/13.png'),
            // // title14:'太原市锦江之星酒店',
            // img14:require('@/assets/css/case/14.jpg'),
            // // title15:'御花园假日广场燃气锅炉项目',
            // img15:require('@/assets/css/case/15.png'),
            // title16:'辽宁台安金厦住宅小区供暖项目',
            // img16:require('@/assets/css/case/16.png'),
            img17:require('@/assets/css/case/shipei.jpg'),
            img18:require('@/assets/css/case/gongnuan.jpg'),
            stitle1:'综合分布式多元化供热经典案例',
            stitle2:'空气源热泵经典案例',
            stitle3:'蓄热式发热电缆经典案例',
            stitle4:'低氮燃气锅炉经典案例',
            stitle5:'热水综合一体化服务经典案例',
            stitle6:'其他案例',
        }
    },
}
</script>
<style lang="less" scoped>
    .pho{
    width: 80%;
    margin:auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    
   }
.el-row{
    width: 1200Px;
    .el-col img{
        width: 100%;
    }
}


.van-row{
    width: 1200Px;
    margin: 0 auto;
    // text-align: center;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 20Px;
}
.van-row p{
    margin-top: 70Px;
    text-align-last: left; 
    font-size: 16Px;
}
.van-row i{
    font-size: 22Px;
    padding-left: 10Px;
    border-left: 3Px solid #f56a00;
    font-style: normal;
    float: left;
    margin-top: 20Px;
}
.van-col img{
    width: 100%;
    margin-top: 20Px;
}
.van-col .t{
    margin-top: 20%;
    
}
.van-col .mt{
    margin-top: 30%;
}
    .img img{
  width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}  
    .test{
    width: 1200Px;
    margin: 0 auto;
    text-align-last: center;
    z-index: 999999999;
    .test_img{
        width: 150Px;
        display: inline-block;
        height: 100Px;
        img{
            width: 100%;
            height: 100%;
        }
    }
   
}
.van-col .st{
    margin-top: 10%;
}
.van-col .top{
    margin-top: 20%;
    // width: 140%;
}
.van-col .b{
   margin-top: 10%;
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>