// 新闻
<template>
    <div>
        <van-row type="flex" justify="space-around" style="height:580px">
            <van-col span="10">
                   <a href="https://mp.weixin.qq.com/s/21dZl2HW1lknKSN62Fg21g" target="_blank">
                                   <div class="headline">
                    <div ><b class="headlineCenter">瑞海新闻|太原学院第二期清洁供热项目开工仪式圆满礼成 </b></div>
                                        <span>2022-08-17</span>
                </div>
                   </a>

                <a href="https://mp.weixin.qq.com/s/21dZl2HW1lknKSN62Fg21g" target="_blank">
                    <!-- <img :src='require("@/assets/css/新闻资讯/1.png" )'> -->
                    <videoSXSW style="margin-top:10px;"></videoSXSW>
                    <div class="headlineCenterP">
                        <p>8月12日，太原学院第二期清洁供热项目举办开工仪式。此次开工仪式由山西省商标品牌标准化建设促进会主办，项目方为瑞海热控省品促会会长孙跃进，省品促会特聘专家、省政府原副秘书长、省劳动保障厅原厅长、山西大众书画院常务副院长李顺通，省证监局原党委书记、局长孙春生，省商务厅原巡视员田国玉，省住建厅二级巡视员姜峰，省能源局总工程师杨仁泽，太原市原供热办主任葛跃强，太原学院党委书记韩保清，副院长刘月红以及瑞海热控董事长秦建民，总经理秦洋等嘉宾参加了开工仪式</p>
                    </div>
                    
                    <div class="Pcenter"></div>
                </a>
            </van-col>
            <van-col span="10">
                <a href="https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=2247488254&idx=1&sn=ae1b2361353027f4b8d5c83779db4c88&scene=19#wechat_redirect" target="_blank" class="A">
                    
                    <b>瑞海新闻|瑞海热控荣获“专精特新”中小企业称号</b>
                    <div class="DataTime">2023-07-20</div>
                    <p>近日，《山西省2023年“专精特新”中小企业认定公示》发布，山西瑞海节能网控电热集中供暖有限公司（简称：瑞海热控）赫然在列。</p>
                </a>
                <a href="https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=2247488258&idx=1&sn=1aee652eb6ac6d23c2f1aec174891ada&scene=19#wechat_redirect" target="_blank" class="A">
                    <b>瑞海新闻|热烈祝贺瑞海热控获得“高新技术企业”认证</b>
                    <div class="DataTime">2023-07-26</div>
                    <p>2022年12月，经全国高新技术企业认定管理工作领导小组办公室公示与备案，山西瑞海节能网控电热集中供暖有限公司（简称：瑞海热控）获得山西省科学技术厅、山西省财政厅和国家税务总局山西省税务局联合颁发的“高新技术企业”认定证书，正式跻身国家高新技术企业行列。</p>
                </a>
                <a href="https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=2247488312&idx=1&sn=6b2aa78f6ae567efd23a268769a5d8a5&scene=19#wechat_redirect" target="_blank">
                    <b>瑞海新闻|瑞海热控总经理秦洋受邀山西青年报专访——创新引领智慧供热 打造能源新路径（转载） </b>
                    <div class="DataTime">2023-11-22</div>
                    <p>近日，山西瑞海节能网控电热集中供暖有限公司（瑞海热控）总经理秦洋接受山西省青年企业家协会、山西青年报专访，详述了一名美归留学生如何带领团队报效家乡，扎根智慧供热新能源产业的创业征程。</p>
                </a>
                <a href="https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MDk5MDc2Mg==&hid=18&sn=e6a1d2969bdaffeede9c4985aca9beae">
                <div class="more" style="right: 83px;">更多...</div></a>
                
            </van-col>
        </van-row>
  
    </div>
</template>
<script>
import videoSXSW from "@/common/videoSXSW.vue";
export default {
    name:"abs",
    // data(){

    // },
components: {
    videoSXSW
  },
    
}
</script>
<style lang="less" scoped>
.van-row{
    position: relative;
    width: 1200Px;
    margin: 0 auto;
    padding: 30Px;
    box-sizing: border-box;
    background-color: #eeeff1;
    border-bottom: 0.08rem solid #d4d5d7;
}
.van-col{
    width: 450Px;
}
img{
    width: 450Px;
    margin: 10Px 0;
}
.A{
    display: block;
    border-bottom: 3px solid #d4d5d7;
}
a{
    cursor: pointer;
}
.more{
    margin-bottom:30px ;
    color: #f56a00;
    font-size: 18Px;
    position: absolute;
    // bottom: 30PX;
    
}
.headline{
    // height: 25px;
    margin-top: 30Px;
    display: flex;
    flex-wrap:nowrap !important;
}
.headlineCenter{
    margin-top: auto;
    width: 23rem !important;
    font-size: 18Px;
    color: #343434;
    // margin-top: 25Px
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    
}
p{
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
}



.headlineCenterP{
    //  overflow-y: scroll;
    // height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

}

b{
    font-size: 18Px;
    color: #343434;
    margin-top: 25Px;
    display: inline-block;
width: 22rem;;
  height: auto;
  word-wrap: break-word; /*强制换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis;/*隐藏后添加省略号*/
  white-space: nowrap;/*强制不换行*/
}
span{
    line-height: 1.5;
    float: right;
    font-size: 14Px;
    color: #a5a5a5;
    // margin-top: 25Px;
}
.DataTime{
    margin-top: 30Px;
    float: right;
    font-size: 14Px;
    color: #a5a5a5;
}
p{
    font-size: 16Px;
    color: rgb(89, 89, 89);
    text-align: inherit;
    
    text-justify: newspaper;
    word-break: break-all;

}

</style>