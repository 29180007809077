// 联系瑞海
<template>
    <div>
              <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt="">
            <!-- <div class="repet"><div class="text">随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。</div></div> -->
        </div>
        <Primary :title="title"></Primary>
        <div class="box">
            <div class="center">
                 <p>山西瑞海节能网控电热集中供暖有限公司</p>
                 <span>联系邮箱： qinyang@rethermo.com<br>
                  联系电话： 18734539360</span>
                 <img :src='require("@/assets/css/名片/wangtaowei.jpg")'>
            </div>
           
        </div>
    </div>
</template>
<script>
import Primary from '@/common/primary'
export default {
     components:{
        Primary,
    },
    data() {
        return {
            title:'联系瑞海'
        }
    },
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            color: #fff;
            width: 450Px;
            height: 230Px;
            background-color: #f56a00;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
        }
   }
  
    .img img{
        width: 100%;
    }
    .box{
        padding: 20Px;
        width: 1200Px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #edeef0;
    }
    .center{
        width: 440Px;
        margin: 0 auto;
    }
    .box .center p{
        font-size: 24Px;
    }
    .center img{
        margin-top: 30Px;
        width: 440Px;
    }
    @media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>