// 能源港
<template>
    <div class="container">
        <div class="img">
            <img :src="require('@/assets/css/banner/zonghepng.png')" alt="" class="ditu"><div class="div"><div class="box">综合能源管理<br><i>INTEGRATED ENERGY MANAGEMENT</i></div><div class="p">光伏 微电网 能源站 余热回收</div></div>
        </div>
        <Primary :title='title1'></Primary>
        <Main :text='text1'></Main>
        <!-- 导航切换 -->
        <Subroute2></Subroute2>
        <!-- 能源应用解决方案 -->
        <div class="bg">
            <Secondary :title="Title1"></Secondary>
        <van-row type="flex" justify="space-between">
           <van-col span="10">
               <p class="t">瑞海热控的能源港项目是以清洁能源整体开发为核心，以清洁能源综合产出，应用与配套化设施建设为延伸的智慧新能源项目。整体能源将以新兴的光伏、风电、生物能、地热能和氢能为供给，建设新产能设施，实现能源港内部字自给自足同时，多余能源辐射周边业态应用。通过智能化综合能源管理系统，对项目整体的能耗进行监控、优化、管理，以实现对能源港及周边产业提供专业的能源应用解决方案（供电、供冷/暖、通风、照明等）的要求。</p>
           </van-col>
           <van-col span="10" v-lazy-container="{ selector: 'img' }"><img :data-src='IMG1'></van-col>
        </van-row>
            <!-- <Gls :Title="Title1" :IMG="IMG1"></Gls>
            <Print :text='text2'></Print> -->
        </div>
        <!-- 源-网- -->
        <div class="bor">
            <Gls :Title="Title2" :IMG="IMG2"></Gls>
        </div>
        <!-- 能源 -->
        <Gls :Title="Title3" :IMG="IMG3"></Gls>
        <!-- 供能网 -->
        <div class="bg">
            <Secondary :title="title2"></Secondary>
            <van-row type="flex" justify="space-around">
                <van-col span="9"><img :src='require("@/assets/css/能源港/3.png")'></van-col>
                <van-col span="9"><p>综合能源服务区域内功能性设施相对城市建筑密度较低，采用分布式清洁能源有灵活性、经济性方面的优势。可根据设施分布密度灵活搭配，避免了集中式光伏发电传输线路长、设备投资大、占地面积大等多方面的束缚。</p></van-col>
            </van-row>
        </div>
        <!-- 负荷 -->
        <Secondary :title="title3"></Secondary>
        <van-row type="flex" justify="space-between">
           <van-col span="12">
               <i>蓄热式电锅炉系列</i>
               <p>蓄热式电锅炉主要是利用低谷电时段将电能储存为热能，并在用能时将储存的热能释放的电制热和储热设备。蓄热式电锅炉可以在供热同时满足区域范围内热水需求，充分利用电力负荷低估时段进行高效蓄热的模式，不仅解决了新能源消纳问题，同时极大降低了运行费用。</p>
           </van-col>
           <van-col span="6"><img :src='require("@/assets/css/能源港/4.png")'></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
           <van-col span="6"><img :src='require("@/assets/css/能源港/5.png")'></van-col>
           <van-col span="12">
               <i>蓄冷式空调系统</i>
               <p>蓄冷式空调技术可以实现区域微电网内移峰填谷，提高电源、微电网设备的利用率、微电网高峰时段供电能力，致使终端用户充分利用电力负荷低谷时段电价，节约了用电成本。冷热端蓄能技术结合，在满足用户冷、热双需同时，极大提升了综合能源辐射区域内微电网运行效率，切实解决了微电网用能端高峰用电紧张、谷期电能闲置的问题。</p>
           </van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
           <van-col span="12">
               <i>冷热双供空气源热泵</i>
               <p>整体系统凭借空气源热泵机组就能提供提冷/热供空调、热水供应、恒温储藏等多功能一体化服务。我们通过高精准的温度、湿度、气压探测器，准确收集环境信息，制定最大化程度的空气蓄/放热方案,精确变频控制机组功率，高效提升集热和制冷能力。</p>
           </van-col>
           <van-col span="6"><img :src='require("@/assets/css/能源港/rebeng.png")'></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
           <van-col span="6"><img :src='require("@/assets/css/能源港/dianlan.jpg")'></van-col>
           <van-col span="12">
               <i>发热电缆</i>
               <p>瑞海热控严选市面上热效率最高的发热电缆厂商,采用世界最前沿的高灵敏度的合金电阻材料或碳纤维发热材料。将发热电缆预埋在指定供暖区域,通电使发热电缆发热,将舒适、持续、稳定的热量以热传导的方式输送给用户，整体经济环保。瑞海热控会将发热电缆预埋在指定供暖区域,通电使发热电缆发热，将舒适、持续、稳定的热量以热传导(对流)的方式传输出去。</p>
           </van-col>
        </van-row>
       
        <!-- 储能 -->
        <Secondary :title="title4"></Secondary>
        
        <van-row type="flex" justify="space-between">
            <van-col span="10" class="E"><em>光储能系统,在区域智慧清洁能源项目延伸中，具有举足轻重的地位。可极大减少能源消耗,降低碳排放。通过储能管理系统,充分利用光伏系统和智能化通信设备进行统筹管理。在降低成本的同时扩展出多元化功能应用。通过系统将清洁能源进行整合储备管理，降低对外部能源依赖,形成自给自足的新能源应用闭环。整体系统将在功能性建筑区域根据实际条件安置一定面积的真空太阳能集热热水器。配置不小于3立方米的保温储水罐，通过循环水泵与热交换器换热提供生活用热水，以供日常功能性使用需求。</em></van-col>
            <van-col span="12" class="I"><img :src='require("@/assets/css/能源港/chuneng.png")'></van-col>
            </van-row>
           
           
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Main from '@/common/main.vue'
import Subroute2 from '@/common/Subroute2.vue'
import Gls from '@/common/gls.vue'
// import Print from '@/common/print.vue'
import Secondary from '../common/secondary.vue'
export default {
    components:{
        Primary,
        Main,
        Subroute2,
        Gls,
        // Print,
        Secondary,
    },
     methods:{
_isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
   },
   mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
    data() {
        return {
            deviceType: '',
            //  videoUrl:require('@/assets/nengyuan.mp4'),
            title1:'新能源综合应用',
            text1:'随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。',
            Title1:'能源应用解决方案',
            IMG1:require('@/assets/css/能源港/tp.jpg'),
            // text2:'瑞海热控的能源港项目是以清洁能源整体开发为核心，以清洁能源综合产出，应用与配套化设施建设为延伸的智慧新能源项目。整体能源将以新兴的光伏、风电、生物能、地热能和氢能为供给，建设新产能设施，实现能源港内部字自给自足同时，多余能源辐射周边业态应用。通过智能化综合能源管理系统，对项目整体的能耗进行监控、优化、管理，以实现对能源港及周边产业提供专业的能源应用解决方案（供电、供冷/暖、通风、照明等）的要求.',
            Title2:'源 - 网 - 荷 - 储',
            IMG2:require('@/assets/css/能源港/1.png'),
            Title3:'能源：光伏发电',
            IMG3:require('@/assets/css/能源港/2.png'),
            title2:'供能网:微电网',
            title3:'负荷：冷热、热水三联供',
            title4:'储能：余能存储'
        }
    },
}
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin: auto;
    overflow: hidden;
    position: relative;
    .ditu{
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
    
    .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-size: 26Px;
            font-style: normal;
        }
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    }
}
.o{
    box-sizing: border-box;
    padding: 10Px ;
    border: 1Px solid #f56a00;
}
.G{
    width: 100%;
    padding: 0;
    background-color: rgba(240,240,240);
}
.bor{
    width: 1200Px;
    margin: 0 auto;
    
    // border-bottom:3Px solid #cccccc;
}
.E{
    text-align-last: left;
    text-align: justify;
     margin-top: 70Px;
}
.E em{
    font-style: normal;
    font-size: 16Px;
    color: #333;
}
.I img{
    width: 100%;
    margin-top: 20Px;
}
img{
  
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  width: 100%;
  margin-left: -1PX;
}
.subroute{
    margin-top: 100px;
}
.bg{
    width: 100%;
    height: 100%;
    background-color: #eff0f2;
    padding: 20Px 0;
}
.van-row{
    width: 1200Px;
    margin: 0 auto;
    // text-align: center;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 20Px;
}
.van-row p{
    margin-top: 70Px;
    text-align-last: left; 
    font-size: 16Px;
}
.van-row i{
    font-size: 22Px;
    padding-left: 10Px;
    border-left: 3Px solid #f56a00;
    font-style: normal;
    float: left;
    margin-top: 20Px;
}
.van-col .t{
    margin-top: 30%;
}
.o img{
    width: 180Px;
    float: left;
    margin-top:35PX;
    margin-right: 10Px;
}
.o .R b{
    border-left: 4Px solid #f56a00;
    font-size: 20Px;
    font-weight: normal;
    float: left;
    margin-top: -40Px;
    padding-left: 10Px;
}
.o .R p{
    color: #333;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>