// 首页
<template>
  <div class="main">
    <van-row type="flex" justify="space-between">
       <van-col span="8" class="left">
        <!-- <h3>清洁低碳方式供热</h3> -->
        <h5>服务场景</h5>
        <router-link to='/school?active=one'>校园综合体供热</router-link><br>
        <router-link to="/office-building?active=three">机关单位</router-link><br>
        <router-link to="/conference?active=four">写字楼供热</router-link><br>
        <router-link to="/hospital?active=two">医院供热</router-link><br>
        <router-link to="/residenttial?active=five">工业园区</router-link><br>
       </van-col>
       <van-col span="18" class="right">
         <Right></Right>
       </van-col>
    </van-row>
  </div>
</template>
<script>
import Right from '@/common/Right.vue'
export default {
  components:{
        Right,
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.main{
  width: 1200Px;
  margin: 0 auto;
}
.left{
  width: 200Px;
  background-color: #f56a00;
  border-radius: 20Px;
  box-sizing: border-box;
  padding: 20Px;
  color: #fff;
}
.left h3{
  font-size: 20Px;
  text-align: center;
}
.left h5{
  font-size: 20Px;
}
a{
  color: #fff;
  font-size: 16Px;
  display: block;
  display: inline-block;
  height: 40Px;
  line-height: 40Px;
   cursor: pointer;
   border-bottom: 1px solid #f56a00;
}
a:hover{
 border-bottom: 1px solid rgb(255, 255, 255);
}
</style>