import Vue from 'vue'
import App from './App.vue'
// import 'amfe-flexible'
// import '@/assets/css/reset.css'
import less from 'less'
import VideoPlayer from 'vue-video-player'
import Vant from 'vant'
import VueLazyload from 'vue-lazyload'
import 'vant/lib/index.css'
import router from '@/router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
Vue.use(ElementUI);
Vue.use(Vant)
Vue.use(VideoPlayer);
Vue.use(less)
Vue.use(VueLazyload)
Vue.config.productionTip = false
Object.defineProperties(Vue.prototype, {
  echarts: { get: () => echarts }
});
new Vue({
  // mode:'history',
  router,
  render: h => h(App),
}).$mount('#app')
