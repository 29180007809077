// 服务流程
<template>
    <div>
         <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt="">
            <div class="div"><div class="box">服务流程<br>SERVICE PROCESS</div><div class="p">设计 建设 运维</div></div>
            <!-- <div class="repet"><div class="text">随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。</div></div> -->
        </div>
        <Primary :title='title'></Primary>
        <Pho :IMG='img'></Pho>
        <Secondary :title='title1'></Secondary>
        <Print :text=text1></Print>
        <Secondary :title='title2'></Secondary>
        <Print :text=text2></Print>
        <Secondary :title='title3'></Secondary>
        <Print :text=text3></Print>
        <Secondary :title='title4'></Secondary>
        <Print :text=text4></Print>
        <!-- <Service></Service> -->
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Print from '@/common/print.vue'
// import Service from '@/common/service.vue'
import Pho from '@/common/pho.vue'
import Secondary from '@/common/secondary.vue'
export default {
    components:{
        Primary,
        // Service,
        Pho,
        Print,
        Secondary,
    },
    data() {
        return {
            title:'服务流程',
            img:require('@/assets/css/服务流程/1.jpg'),
            title1:'运营成本经济可控',
            text1:'瑞海热控能对项目的精准计费，根据实际产生的服务计费，运营成本清晰透明。依托智能控制，瑞海热控可以做到对运营成本实时调控，依照实际情况优化运营成本。目前，瑞海热控是我国唯一能够利用广域网技术将电网谷期电能应用于建筑物集中蓄热供暖和冰蓄冷制冷的系统集成商，这能为客户节省大量的运营成本。',
            title2:'区域级设备供货商',
            text2:'瑞海热控与多家设备厂商签订了战略协议，与山西地区的设备销售监理了稳定可靠的货源关系。瑞海热控将厂商设备优势直接延伸至应用端，以极快的相应速度，为区域的客户制定出有针对性的设备组合方案。同时，瑞海热控充分利用扁平化、精准化供应链的优势，大大减少供货渠道上的阻力，能以远低于市场价的价格为客户选购相应的设备。',
            title3:'稳定可靠的大型项目运营经验',
            text3:'项目覆盖，山西、北京、辽宁等地区，为诸多机构单位提供大型项目一体智能化解决方案与运营维护工作。仅太原学院单个项目便铺设了22万平方米的智能化供热区域，持续稳定服务十数载，系统稳定可靠，运营维护成本极低。此类大型供暖项目长达10年的运维经验，为业内少有。此外，公司还拥有多类型项目的智能化系统开发经验，为医院、学校、商业办公楼、住宅公寓及各类别墅院落等不同类型项目建设了配套智能化系统整合，熟悉各类型智能化设备的开发与应用。提供综合服务、定制化服务的能力突出。',
            title4:'迅速响应',
            text4:'公司现有技术通过光纤与4G、5G、微波等无线通讯光纤与微波通讯，可实现对智能化设备的远程调控、精确调控，各项数据实时回馈到控制总机，实现自动预警，这大大降低了运营维护难度和运行成本，可及时暴露系统的故障点，杜绝隐患。公司能够在第一时间发现问题、解决问题，做到小问题1小时内解决，大问题24小时内维修完毕，真正做到不间断供暖。问题响应与维修速度，在业内一直保持领先水平。'

        }
    },
}
</script>
<style lang="less" scoped>
    .img img{
        width: 100%;
    }
    .pho{
    width: 80%;
    margin:auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    
   }
   @media screen and (max-width:1900px) {
        .pho {
            width:100%;
        }
    }
</style>