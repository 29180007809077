<template>
  <div id="app">
    <AppTabBar></AppTabBar>
  </div>
</template>

<script>
import DevicePixelRatio from "./devicePixelRatio";
import AppTabBar from "@/components/AppTabBar";
export default {
  name: "App",
  components: {
    AppTabBar,
  },
  created() {
    new DevicePixelRatio().init();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.detectZoom();
      })();
    };
  },
  methods: {
    detectZoom() {
      var ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase();
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (
        window.outerWidth !== undefined &&
        window.innerWidth !== undefined
      ) {
        ratio = window.outerWidth / window.innerWidth;
      }

      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      //ratio就是获取到的百分比
      console.log(ratio);
      this.onresize_height = ratio;
      // return ratio;
    },
  },
};
</script>

<style lang='less' scoped>
</style>
