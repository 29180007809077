// 一级标题
<template>
  <div>
    <div class="primary">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="less" scoped>
.primary {
  text-align: center;
}
h1 {
  font-size: 34Px;
  color: #000000;
}
</style>