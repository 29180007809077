// 发热电缆
<template>
  <div class="container">
    <div class="pho">
      <img :src="require('@/assets/css/banner/ditu.png')" alt="" class="ditu"><img :src="require('@/assets/css/banner/tubiao.png')" alt="" class="tubiao"><div class="div"><div class="box">低碳清洁供暖<br>LOW CARBON CLEAN HEATING</div><div class="p">分布式 多热源 冷暖一体化服务</div></div>
    </div>
    <Primary :title="title"></Primary>
    <Main :text="text"></Main>
    <Subroute></Subroute>
    <Secondary :title="title1"></Secondary>
    <Print :text="text1"></Print>
    <Pho :IMG="img1"></Pho>
    <div class="contant">
      <p>
        由于瑞海热控的发热电缆采暖系统适应于各种应用场景、经济环保、安全舒适、稳定性高、成本低等特性，是瑞海热控最成熟的供热解决方案之一。瑞海热控的发热电缆采暖系统包含有：
      </p>
      <div class="main">
        <span>01</span>
        <p>
          按照客户的需求定制的发热电缆
          瑞海热控可以根据客户的使用场景和使用特点等需求，深度定制电缆结构和材料，满足客户对导热性、耐腐蚀性、抗冲击性、抗潮性等个性化的需求
        </p>
        <span>02</span>
        <p>
          发热电缆智能温控管理技术
          瑞海热控会采用先进的大数据云控制技术，对发热电缆的供暖时段、感应预热、分区供热等进行管理，通过房间的尺寸、容积、布局等智能划分供热区域，以满足每个供热区域每天不同时段的温度需求。利用声音、压感、运动检测、及其移动数据传输等高新技术精确定位。
        </p>
      </div>
    </div>
    <Secondary :title="title3"></Secondary>
    <van-row type="flex" justify="space-between" class="test" >
      <van-col span="7">
        <div class="test_img"><img :src='require("@/assets/css/发热电缆/wenkongq.jpg")'></div>
             <b>温度控制设备</b>
      </van-col>
      <van-col span="7">
        <div class="test_img"><img :src='require("@/assets/css/发热电缆/lywk.jpg")'></div>
             <b>楼宇通讯设备</b>
      </van-col>
      <van-col span="7">
        <div class="test_img"><img :src='require("@/assets/css/发热电缆/lcwk.jpg")'></div>
             <b>楼层通讯设备</b>
      </van-col>
    </van-row>
    <Pho :IMG="img9"></Pho>
    <Print :text="text2"></Print>
    <Secondary :title="title2"></Secondary>
    <van-row gutter="24" justify="space-around">
      <van-col span="8"
        ><img :src="require('@/assets/css/发热电缆/居民住宅.png')" />
        <p>
          居民住宅需要电缆发热效果均—、寿命长、安全、低辐射。我们的TXLP
          (G)，采用合金发热丝，发热效果均—稳定。复合交联绝缘层使得电缆更绝缘、更安全，加入铝复合膜后的屏蔽层可以有效降低电磁辐射，寿命长达50年。
        </p></van-col
      >
      <van-col span="8"
        ><img :src="require('@/assets/css/发热电缆/机场跑道.png')" />
        <p>
          部分应用场景有特殊的抗压需求，例如飞机跑道需要电缆加热快、抗高强度冲击、抗潮、少弯折以增加跑道强度。我们的融雪发热电缆HM-cable，采用特殊工艺的外壳，提高了机械强度、导热性和耐腐蚀性。此外使用双导结构，不但易于安装，而且不需要弯折。
        </p></van-col
      >
      <van-col span="8"
        ><img :src="require('@/assets/css/发热电缆/huw.png')" />
        <p>
          基于发热电缆技术设计的瑞海热控加热垫可对户外区域和机械快速解冻。发热垫的形状易于铺设和连接，可以灵活移动。预制插头，即插即用。同时发热垫正反面材质不同，使电缆在严苛的户外环境下可以高效加温、减少热量损耗。
        </p></van-col
      >
    </van-row>
    <Pho :IMG="img8"></Pho>
    <van-row gutter="20">
      <van-col span="6"><i>道路化雪</i></van-col>
      <van-col span="6"><i>地面地暖</i></van-col>
      <van-col span="6"><i>水产养殖</i></van-col>
      <van-col span="6"><i>土壤加热</i></van-col>
    </van-row>
  </div>
</template>
<script>
import Primary from "@/common/primary.vue";
import Main from "@/common/main.vue";
import Subroute from "../common/Subroute.vue";
import Secondary from "../common/secondary.vue";
import Print from "../common/print.vue";
import Pho from "../common/pho.vue";
export default {
  components: {
    Primary,
    Main,
    Subroute,
    Secondary,
    Print,
    Pho,
  },
 
 mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
  methods: {
    skip1() {
      this.$router.push("/road");
    },
    skip2() {
      this.$router.push("/residenttial");
    },
    skip3() {
      this.$router.push("/school");
    },
    skip4() {
      this.$router.push("/school");
    },
    _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
  },
  data() {
    return {
      deviceType: '',
      // videoUrl:require('@/assets/clear.mp4'),
      activeName: "heat",
      title: "清洁供暖",
      // text: "瑞海热控的空气源热泵系统针对市场需求和智能化进步，不断革新升级，采用创新双极压缩机和喷气增焓技术，大幅提升了机组整体性能。实现了一台气源热泵机组，即可提供冷/热双供空调、地暖、热水供应、恒温储能等多功能一体化服务。",
      title1: "发热电缆智控",
      title3:'蓄热式发热电缆智慧管控设备',
      text1:
        "瑞海热控严选市面上热效率最高的发热电缆厂商，采用世界最前沿的高灵敏度的合金电阻材料或碳纤维发热材料。将发热电缆预埋在指定供暖区域，通电使发热电缆发热，将舒适、持续、稳定的热量以热传导的方式输送给用户，整体经济环保。瑞海热控会将发热电缆预埋在指定供暖区域，通电使发热电缆发热，将舒适、持续、稳定的热量以热传导(对流)的方式传输出去。",
      img1: require("@/assets/css/发热电缆/cables.jpg"),
      title2: "经典应用场景",
      img8: require("@/assets/css/发热电缆/yychangjing.jpg"),
      img9: require("@/assets/css/发热电缆/data.png"),
      text2:
        "供热区域的供热需求并作出相应反应，实现人在供暖，人走停暖，大幅度减少能源消耗。瑞海热控的发热电缆采缓系统被广泛运用于综合写字楼、医院、校园、酒店、商场、楼宇集群等大型空间的综合供热。也在有专项用途的建筑或工程中有出色的表现，例如公路、桥梁、隧道、机场跑道、礼堂、酒窖、体育场馆、畜牧养殖场、土壤加热等等。",
    };
  },
};
</script>
<style lang="less" scoped>
   .test{
    width: 1200Px;
    margin: 0 auto;
    text-align-last: center;
    z-index: 999999999;
    .test_img{
        // width: 350Px;
        display: inline-block;
        // height: 260Px;
        img{
            width: 100%;
            height: 100%;
        }
    }
   }
.pho{
    width: 80%;
    margin:auto;
    overflow: hidden;
    position: relative;
    .ditu{
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
     .tubiao{
      position: relative;
       width: 100%;
       height: 100%;
        top: -15Px;
        left: 0;
        bottom: 0;
        right: 0;
        margin-top: -10000px;
        z-index: 22;
    }
    .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    }
}
video {
 display: block;
        margin: auto;
        max-width: 100%;
}
video {
  pointer-events: none;
}
// /所有控件
video::-webkit-media-controls-enclosure {
  display: none;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  width: 100%;
}
.contant {
  width: 1200Px;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 14Px;
}
.contant span {
  font-weight: 800;
  font-size: 50Px;
  color: rgba(242, 242, 242);
  margin-left: 60px;
}
.contant .main p {
  margin-left: 120Px;
  margin-top: -40Px;
  line-height: 24Px;
}

.van-row {
  box-sizing: border-box;
  cursor: pointer;
  width: 1200Px;
  margin: 0 auto;
  text-align: center;
  img{
    width: 100%;
  }
}
.van-row p {
  font-size: 14Px;
  text-align: justify;
  text-align-last: left;
}
i {
  text-align: center;
  font-style: normal;
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>