import Vue from "vue";
import VueRouter from "vue-router";
// import User from "@/views/User";
import AboutUs from "@/views/about-us";
import AirSource from "@/views/air-source";
import CarbonEnergy from "@/views/carbon-energy";
import Conference from "@/views/conference";
import ContactRuihai from "@/views/contact-ruihai";
import ElectricTracing from "@/views/electric-tracing";
import EnergyPort from "@/views/energy-port";
import GasBoiler from "@/views/gas-boiler";
import GreatCase from "@/views/great-case";
import HeatingCable from "@/views/heating-cable";
import HomePage from "@/views/home-page";
import Hospital from "@/views/hospital";
import HotWater from "@/views/hot-water";
import NewsInformation from "@/views/news-information";
import OfficeBuilding from "@/views/office-building";
import PhotovoltaicArray from "@/views/photovoltaic-array";
import Residential from "@/views/residenttial";
import School from "@/views/school";
import ServiceProcess from '@/views/service-process'
import System from '@/views/system'
import Waterway from '@/views/waterway'
import Circuit from '@/views/circuit'
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "/home-page"},
    { path: "/about-us", component: AboutUs},
    { path:'/air-source', component: AirSource},
    { path:'/carbon-energy',component: CarbonEnergy},
    { path:'/conference', component: Conference},
    { path:'/contact-ruihai', component: ContactRuihai},
    { path:'/electric-tracing', component: ElectricTracing},
    { path:'/energy-port', component: EnergyPort},
    { path:'/gas-boiler', component: GasBoiler},
    { path:'/great-case', component: GreatCase},
    { path:'/heating-cable', component: HeatingCable},
    { path:'/home-page', component: HomePage},
    { path:'/hospital', component: Hospital},
    { path:'/hot-water', component: HotWater},
    { path:'/news-information', component: NewsInformation},
    { path:'/office-building', component: OfficeBuilding},
    { path:'/photovoltaic-array', component: PhotovoltaicArray},
    { path:'/residenttial', component: Residential},
    { path:'/school', component: School},
    { path:'/service-process', component: ServiceProcess},
    { path:'/system',component:System},
    { path:'/waterway',component:Waterway},
    { path:'/circuit',component:Circuit}
  ],
});
// 跳转后自动返回页面顶部
router.afterEach(() => {
  window.scrollTo(0,0);
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
