<template>
  <div
    class="back_top"
    @mouseover="enterBackTop"
    @mouseout="outBackTop"
    ref="backTop"
    :style="{ opacity: opacity }"
    v-show="gotop"
    @click="handleScrollTop"
  >
  <img :src='require("@/assets/css/返回顶部.png")'>
  </div>
</template>
<script>
export default {
  data() {
    return {
      opacity: ".3",
      gotop: false,
      scrollHeight: 300,
      scrollTop: 0
    };
  },
  methods: {
    enterBackTop() {
      this.opacity = ".5";
    },
    outBackTop() {
      this.opacity = ".3";
    },
    handleScroll() {
      const that = this;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      that.scrollTop > this.scrollHeight
        ? (this.gotop = true)
        : (this.gotop = false);
    },
    handleScrollTop() {
      // this.$nextTick(() => {
      //   this.ele.scrollIntoView({ behavior: 'smooth' });
      // });
      const that = this;
      const timer = setInterval(() => {
        const ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 注册滚动事件
  }
};
</script>
<style lang="less" scoped>
.van-icon{
    position: fixed;
    bottom: 80px;
    right: 30px;
}
.van-icon:hover{
    opacity: 1;
}
.back_top{
    background-color: rgb(179, 177, 177);
    width: 50Px;
    height: 50Px;
    border-radius: 25Px;
    text-align: center;
    box-sizing: border-box;
    padding: 10Px;
    position: fixed;
    bottom: 30Px;
    right: 34Px;
    img{
        width: 100%;
    }
}
</style>