// 新闻
<template>
    <div class="container">
        <van-row type="flex" justify="space-around">
           <van-col span="10">
               <a :href="lskip" target="_blank">
               <b>{{ltopic}}</b>
               <span>{{ltime}}</span>
               <p>{{lsection}}</p>
               </a>
           </van-col>
           <van-col span="10">
               <a :href="rskip" target="_blank">
               <div class="more">更多...</div>
               </a>
           </van-col>
        </van-row>
        
    </div>
</template>
<script>
export default {
  props: {
    lskip: {
      type: String,
    },
    rskip:{
      type:String
    },
    ltopic: {
      type: String,
    },
    rtopic: {
      type: String,
    },
    ltime: {
      type: String,
    },
    rtime: {
      type: String,
    },
    lsection: {
      type: String,
    },
    rsection: {
      type: String,
    },
  },
};
</script>
<style lang="less" scoped>
.container{
    width: 1200Px;
    height: 100%;
    background-color: #eeeff1;
    box-sizing: border-box;
    margin: 0 auto;
}
.van-row{
    padding: 30Px;
    border-bottom: 3px solid #d4d5d7;
}
.van-col{
    width: 450Px;
    position: relative;
}
a{
    cursor: pointer;
}
a .more{
    color: #f56a00;
    font-size: 18Px;
    position: absolute;
    bottom: 0;
    right: 0;
}
b{
    font-size: 18Px;
    color: #343434;
    

}
span{
    float: right;
    font-size: 14Px;
    color: #a5a5a5;
}
p{
    font-size: 16Px;
    color: rgb(89, 89, 89);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
</style>