<template>
    <div class="C">
        <table cellpadding="1px" cellspacing="0px">
                    <thead>
                       <tr>
                        <th>系统优势</th>
                    </tr> 
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,index) in tabList" 
                        :key="index" 
                        :class="{active:currentClass==index}" 
                        @mousemove="toggleTab(index)" >
                            <td 
                            >
                            {{item}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="tab">  
        <div class="tab_con" v-show="currentTab==0">
            <img :src='require("../assets/css/sun/1-4.png")' alt="">
            <p>率先应用210大硅片组件，最大功率提升至555W更高装机容量，实现更高效益。</p>
        </div>
        <div class="tab_con" v-show="currentTab==1">
            <img :src='require("../assets/css/sun/1-3.png")' alt="">
            <p class='Y'>创新型切半组件密排技术，效率提升至21.2%成熟的多主栅（MBB）技术，电流损耗更低、更低温度系数、带来更高发电量。</p>
        </div>
        <div class="tab_con" v-show="currentTab==2">
            <img :src='require("../assets/css/sun/1-2.png")' alt="">
            <p>严苛的全流程质量控制体系，质量好，更省心，专业设计资质，标准化施工、安全可靠，收益高</p>
        </div>
        <div class="tab_con" v-show="currentTab==3">
            <img :src='require("../assets/css/sun/1-1.png")' alt="">
            <p>瑞海云平台全天候监控电站运行大数据分析，主动预警急速服务</p>
        </div>
    </div>
    </div>
</template>
<script>
export default {
  name: "Home",
  props: {},
  data() {
    return {
      currentTab: 2,
      currentClass: 2,
      tabList: ["更高功率组件", "创新高科技 切半密排", "原装正品品质保证",'无忧售后'],
    };
  },
  methods: {
    toggleTab(current) {
      this.currentTab = current;
      this.currentClass = current;
    },
  },
};
</script>
<style lang="less" scoped>
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.C{
  width:1200Px;
  margin:0 auto;
  margin-top: -50PX;
  overflow: hidden;
  display:flex;
  box-sizing: border-box;
  justify-content: center;
}
table{
  width: 30%;
  color: #fff;
  th{
    background-color: #f56a00;
    border-bottom: 1px solid #ffebdc;
    height: 80Px;
    line-height: 80Px;
  }
  td{
    background-color: #f56a00;
    // padding-left: 90Px;
    text-align: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ffebdc;
  }
  td:hover{
    background-color: #ce5a03;
    font-size: 18Px;
  }
}
.tab{
  width: 70%;
  flex: 1;
  position: relative;
  img{
    width: 100%;
    vertical-align:bottom;
  }
  p{
    position: absolute;

    bottom:-15Px;
    width: 100%;
    color: #f5f5f5;
    padding: 20Px 10Px;
    background-color: rgba(0,0,0,.3);
  }
}
</style>