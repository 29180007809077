<template>
  <div class="subroute">
     <van-row gutter="24"  type="flex" justify="space-between">
       <van-col span="4">
      <router-link to="/school?active=one"  @click.native="doSomething('one')"  :class="active==='one'?classActive:classUnActive">学校</router-link>
      </van-col>
      <van-col span="4">
      <router-link to="/hospital?active=two" @click.native="doSomething('two')" :class="active==='two'?classActive:classUnActive" >医院</router-link>
      </van-col>
      <van-col span="4">
      <router-link to="/office-building?active=three" @click.native="doSomething('three')"  :class="active==='three'?classActive:classUnActive">政府机关</router-link>
      </van-col>
      <van-col span="4">
      <router-link to="/conference?active=four" @click.native="doSomething('four')"   :class="active==='four'?classActive:classUnActive">写字楼</router-link>
      </van-col>
      <van-col span="4">
      <router-link to="/residenttial?active=five" @click.native="doSomething('five')"  :class="active==='five'?classActive:classUnActive">工业园区</router-link>
      </van-col>
      </van-row>      
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
 
  data(){
    return {
      active:this.$route.query.active,
      classActive:"classActive",
      classUnActive:"classUnActive"
    }
  },
  created(){

  },
 
  methods: {
    doSomething(st){
      this.active=st
    }
  },
}
</script>
<style lang="less" scoped>
.van-row{
  width: 1200Px;
  margin: 60px auto;
}
 a {
  color: #f56a00;
  background: #ffffff;
  border: 1px solid #f56a00;
  display: block;
  width: 100%;
  height: 98Px;
  line-height: 98Px;
  font-size: 22Px;
  text-align: center;
  border-radius: 10Px;
}
 a.router-link-exact-active{
	background-color: #f56a00;
	color: #fff;
}
.classActive:hover{
     background-color: #f56a00c2;
    color: #fff;
}
.classUnActive:hover{
    background-color: #f56a00;
    color: #fff;
}
</style>