// 空气源
<template>
    <div class="container">
       <div class="img">
      <img :src="require('@/assets/css/banner/ditu.png')" alt="" class="ditu"><img :src="require('@/assets/css/banner/tubiao.png')" alt="" class="tubiao"><div class="div"><div class="box">低碳清洁供暖<br>LOW CARBON CLEAN HEATING</div><div class="p">分布式 多热源 冷暖一体化服务</div></div>
    </div>
        <!-- 一级标题 -->
        <Primary :title='title'></Primary>
        <!-- 正文部分 -->
        <Main :text='text'></Main>
        <Subroute></Subroute>
        <Secondary :title='title1'></Secondary>
        <div class="pho" v-lazy-container="{ selector: 'img' }">
            <img :data-src='require("@/assets/css/空气源/1.png")'>
        </div>
        <Print :text='text1'></Print>
        <Secondary :title='title2'></Secondary>
        <Print :text='text2'></Print>
        <Pho :IMG="img6"></Pho>
        <div class="mar"></div>
        <Brief :Title="Title1" :Text="Text1" :IMG="IMG1" class="Top"></Brief>
        <Brief :Title="Title2" :Text="Text1" :IMG="IMG2"></Brief>
        <Brief :Title="Title3" :Text="Text3" :IMG="IMG3" class="Top" ></Brief>
        <Secondary :title='title3'></Secondary>
        <van-row gutter="20" v-lazy-container="{ selector: 'img' }">
          <van-col span="8"><img :data-src='require("@/assets/css/空气源/居民住宅.png")'><p>冬暖夏凉的环境对于居住和工作都有着重要的意义。瑞海热控空气源热泵可以—机满足居民住宅和写字楼的供暖制冷需求。体感舒适度显著性强于中央空调。相较于常规制冷空调只能通过开关压缩机或者变频来调节制冷温度，瑞海热控采用水媒辅助调节技术，使得风机盘管中的冷气温度更加精准、舒适。更不会因为经过导热片的风过于冰冷，而产生不必要的冷凝，带走房间的水气。瑞海热控还拥有凝露点检测技术，可以调节空气湿度，能根据屋内环境提供更具有湿润度和舒适性的出风。</p></van-col>
          <van-col span="8"><img :data-src='require("@/assets/css/空气源/商场.png")'><p>瑞海热控的螺杆空压机组空气源热泵面向大型商场、大型仓库、会议中心等客户。单机采暖制冷可覆盖10000平方米。配合风机盘管，温度调节效率更高。专利技术可以在极寒地区-35℃环境下运行。专利导热剂，使得能效更上一层楼。基于瑞海热控的温控网络，随空间温度调整空气源热泵功率和出水口温度，降低热损耗，运行效率更高，温控开支更低。</p></van-col>
          <van-col span="8"><img :data-src='require("@/assets/css/空气源/build.png")'><p>对于别墅等高端客户，瑞海热控倾力打造了基于空气源热泵的五恒系统。包含恒温、恒湿、恒氧、恒净、恒静。墙体6面全部铺设温控毛细管，一年四季温度都控制在人体舒适的16~24℃区间。新风系统出口小而多的设计使得房间噪音在不断换气过滤的同时，噪音在20分贝以内。配套湿度和含氧量调控装置让人健康舒适。</p></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/yiyuan.png")'>
             <b>医院</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/bieshu.png")'>
             <b>别墅</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/shangchang.png")'>
             <b>商场</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/jiudian.png")'>
             <b>酒店</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/xiyu.png")'>
             <b>洗浴中心</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/meikuang.png")'>
             <b>煤矿</b>
         </van-col>
         <van-col span="3">
             <img :src='require("@/assets/css/空气源/gongchang.png")'>
             <b>工厂厂房</b>
         </van-col>
        </van-row>
        
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Main from '@/common/main.vue'
import Subroute from '@/common/Subroute.vue'
import Secondary from '@/common/secondary.vue'
import Print from '../common/print.vue'
import Pho from "@/common/pho.vue";
import Brief from '../common/brief.vue'
export default {
    components:{
       Primary,
       Main,
       Subroute,
       Secondary,
       Print,
       Brief,
       Pho
    },
    data() {
        return {
            deviceType: '',
        //   videoUrl:require('@/assets/clear.mp4'),
           activeName: 'air',
           title:'清洁供暖',
        //    text:'瑞海热控的空气源热泵系统针对市场需求和智能化进步，不断革新升级，采用创新双极压缩机和喷气增焓技术，大幅提升了机组整体性能。实现了一台气源热泵机组，即可提供冷/热双供空调、地暖、热水供应、恒温储能等多功能一体化服务。',
           title1:'空气源热泵智控',
           text1:'瑞海热控的空气源热泵系统有冷暖双供，节能环保、休感舒适等特点。通过瑞海热控的智能控制，可以实现一部气源热泵机组就能提供提冷/熟供空调、地暖、热水供应、恒温储藏等多功能一体化服务。瑞海热控通过高精准的温度、湿度、气压探测器能准确收集环境信息，制定最大化程度的空气蓄/放热方案，精确变频控制机组功率，高效集热/制冷。出色的热回收技术能大幅度回收再利用排向外界的废热，大大提升节能减排的效果。瑞海热控可以对各个分机盘管和热水阀门智能控制，结合室温给供暧区域提供最舒适的冷/热供应。瑞海热控的空气源热泵系统在居民住宅、别墅、大型商场、写字楼、仓库、酒窖、会议中心都有广泛的使用。',
           title2:'空气源热泵系统',
        //    text2:'瑞海热控的空气源热泵系统针对巿场需求和智能化进步，不断革新升级，采用创新双级压缩机和喷气增熔技术，大幅提升了机组整体性能。实现了一台气源热泵机组，就可提供冷/热双供空调、地暖、热水供应、恒温储藏等多功能—体化服务。通过瑞海热控的智能控制系统，设备在节能环保上将达到极致。',
           title3:'经典应用场景',
           Title1:'瑞海螺杆式超低环温机组',
           Text1:'瑞海螺杆式超低环温空气源热泵机组专门针对北方分布式集中供暖市场。单机即可覆盖10000平方米以上范围的居民小区及大型商业建筑供暖，尤其适用于大型工业应用场景，低温环境温度下能效比极高，是替代燃煤锅炉的完美解决方案，适用于大型客户进行选择。',
           IMG1:require('@/assets/css/空气源/2.png'),
           Title2:'多功能串联机组',
           IMG2:require('@/assets/css/空气源/3.png'),
           Title3:'家用模块化机组',
           Text3:'在集控系统中包含了多个独立设置的空气源、温度传感控制模块和云服务器。有效地对用热能耗进行有效管控，减少空气源主机运行频率。可实现一户一机，灵活性极高。适合个人和小型客户选用。',
           IMG3:require('@/assets/css/空气源/3.jpg'),
           img5:require('@/assets/css/空气源/lingyu.jpg'),
           img6:require('@/assets/css/空气源/rebeng.png')
        }
    },
     mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
    methods:{
         _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
    }
}
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin: auto;
    overflow: hidden;
    position: relative;
    .ditu{
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
     .tubiao{
      position: relative;
       width: 100%;
       height: 100%;
        top: -15Px;
        left: 0;
        bottom: 0;
        right: 0;
        margin-top: -10000px;
        z-index: 22;
    }
    .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    }
}
ul{
    width: 1200Px;
    margin: 0 auto;
    display: flex;
    
}
.mar{
    margin-top: 40Px;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.L{
    margin-left: 15Px;
}
ul li{
    width: 130Px;
    float: left;
    text-align: center;
}
.Top{
    background-color: #ebecee;
}
img{
    width: 100%;
}
.pho{
  width: 1200Px;
  margin: 0 auto;
}
.van-row{
    width: 1200Px;
    margin: 20Px auto;
    text-align: center;
}
.van-row p{
    text-align: justify;
    text-align-last: left; 
    font-size: 14Px;
}
.van-row b{
    text-align: center;
    font-weight: normal;
    font-size: 14Px;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>