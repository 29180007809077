// 关于我们         
<template>
    <div>
        <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt=""><div class="repet"><div class="text">瑞海热控（山西瑞海节能网控电热集中供暖有限公司)于2009年成立于山西太原，扎根集中供暖行业十余年。依托自主研发的互联网云控制系统与物联网概念的应用，逐步由传统领域向智能化转型，致力于改变传统集中供暖的落后非环保方式，从低碳环保领域出发，为客户提供智能化集中供暖、恒温控制、能源管理与碳能管理等综合一体化服务。</div></div>
        </div>
        <Primary :title="title"></Primary>
        <p>依托云平台控制方案，为客户打造最舒适、便捷的生活环境</p>
        <div class="bg">
            <Secondary :title="title1"></Secondary>
            <Pho :IMG='img1'></Pho>
            <Print :text='text1'></Print>
        </div>
        <Secondary :title="title2"></Secondary>
        <van-row type="flex" justify="space-between">
            <van-col span="7">
                <b>特许经营权</b>
                <img :src='require("@/assets/css/关于我们/certificate2.jpg")'>
                <i>作为太原首家拥有特许经营权资质的供暖企业,改变了传统粗放式"一刀切"的供热模式,致力于依托信息化,提供更加绿色、高效、多元的新能源供热体系服务。</i>
            </van-col>
			<van-col span="7">
			    <b>高新技术企业</b>
			    <img :src='require("@/assets/css/关于我们/gaoxin.png")'>
			    <i>瑞海在2022年12月12日成功获批成为高新技术企业</i>
			</van-col>
            <van-col span="7">
                <b>十大创领品牌</b>
                <img :src='require("@/assets/css/关于我们/certificate2.png")'>
                <i>由中华民族建筑研究会供热采暖委员会及《暖通空调》杂志社、中国建筑节能减排产业联盟颁发。</i>
            </van-col>
            <van-col span="7">
                <b>校园清洁供热</b>
                <img :src='require("@/assets/css/关于我们/certificate3.png")'>
                <i>由中国民族建筑研究会供暖采暖委员会审核颁发，瑞海成熟的智慧校园清洁供暖技术对清洁能源进校园的推广具有划时代意义。可对全国校园清洁供暖项目提供测评平台，并进行评估、指导。</i>
            </van-col>
            <van-col span="7">
                <b>碳资产管理</b>
                <img :src='require("@/assets/css/关于我们/cartificate4.png")'>
                <i>瑞海积极践行国家碳中和战略，为暖通低碳化转型提供了专项服务。目前公司多人已获得国家人社局考核认证的碳资产管理证书，并与国网公司及轨道交通下属国碳公司达成合作，力促碳市场在山西发展成熟。</i>
            </van-col>
            <van-col span="7">
                <b>BIM会员单位</b>
                <img :src='require("@/assets/css/关于我们/BIM.png")'>
                <i>瑞海作为暖通运维领域BIM技术应用的先行者，技术受获山西省BIM技术创新联盟认可、授牌。相关转化成果为我省暖通行业开辟出现代化转型发展之路。</i>
            </van-col>
        </van-row>
        <div class="black">
        <van-row type="flex" justify="space-between">
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/综合云系统.png")'><em>综合云系统专利</em>
            </van-col>
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/集中供热模组.png")'><em>集中供热模组专利</em>
            </van-col>
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/跨热源控制.png")'><em>跨热源控制专利</em>
            </van-col>
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/软件著作权.png" )'><em>软件著作权</em>
            </van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/国家创新.png")'><em>国家科技部创新新技术认证</em>
            </van-col>
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/谷期智控.png")'><em>谷期智控电蓄热技术认证</em>
            </van-col>
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/安全管理.png")'><em>安全管理体系认证</em>
            </van-col>
            <van-col span="5">
                <img :src='require("@/assets/css/关于我们/建筑资质.png" )'><em>建筑安装资质</em>
            </van-col>
        </van-row>
        </div>
        <Secondary :title="second1"></Secondary>
        <Collapse></Collapse>
        <Secondary :title="second2"></Secondary>
        <Pho :IMG='img2'></Pho>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Secondary from '../common/secondary.vue'
import Pho from '@/common/pho.vue'
import Print from '@/common/print.vue'
import Collapse from '@/common/collapse.vue'
export default {
    components:{
        Primary,
        Secondary,
        Pho,
        Print,
        Collapse
    },
    data() {
        return {
            title:'公司愿景',
            title1:'关于公司',
            img1:require('@/assets/css/关于我们/1.png'),
            text1:'公司作为太原市首家获得供暖特许经营权的企业(证书编号20120001)，拥有7项发明专利，13项实用新型专利.在全国范围内，开展了综合集中供暖业务。先后服务于“国家电网”“同煤集团”“太原学院”“北京旅游局”等中大型客户，热力覆盖面积达100万平方米以上。能够一站式满足采暖方案设计、施工、调试质检、运营维护等需求。为推动分布式热源和传统行业智慧赋能发挥出优势作用。',
            title2:'公司资质',
            second1:'核心团队',
            second2:'合作团队',
            img2:require('@/assets/css/关于我们/footer.png')
        }
    },
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            // height: 230Px;
            background-color: #f56a00;
            color: #fff;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
        }
   }
  
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.img img{
    width: 100%;
}
.black{
    background-color: #000;
    width: 100%;
    margin: 50PX 0;
    color: #fff;
    padding: 40Px 0;
    .van-row{
        width: 1200Px;
        margin: 40Px auto;
        text-align: center;
    }
    .van-col{
        img{
            // width: 100%;
            height: 180Px;
            width: auto;
        }
        em{
            font-style: normal;
            text-align: center;
            font-size: 20Px;
            display: inline-block;
            line-height: 40Px;
        }
    }
    
}
 .van-row{
        width: 1200Px;
        margin: 40Px auto;
        text-align: center;
    }
    .van-col{
        img{
            // width: 100%;
            height: 140Px;
            width: auto;
            margin-top: 20Px;
        }
        i{
            font-style: normal;
            text-align: center;
            text-align: justify;
            display: inline-block;
           text-align-last: left;
        }
        b{
            font-size: 20Px;
            font-weight: normal;
            display: inline-block;
            background-color: #f56a00;
            color: #fff;
            width: 100%;
            line-height: 40Px;
        }
    }
.van-divider{
    width: 1200Px;
    margin: 0 auto;
   font-size: 28Px;
}
p{
    width: 1200Px;
    margin: 0 auto;
    text-align: center;
    font-size: 22Px;
}
.bg{
    width: 100%;
    margin: 0 auto;
    background-color: #eff0f2;
    padding: 20Px 0;
    box-sizing: border-box;
    margin-top: 20Px;
}
// .van-row{
//     text-align: center;
//     width: 1200Px;
//     margin: 0 auto;
//     padding: 40Px 0;
//     box-sizing: border-box;
//     // border-bottom: 2Px dashed #b7b7b7;
// }
// .van-col img{
//     width: 200Px;
// }
// .van-col .B{
//     width: 120Px;
// }
// .van-col span{
//     font-size: 50PX;
//     font-weight: 800;
//     color: #e3e3e3;
// }
// .van-col b{
//     color: #f56a00;
//     font-size: 24Px;
//     margin-left: 10Px;
// }
// .van-col i{
//     display: block;
//     text-align: left;
//     font-size: 16Px;
//     font-style: normal;
//     line-height: 24Px;
// }
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>