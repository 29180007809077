<template>
  <div class="head" :style="topNavBg">
    <div class="logo">
      <img src="@/assets/css/logo.png" />
    </div>
    <div class="menu">
      <ul>
        <li><router-link to="/home-page">瑞海首页</router-link></li>
        <li><router-link to="/about-us">关于我们</router-link></li>
        <li>
          <a href="#">整体服务</a>
          <ul>
            <li>
              <a href="/system?active=one">智慧供热管理系统</a>
              <ul>
                <li>
                  <router-link to="/system?active=one" class="thirdh">系统介绍</router-link>
                </li>
                <li>
                  <router-link to="/waterway?active=two">水路供暖智控系统</router-link>
                </li>
                <li>
                  <router-link to="/circuit?active=three">电路供暖智控系统</router-link>
                </li>
              </ul>
            </li>
            <li>
              <a href="/heating-cable?active=one">清洁供暖</a>
              <ul>
                <li>
                  <router-link to="/heating-cable?active=one" class="thirdh"
                    >发热电缆</router-link
                  >
                </li>
                <li><router-link to="/gas-boiler?active=two">燃气锅炉</router-link></li>
                <li><router-link to="/air-source?active=three">空气源</router-link></li>
              </ul>
            </li>
            <li>
              <router-link to="/hot-water" class="sech">热水服务</router-link>
            </li>
            <li>
              <a href="/photovoltaic-array?active=one">综合能源应用</a>
              <ul>
                <li>
                  <router-link to="/photovoltaic-array?active=one" class="thirdh"
                    >光伏</router-link
                  >
                </li>
                <li>
                  <router-link to="/electric-tracing?active=two">电伴热</router-link>
                </li>
                <li><router-link to="/carbon-energy?active=three">碳能管理</router-link></li>
                <li><router-link to="/energy-port?active=four">能源港</router-link></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">应用场景</a>
          <ul>
            <li><router-link to="/school?active=one" class="sech">学校</router-link></li>
            <li><router-link to="/hospital?active=two" class="sech">医院</router-link></li>
            <li>
              <router-link to="/office-building?active=three" class="sech"
                >政府机关</router-link
              >
            </li>
            <li>
              <router-link to="/conference?active=four" class="sech">写字楼</router-link>
            </li>
            <li>
              <router-link to="/residenttial?active=five" class="sech"
                >工业园区</router-link
              >
            </li>
          </ul>
        </li>
        <li><router-link to="/service-process">服务流程</router-link></li>
        <li><router-link to="/news-information">新闻资讯</router-link></li>
        <li><router-link to="/great-case">精彩案例</router-link></li>
        <li><router-link to="/contact-ruihai">联系瑞海</router-link></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topNavBg: {
        backgroundColor: "rgba(0,0,0,.5)",
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.topNavBg.backgroundColor = `rgba(80, 80, 80,
	        	${scrollTop / (scrollTop + 80)})`;
      } else if (scrollTop === 0) {
        this.topNavBg.backgroundColor = "rgba(0,0,0,.5)";
      }
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.head {
  position: relative;
  max-width: 100%;
  min-width: 3300px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
}
.logo {
  height: 150px;
  margin: 15Px 0;
  box-sizing: border-box;
  margin-right: 10%;
}
.logo img {
  display: inline-block;
  // height: 240px;
  height: 220px;
  margin-top: -16Px;
}

body a {
  color: #f5f5f5;
}
.menu {
  height: 150px;
  display: block;
  display: flex;
  z-index: 999;
}
.menu ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
  white-space:nowrap;
  border: 0;
  height: 150px;
  display: block;
  z-index: 999;
}
.menu ul li {
  // float: left;
  position: relative;
  padding: 0;
  margin: 0;
  // display: block;
  display: inline-block;
  border: 0;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.menu ul li:hover ul {
  display: block;
}
.menu ul li a {
  width: 280px;
  font-size: 0.8rem;
  font-size: 16Px;
  height: 148px;
  margin-top: -4Px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.menu ul li ul {
  display: none;
  position: absolute;
  left: 0px;
  top: 183px;
}
.menu ul li ul li {
  width: 280px;
  height: 110px;
  line-height: 120px;
  display: block;
  padding: 0px;
  border-bottom: 1px solid rgb(255, 255, 255);
  font-size: 0.1rem !important;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}
.menu ul li ul li a{
  font-size: 14Px;
}
body a:hover {
  color: #f56a00;
}
.menu > ul > li:hover {
  border-bottom: 5px solid #f56a00;
}
.menu ul li ul li:hover ul {
  visibility: visible;
}
.menu ul li ul li ul {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 283px;
}
.menu ul li ul li ul li {
  background: rgba(0, 0, 0, 0.5);
}
.menu ul li ul li ul li a{
  font-size: 14Px;
}
</style>