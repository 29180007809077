// 学校
<template>
  <div>
    <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt=""><div class="repet"><div class="text">瑞海热控致力于为用户提供节能环保和降低用户取暖价格的新型技术开发和利用。目前，可将空气源热泵、蓄热式发热电缆、低氮全预混燃气锅炉等清洁供热方式，根据用户实际使用环境和配套条件将以上清洁供热方式进行单独或混合配套，一站式从设计、施工、调试、质检、运行、维护、能源端满足用户差异化需求。</div></div>
        </div>
    <Primary :title="Title"></Primary>
    <Subroute></Subroute>
    <div class="bc">
       <Secondary :title="title"></Secondary>
    <el-row type="flex"  justify="space-between" >
      <el-col :span="10" class="top"><span>01</span>
          <b>建筑结构复杂，用途多样 </b><p>不同区域建筑根据建设要求，所要满足的供暖温度要求各不相同。粗放式供暖无法根据校园多样复杂的建筑类型制定因地适宜的供暖方案。办公、教学、住宿等多种建筑类型，供热设计需更精确。学校负荷设计需满足白天办公教学，夜间学生住宿的供暖需求，要合理修正热负荷，实现管路切换与有效供暖。</p></el-col>
      <el-col :span="10"><img :src="img1" alt=""></el-col>
    </el-row>
    <el-row type="flex"  justify="space-between">
      <el-col :span="10"><img :src="img2" alt=""></el-col>
      <el-col :span="10" class="top"><span>02</span>
          <b>供暖周期多变  </b><p>由于学校人员流动性极大，各个不同区域所需的供暖时间段和供暖周期也不同。采暖季每日有效供暖时间≤12小时；二是有半个月左右的局部供暖需求或临时供暖需求；三是采暖季最冷月基本空置。</p></el-col>
    </el-row>
    </div>
      <Secondary :title="title1"></Secondary>
      <Pho :IMG="img3"></Pho>
    <Print :text="text4"></Print>
      <!-- <van-row type="flex" justify="space-between">
           <van-col span="10">
               <p>瑞海热控绿色校园综合节能方案的建设分为硬件建设和软件建设，大数据应用三个方面。自2008至今，全国有300所学校，不程度的都建设了能源管理系统和一定范围内的节能改造，随着大数据时代的来临，更加需要收集完善的全面的数据进行挖掘分析，还原校园真实能源消耗轨迹，更准确地掌握用能潜在规律和存在浪费的网点，结合先进的节能技术，帮助学校管理，实现精细化，智能化的创新路径。其中应用综合能源实现清洁高效供热的模式如下：电采暖、燃气锅炉、低氮燃气锅炉、空气源热泵、空调等。</p>
           </van-col>
           <van-col span="10"><img :src='img3'></van-col>
        </van-row> -->
    <Secondary :title="title2"></Secondary>
    <Pho :IMG="img5"></Pho>
    <Print :text="text5"></Print>
    <Secondary :title="title3"></Secondary>
    <img v-lazy="img6" class="small">
    <!-- <Pho :IMG="img6" class=""></Pho> -->
    <Print :text="text6"></Print>
    <!-- <van-row type="flex" justify="space-between">
      <van-col span="10"><img :src='img6' class="sm"></van-col>
           <van-col span="10">
               <p class="cen">搭建能耗实时监测与控制节能综合应用平台，利用监测与控制系统通过监测和控制过滤、水泵、供热管网等楼宇设备，有效实现能源可视化，能源平衡化与用能优化；采暖系统通过对热源端和关键节点控制，可实现供热精细化和节能自动化运行目标。</p>
           </van-col>
           
        </van-row> -->
  </div>
</template>
<script>
import Subroute from "@/common/Subroute3.vue";
import Primary from "@/common/primary.vue";
import Secondary from "@/common/secondary.vue";
import Pho from "@/common/pho.vue";
import Print from "@/common/print.vue";
export default {
  components: {
    Subroute,
    Primary,
    Secondary,
    Pho,
    Print,
  },
  data() {
    return {
      Title: "解决方案",
      title: "校园多楼宇综合供热特点",
      img1: require("@/assets/css/校园/xuqiupng.png"),
     title1: "绿色校园综合节能方案",
      img2: require("@/assets/css/校园/zhouqi.png"),
     title2: "建筑节能综合应用平台",
     title3: "能耗实时监测与控制节能综合应用平台",
        img3: require("@/assets/css/校园/lvse.png"),
      img5: require("@/assets/css/校园/guanli.png"),
      text4:'瑞海热控绿色校园综合节能方案的建设分为硬件建设和软件建设，大数据应用三个方面。自2008至今，全国有300所学校，不程度的都建设了能源管理系统和一定范围内的节能改造，随着大数据时代的来临，更加需要收集完善的全面的数据进行挖掘分析，还原校园真实能源消耗轨迹，更准确地掌握用能潜在规律和存在浪费的网点，结合先进的节能技术，帮助学校管理，实现精细化，智能化的创新路径。其中应用综合能源实现清洁高效供热的模式如下：电采暖、燃气锅炉、低氮燃气锅炉、空气源热泵、空调等。',
      text5:'通过监测和控制循环水泵，照明变配电供热管网等楼宇设备有效实现能源管理的可视化，实现能源平衡调度与用能优化。结合建筑的实际情况，通过降低能源消耗，优化能源成本，提供能源供给使用等方式，在智能分析技术模型和工具完成效率性能计算的基础上，继而形成能好实时监控，即建筑节能综合应用平台。',
      img6:require("@/assets/css/校园/2.png"),
      text6:'搭建能耗实时监测与控制节能综合应用平台，利用监测与控制系统通过监测和控制过滤、水泵、供热管网等楼宇设备，有效实现能源可视化，能源平衡化与用能优化；采暖系统通过对热源端和关键节点控制，可实现供热精细化和节能自动化运行目标。'
      // text6:'搭建能耗实时监测与控制节能综合应用平台，利用监测与控制系统通过监测和控制过滤、水泵、供热管网等楼宇设备，有效实现能源可视化，能源平衡化与用能优化；采暖系统通过对热源端和关键节点控制，可实现供热精细化和节能自动化运行目标。'
    };
  },
};
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            // height: 230Px;
            background-color: #f56a00;
            color: #fff;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
        }
   }
  
.img img {
  width: 100%;
  background-color: #c8c9cb;
}
.el-row{

  text-align: center;
    width: 1200Px;
    margin: 50Px auto;
    display:flex;
    img{
        width: 100%;
    }
    p{
      font-size: 16Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;
    }
     span {
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
  
}
.top{
    margin-top: 40Px;
  }
 b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
}
.bc{
  background-color: #eff0f2;
  padding: 40Px 0;
}
.small{
    width: 1200Px;
    display: block;
    margin: 0 auto;
}
.van-row{
  width: 1200Px;
  margin: 0 auto;
  img{
    width: 100%;
  }
  .sm{
    width: 80%;
  }
  p{
    margin-top: 15%;
  }
  .cen{
    margin-top: 40%;
  }
}
.small{
width: 1000Px;
margin: 0 auto;
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>