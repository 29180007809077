// 系统介绍
<template>
  <div class="container">
    <div class="img">
     <img class="bannerImg" :src="require('@/assets/css/banner/zhikong.png')" alt="">
            <div class="reph">
                <div class="text">
                    瑞海热控结合十余年的供热开发、设计、运营经验与技术积累，自主研发形成了高集成度的一体化智慧供热管理系统。可对蓄热式发热电缆、空气源热泵、燃气锅炉等多种分布式热源模式进行融合管理，以满足不同应用场景对供热差异化、精细化、阶段式需求。
                </div>
            </div>
    </div>
    <Primary :title="title1"></Primary>
    <Subroute4></Subroute4>
    <Main :text="text1"></Main>
    <!-- 产品介绍 -->
    <div class="bc">
      <Secondary :title="title2"></Secondary>
      <van-row type="flex" justify="space-between" class="Top">
        <van-col span="10"
          >
          <img :src='require("@/assets/css/智慧能源云平台/产品介绍1.png")' 
        />
        </van-col>
        <van-col span="10" class="T">
          <span>01</span>
          <b>室温智能调节</b>
          <p>
            用户要改变预设温度计运行状态，系统根据设定值下发运行指令至楼层控制模块，楼层控制模块根据设定数据不间断采集各个控制节点的数值（室内温度传感器、水温传感器，人员流动传感器等)当室温智能调节。
          </p>
        </van-col>
      </van-row>
      <van-row type="flex" justify="space-between">
        <van-col span="10" class="B">
          <span>02</span>
          <b>满足差异化温度需求</b>
          <p>
            当面临差别供暖需求时，可在操作界面直接修改运行数据或调整至预设方案，以满足不同受热要求。在供热需求量较低时段，系统可自动调整为节能模式，并根据实际需求对各区域进行单独调温，以区域式为手段保障全面性供暖服务。当系统运行节能模式时，系统将根据各个温控节点反馈的数据，自动调整各级节点开度与热源实时功率。所有的运行数据可在控制界面及移动端APP上实时查看，运行数据及运行参数在“云”端存储、运行安全可靠。
          </p>
        </van-col>
        <van-col span="10"
          ><img :src='require("@/assets/css/智慧能源云平台/产品介绍2.png")' 
        /></van-col>
      </van-row>
    </div>
    <!-- 集成化 -->
    <Secondary :title="title3"></Secondary>
    <Print :text="text2"></Print>
    <img v-lazy='require("@/assets/css/智慧能源云平台/1.png")'  class="ce">
    <div class="IMG">
      <div class="c">
        <img v-lazy='require("@/assets/css/智慧能源云平台/2.jpg")' />
      <p>
        瑞海智能热控系统能根据用户的需求和预算，做出热源之间的权衡，灵活在不同的局部采取不同热源方案，达到整体最优。
      </p>
      </div>
      
    </div>
    <div class="dashe">
      <van-row type="flex" justify="space-between">
        <van-col span="12"
          ><img :src='require("@/assets/css/智慧能源云平台/3.png")'
        /></van-col>
        <van-col span="10">
          <p class="e">
            除此之外，瑞海智能热控系统RH.OS
            V3.0.1还有实时大数据接口，针对一些决定性的影响因素进行云计算分析，动态模拟运行情况选择最优的供暖方案。
            例如，发热电缆和空气源热泵等设备对电价的敏感度非常高，电价的一点点变化都会引起整体运营成本的大幅变动。瑞海智能热控系统会结合实时峰谷用电负载和价格的大数据对热源组合进行适当调整，在峰电价到来之前，使用发热电缆储存好足够的热能缓慢放热或切换成其他更经济的热源。在满足供热需求的情况下，选择最经济实惠的热源组合。
          </p>
        </van-col>
      </van-row>
    </div>
    <h4>集成化优势总结：结合各热源的优点；局部灵活；整体最优。</h4>
    <!-- 可视化 -->
    <Secondary :title="title4"></Secondary>
    <!-- <img :src='require("@/assets/css/智慧能源云平台/2.png")' alt="" class="ce"> -->
    <div class="dashe">
      <van-row type="flex" justify="space-between">
        
        <van-col span="10">
          <p class="r">
            瑞海热控还可以根据客户的设备的具体厂商、型号、功能量身定做可视化的操空界面。我们将用拟真3D模型把设备还原在控制系统中，并且显示有由精准传感器采集而来的舒适。用户还可以直观检查设备各个部件的具体运行参数，例如，特定部件和位置的温度、湿度、压力、有害空气浓度、烟雾等等。瑞海热控的工程师会根据这些参数设置相应的预警警报阀值，如果某一参数超过了系统的设定的安全阀值，系统会在3D模型上高亮有安全隐患的部件并发出警报，通知运维人员及时处理。
          </p>
        </van-col>
        <van-col span="12"
          ><img :src='img1'
        /></van-col>
      </van-row>
    </div>
    <h4>可视化优势：体验舒适；热效率高；设备使用便捷。</h4>
    <!-- 智能化 -->
    <Secondary :title="title5"></Secondary>
    <Print :text='text4'></Print>
    <div class="dashe">
      <van-row type="flex" justify="space-between">
        <van-col span="12"
          ><img :src='img2'
        /></van-col>
        <van-col span="10">
          <p class="l">
            通过瑞海云计算，监控每个传感器的动态范围，实时调整设备功率，实现辅助节能。同时能按需部署，在所有区域均可设置独立的控制传感器，达到0.5℃的局部精确控温。考虑到不同房间、不同设备的不同需求客户可以设置延时开关、定时启停、区间温控等精确控制。反应迅速、可靠性高。 瑞海热控的云计算动态温控模块还会结合实时大数据下的环境特征给系统提供最科学的动态操控建议，例如可根据各地太阳落山时间段自动调整照明时间，会根据天气预报及时调整温度、湿度，为客户带来最舒适的居住体验。
          </p>
        </van-col>
      </van-row>
    </div>
    <h4>智能化优势：通过云计算辅助节能；按需部署；可靠性高。</h4>
    <!-- 多样化 -->
    <Secondary :title="title6"></Secondary>
    <Print :text='text6'></Print>
    <div class="dashe">
      <van-row type="flex" justify="space-between">
        
        <van-col span="12">
          <p class="r">
              瑞海建筑集群控制系统，集数据通讯、人机界面、监控服务器及其他外设为一体，可实现集中操作、管理、显示以及报警等。建筑集群物联网联控技术可以完美兼容各种常见设备的控制系统，实行全自动的综合管理，大大提高建筑和设备的安全性、经济性和寿命，为建筑管理者及广大客户提供最有效的信息服务和一个高效、舒适、便利、安全的环境。目前兼容的设备类型有:采暖、通风、电梯、空调监控，给排水监控，配变电与自备电源监控，火灾自动报警，视频监控等。从办公楼、居民楼等常见建筑，乃至礼堂、体育场馆等专项用途的建筑、还有机场、隧道、工厂、化工管道、畜牧养殖场等有特殊需求的工程，都能见到我们瑞海建筑集群控制系统的身影。
          </p>
        </van-col>
        <van-col span="12"
          ><img v-lazy='require("@/assets/css/智慧能源云平台/5.png")'
        /></van-col>
      </van-row>
    </div>
    <h5>多样化优势：延长设备的使用寿命；提高设备使用效率；优化设备运行方式</h5>
  </div>
</template>
<script>
import Primary from "@/common/primary.vue";
import Main from "@/common/main.vue";
import Subroute4 from '@/common/Subroute4.vue';
import Secondary from "@/common/secondary.vue";
import Print from "@/common/print.vue";
// import Pho from "@/common/pho.vue";

export default {
  components: {
    Primary,
    Main,
    Subroute4,
    // Gls,
    Secondary,
    Print,
    // Pho
  },
   methods:{
_isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
   },
   mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
  data() {
    return {
      deviceType: '',
      // videoUrl:require('@/assets/zhikong.mp4'),
      title1: "瑞海智慧供热管理系统",
      // text1:
      //   "瑞海热控结合十余年的供热开发、设计、运营经验与技术积累，自主研发形成了高集成度的一体化智慧供热管理系统。可对蓄热式发热电缆、空气源热泵、燃气锅炉等多种分布式热源模式进行融合管理，以满足不同应用场景对供热差异化、精细化、阶段式需求。",
      title2: "产品介绍",
      title3: "集成化",
      title4:'可视化',
      title5:'智能化',
      title6:'多样化',
      text2:
        "对考虑地域气候因素，用户差异化的冷热源诉求，瑞海热控针对性地开发了跨冷热源与复合热源的高集成化融合供暖系统。在兼容其他热源的基础上，因地制宜地达到效率与节能的兼配合一。瑞海智能热控系统H.OSV3.0.1基于瑞海热控十余年的综合管理恒温控制技术，依靠高集成度的一体化的解决方案和对相关软硬件与关键数据灵活掌控，做到对各类型热源的协调和统一，实现多热源同时供暖，突出局部供热最灵活、整体供热最优的特点，同时满足客户对供暖舒适度、运营经济性、节能可持续发展、新旧设备兼容性、设备持久性等复杂需求。",
        img1:require('@/assets/css/智慧能源云平台/产品介绍3.png'),
        text4:'智能化云计算是瑞海智能热控系统的技术保障。瑞海物联网云端每秒都会与我们的控制端发生交互。数据传感器上的任何数据都会随时与瑞海云端进行同步。',
        img2:require('@/assets/css/智慧能源云平台/4.jpg'),
        text6:'瑞海智能热控系统RH.OS V3.0.1的优势综合体现到了应用层面。基于瑞海热控搭建的物联网，控制系统可以和建筑集群的各种设备及其子操作系统无缝连接，实现对物联网内个节点的全方位集中管理、整体联动，从而延长设备的服役寿命、降低能源消耗、实现建筑集群自动化控制。瑞海智能热控系统可以实时与物联网中的各个节点交互，数据传感器上的任何数据都会第一时间与瑞海热控的云端服务器进行同步，管理员也能随时对各个设备下达指令。系统还可以实现设备按照预定工作状态的自动控制。以供暖系统为例，瑞海热控的传感器可以实现对目标区域的温度、湿度、亮度、气压等关键参数的实时监控，对温度进行自动调节。如果温度显著低于设定值，就加大功率，快速升温;当温度接近设定值时，减小功率，降低能耗，缓慢升温;当温度高于设定值时，我们会在这个房间停止供暖;当温度显著高于房屋设定值，房屋也停止了供暖。云控制系统还会综合传感器的数据，云控制系统还能判断区域是否有人供暖需求，如果没有人供暖需求，云控制系统会自动停止供暖，节约能耗。云控制系统还可以自动排查系统中的异常状态，当有疑似异常状态时，会触发警报系统，提醒大楼运维人员到现场查看，排查故障。',


    };
  },

};
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        // float: left;

        vertical-align: middle;
    }
    .reph{
         position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        left: 100Px;
        bottom: 0;
       .text{
           box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            // height: 230Px;
            background-color: #f56a00;
            font-size: 22Px;
            line-height: 38Px;
            color: #fff;
            padding: 40Px;
       }
    }
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.ce{
    width: 800Px;
    margin: 40Px auto;
    display: block;
}
h4{
    width: 1200Px;
    margin: 20Px auto;
    height: 60Px;
    text-align: center;
    color: #343434;
    font-size: 20Px;
    font-weight: normal;
}
h5{
    width: 1200Px;
    margin: 0 auto;
    margin-top: 20Px;
    text-align: center;
    color: #343434;
    font-size: 18Px;
    font-weight: normal;
}

.dashe {
  width: 1200Px;
  margin: 40Px auto;
  box-sizing: border-box;
  border: 2Px dashed #c8c9cb;
  color: #000;
  img{
    width: 100%;
  }
  .e{
    margin-top: 14Px;
    font-size: 16Px;
    padding-right: 75Px;
    box-sizing: border-box;
  }
  .l{
    margin-top: 10%;
    font-size: 16Px;
    padding-right: 75Px;
    box-sizing: border-box;
  }
  .r{
    margin-top: 10%;
    font-size: 16Px;
    padding-left: 75Px;
    box-sizing: border-box;
  }
  .s{
     font-size: 16Px;
    padding-left: 75Px;
    box-sizing: border-box;
  }
  .to{
    margin-top: 15%;
  }
}
.van-row {
  width: 1200Px;
  padding: 30Px 0;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
.img img {
  width: 100%;
}
.van-col img {
  width: 450Px;
}
.IMG {
  padding: 50Px 0 10Px 0;
  background-color: #eff0f2;
  .c{
    width: 1200Px;
    margin: 0 auto;
  }
}
.IMG p {
  color: #343434;
  font-size: 16Px;
  line-height: 50Px;
  text-align: center;
}
.IMG img {
  width: 1200Px;
}
.bc {
  width: 100%;
  margin-top: 20Px;
  background-color: #eff0f2;
  padding: 20Px 0;
}
.van-col span {
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
}
.van-col b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
.van-col p {
  font-size: 16Px;
  color: #000000;
  text-align-last: left;
  text-align: justify;
}
.Top {
  margin-bottom: 40Px;
}
.T {
  margin-top: 20Px;
}
.B {
  margin-top: 20Px;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>