<template>
  <div class="TAB">
      <div class="tab">
        <ul class="tab_button">
            <li 
         v-for="(item,index) in tabList" 
         :key="index" 
         :class="{active:currentClass==index}" 
         @mousemove="toggleTab(index)" 
            >{{item}}</li>
        </ul>
    </div>
    <div>  
        <div class="tab_con" v-show="currentTab==0" >
            <img :src='require("../assets/css/hot/购销.png")' alt="">
        </div>
        <div class="tab_con" v-show="currentTab==1">
            <img :src='require("../assets/css/hot/BOT.png")' alt="">
        </div>
        <div class="tab_con" v-show="currentTab==2">
            <img :src='require("../assets/css/hot/合伙人.png")' alt="">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {},
  data() {
    return {
      currentTab: 0,
      currentClass: 0,
      tabList: ["购销模式", "BOT模式", "商业合伙人模式"],
    };
  },
  methods: {
    toggleTab(current) {
      this.currentTab = current;
      this.currentClass = current;
    },
  },
};
</script>
<style lang='less' scoped>
.TAB{
    width: 1200Px;
    height: 420PX;
    background-color: #eff0f2;
    padding: 30Px 40PX;
    margin: 0 auto;
}
.tab .tab_button{
    display: flex;
    justify-content: space-around;
}
.tab_button li {
    text-align: center;
    cursor: pointer;
    float: left;
    width: 140Px;
    height: 42Px;
    line-height: 42Px;
    border: 1Px solid #f56a00;
    color: #f56a00;
    border-radius: 10Px;
    font-size: 14Px;
}
.tab_button li.active {
  background-color: #f56a00;
    color: #fff;
}
 .tab_con {
    text-align: center;
    margin-top: 50Px;
}
</style>