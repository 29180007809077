// 光伏
<template>
    <div class="container">
        <div class="img">
         <img :src="require('@/assets/css/banner/guangfu.png')" alt="">
            <div class="reph">
                <div class="text">
                    瑞海热控针对不同类型的客户，打造了多种光伏安装贷款模式供客户选择，客户可根据自己投资能力灵活调整，自我投资越高，回收周期越快。银行贷款最高可享10年期，客户在不投入自己实际资金前提下，由光伏所产生发电收益直接还款。十年之后，光伏剩余15年的所有收益全部归由客户（年收益根据客户保养情况，约1.2万元/年）。
                </div>
            </div>
        </div>
        <!-- 一级标题 -->
        <Primary :title='title1'></Primary>
        <!-- 正文部分 -->
        <Main :text='text1'></Main>
        <!-- 导航切换 -->
        <Subroute2></Subroute2>
        <!-- 发电原理演示 -->
        <Gls :Title="Title1" :IMG='IMG1' class="G"></Gls>
        <!-- 合作模式介绍 -->
        <Gls :Title="Title2" :IMG='IMG2'></Gls>
        <!-- 光伏收益详情 -->
        <Secondary :title='title2'></Secondary>
        <van-row type="flex" justify="space-between" gutter="24">
          <van-col span="12">
              <div class="left" v-lazy-container="{ selector: 'img' }">
                  <h1>富民宝</h1>
                  <img :data-src='require("@/assets/css/sun/tp1.png")'>
                  <p>面对资金较为充足农户，可为其提供整体光伏设施设备售卖及安装服务。70㎡屋顶投资约33块600W光伏板，以光伏每瓦约3.2元，相关配套费用约1.8元/W，投资约为99，000元，年收益约为12900元。用户理想状况下约7.5年回本。</p>
              </div>
              
          </van-col>
          <van-col span="12">
              <div class="right" v-lazy-container="{ selector: 'img' }">
                  <h1>阳光贷</h1>
                  <img :data-src='require("@/assets/css/sun/tp2.png" )'>
                  <p>光伏安装银行15年还款周期内，发电费用除还银行贷款外，农户还享受光伏发电年收益盈余，约每块板97元，以屋顶面积70㎡计算，可安装约30-33块，贷款期内年盈利发电收入约为2910-3201元；贷款还清后，每年可收入为12900-14256元；25年期内农户共盈利172,650.</p>
              </div>
          </van-col>
        </van-row>
        <!-- 系统特点 -->
        <div class="main-title">
            <h2>系统特点</h2>
        </div>
        <Change></Change>
        <div class="main-title">
            <h2>系统产品</h2>
        </div>
        <div class="H">
        <van-row gutter="20">
          <van-col span="6"><img :src='require("@/assets/css/sun/2.png")'><h4>光伏组件</h4></van-col>
          <van-col span="6"><img :src='require("@/assets/css/sun/3.png")'><h4>逆变器</h4></van-col>
          <van-col span="6"><img :src='require("@/assets/css/sun/4.png")'><h4>光伏计量箱</h4></van-col>
          <van-col span="6"><img :src='require("@/assets/css/sun/5.png")'><h4>电线电缆</h4></van-col>
        </van-row>
        </div>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Change from '@/common/Change.vue'
import Main from '@/common/main.vue'
import Subroute2 from '@/common/Subroute2.vue'
import Gls from '@/common/gls.vue'
import Secondary from '@/common/secondary.vue'
export default {
    components:{
        Primary,
        Main,
        Subroute2,
        Gls,
        Secondary,
        Change,
    },
    data() {
        return {
            deviceType: '',
            // videoUrl:require('@/assets/guangfu.mp4'),
            title1:'新能源综合应用',
            text1:'随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。',
            Title1:'用户光伏发电原理演示图',
            IMG1:require('@/assets/css/sun/1.png'),
            Title2:'合作模式介绍',
            IMG2:require('@/assets/css/sun/合作.png'),
            Title3:'系统特点',
            Title4:'系统产品',
            title2:'光伏收益详情',
            Title5:'优秀案例',
        }
    },
    methods:{
_isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
   },
   mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
}
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin:auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        // float: left;

        vertical-align: middle;
    }
    .reph{
         position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 100Px;
        bottom: 0;
       .text{
           box-shadow: 10Px 10Px 3px 2px #637687e6;
            width: 450Px;
            // height: 230Px;
            background-color: #49545bd5;
            font-size: 22Px;
            line-height: 38Px;
            color: #fff;
            padding: 40Px;
       }
    }
}
video{
        display: block;
        margin: auto;
        max-width: 100%;
}
video {
  pointer-events: none;
}
video::-webkit-media-controls-enclosure {
  display: none;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.A{
    display: flex;
    width: 1200Px;
    margin: 0 auto;
    margin-top: -50Px;
    justify-content: space-between;
    margin-bottom: 20Px;
    img{
        width: 49%;
    }
}
.AN{
    display: flex;
    width: 1200Px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 20Px;
    img{
        width: 49%;
    }
}
.G{
    width: 100%;
    padding: 30Px 0;
    background-color: rgba(240,240,240);
}
img{
    width: 100%;
    margin-left: -1PX;
}
.subroute{
    margin-top: 100px;
}
.van-row{
    width: 1200Px;
    box-sizing: border-box;
    margin: 0 auto;
    display: felx;
    justify-content: space-between;
    text-align: center;
}
.H{
    width: 100%;
    margin-top: -40Px;
    background-color: #eff0f2;
}
.van-col h1{
    font-size: 18Px;
    font-weight: normal;
}

.right {
    background-color: #eff0f2;
    padding: 10PX 30Px;
    box-sizing: border-box;
    height: 570Px;
}
.left {
    background-color: #eff0f2;
    padding: 10PX 30Px;
    box-sizing: border-box;
    height: 570Px;
}
.van-row p{
    text-align:justify; 
    text-align-last: left;
    font-size: 14Px;
}
h2{
  font-size: 28Px;
  font-weight: normal;
}
.main-title{
    text-align: center;
    margin-bottom: 60Px;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>