<template>
    <div class="report">
        <ul class="team">
            <h3>管理团队</h3>
            <li class="team_item" v-for="item in adminList" :key="item.name" >
                <img :src='item.img'>
                <b>{{item.name}}</b>
                <em>{{item.post}}</em>
                <ul>
                    <li>{{item.intro}}</li>
                </ul>
            </li>
        </ul>
        <ul class="team">
            <h3>战略咨询团队</h3>
            <li class="team_item" v-for="item in tacticList" :key="item.name">
                <img :src='item.img'>
                <b>{{item.name}}</b>
                <em>{{item.post}}</em>
                <ul>
                    <li>{{item.intro}}</li>
                </ul>
            </li>
        </ul>
        <ul class="team">
            <h3>硬件开发团队</h3>
            <li class="team_item" v-for="item in hardwareList" :key="item.name">
                <img :src='item.img'>
                <b>{{item.name}}</b>
                <em>{{item.post}}</em>
                <ul>
                    <li>{{item.intro}}</li>
                </ul>
            </li>
        </ul>
        <ul class="team">
            <h3>云技术开发团队</h3>
            <li class="team_item" v-for="item in exploitList" :key="item.name">
                <img :src='item.img'>
                <b>{{item.name}}</b>
                <em>{{item.post}}</em>
                <ul>
                    <li>{{item.intro}}</li>
                </ul>
            </li>
        </ul>
        <ul class="team">
            <h3>设计团队</h3>
            <li class="team_item" v-for="item in designList" :key="item.name">
                <img :src='item.img'>
                <b>{{item.name}}</b>
                <em>{{item.post}}</em>
                <ul>
                    <li>{{item.intro}}</li>
                </ul>
            </li>
        </ul>
        <ul class="team">
            <h3>市场团队</h3>
            <li class="team_item" v-for="item in sellList" :key="item.name">
                <img :src='item.img'>
                <b>{{item.name}}</b>
                <em>{{item.post}}</em>
                <ul>
                    <li>{{item.intro}}</li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            adminList:[
                {
                    img:require('@/assets/css/personnel/qinjianmin.png'),
                    name:'秦建民',
                    post:'董事长',
                    intro:'曾任山西航空公司董事长，海航集团高级执行副总裁，全面负责公司的各项工作。'
                },
                {
                    img:require('@/assets/css/personnel/qinyang.png'),
                    name:'秦洋',
                    post:'总经理',
                    intro:'美国西雅图华盛顿大学学士，芝加哥大学硕士，加入公司前曾任美国西雅图飞机制造公司Triton Aerospace市场总监。'
                },{
                    img:require('@/assets/css/personnel/weizhifeng.png'),
                    name:'魏志峰',
                    post:'总会计师',
                    intro:'毕业太原理工财经学院，高级会计师，注册税务师，10多年财务工作经验。曾任职于山西大昌汽车集团，山西真诚税务师事务所有限公司。'
                }
            ],
            tacticList:[
                {
                    img:require('@/assets/css/personnel/geyueqiang.png'),
                    name:'葛跃强',
                    post:'高级市场顾问',
                    intro:'太原市前供热办主任，从事供暖行业几十年，有着丰富的供暖经验。目前在瑞海热控负责供暖项目的战略部署的管理咨询。'
                },
                {
                    img:require('@/assets/css/personnel/xuwenjuan.png'),
                    name:'徐文娟',
                    post:'经济分析师',
                    intro:'日本早稻田大学商学硕士。三年国企会计师经验。擅长市场分析，业界分析等。为瑞海热控提供项目盈亏分析报告。'
                },{
                    img:require('@/assets/css/personnel/ludeyi.png'),
                    name:'卢德颐',
                    post:'战略规划师',
                    intro:'复旦大学电子工程学士，美国伊利诺理工大学信息学硕士。在美国西雅图飞机制造公司Triton Aerospace担任设备工程师，有多年项目规划经验。'
                }
            ],
            hardwareList:[
                {
                    img:require('@/assets/css/personnel/zhaoyu.png'),
                    name:'赵昱',
                    post:'总工程师',
                    intro:'毕业于中南大学土木工程专业，从事电采暖行业十余年，在设计、施工、运维等方面有着丰富的经验。主导瑞海热控多项技术方案的研发和实施。'
                },
                {
                    img:require('@/assets/css/personnel/liwentao.png'),
                    name:'李文涛',
                    post:'通讯工程师',
                    intro:'吉林大学通讯工程学院学士，曾就职于朗讯科技、阿尔卡特朗讯、诺基亚等全球顶尖通讯公司、负责通信网络架构及设计，有丰富的大型工程设计及运维经验。'
                },
                {
                    img:require('@/assets/css/personnel/yanziming.png'),
                    name:'闫子鸣',
                    post:'设备开发工程师',
                    intro:'美国纽约州立大学石溪分校机械工程硕士，克莱姆森大学车辆工程博士。主要研究方向为内燃机,热动力，热交换装置，精通工程热力学，流体力学，传热学，以及各类机械装置设计。在瑞海热控主要担任热力机的设计工作.。'
                }
            ],
            exploitList:[
                {
                    img:require('@/assets/css/personnel/chenxiaochen.png'),
                    name:'陈笑尘',
                    post:'项目经理',
                    intro:'山东建筑大学工程管理学士，美国伊利诺州立大学芝加哥分校城市规划硕士，渥太华大学IT-MBA。在美多家创业公司担任项目经理职位，有多年项目管理经验。目前负责瑞海热控的云控制平台的设计与开发。'
                },
                {
                    img:require('@/assets/css/personnel/shicechi.png'),
                    name:'石策驰',
                    post:'云平台架构工程师',
                    intro:'美国西雅图华盛顿大学工程学士。毕业后任职于西雅图一家金融科技公司。擅长全栈开发、云平台架构设计与部署。目前担任瑞海热控的云服务架构工作。'
                },
                {
                    img:require('@/assets/css/personnel/suiqili.png'),
                    name:'隋绮丽',
                    post:'全栈工程师',
                    intro:'美国德保罗大学计算机工程与科学学士。在美国西北大学担任全栈工程师。熟练掌握多种开发语言，有十分丰富的交互开发经验。目前负责瑞海热控的全栈开发工作。'
                },
                {
                    img:require('@/assets/css/personnel/xuao.png'),
                    name:'徐傲',
                    post:'全栈工程师',
                    intro:'拥有多年开发经验，精通前、后端开发。在瑞海热控负责物联网架构和全站开发工作。'
                },
            ],
            designList:[
                {
                    img:require('@/assets/css/personnel/houchengxi.png'),
                    name:'侯承希',
                    post:'首席设计师',
                    intro:'美国南加州建筑学院建筑学硕士曾就职洛杉矶rRotoArchitects、archimorphic等建筑设计公司，精通空间设计，平面设计，产品设计等多媒体统筹规划。'
                },
                {
                    img:require('@/assets/css/personnel/lilinwei.png'),
                    name:'李林蔚',
                    post:'三维建模师',
                    intro:'毕业于辽宁工业大学数字媒体艺术专业，有丰富的建模，动画以及后期经验。在设计学，美学等领域都有自己独到的造诣。'
                },
                {
                    img:require('@/assets/css/personnel/fengnanqi.png'),
                    name:'冯楠淇',
                    post:'特聘建模师',
                    intro:'美国伊利诺理工大学建筑学士，在芝加哥一家知名建筑事务有四年工作经验。擅长建筑设计、室内设计、平面设计、3D建模。目前为瑞海热控提供专业的3D建模供暖场景还原。'
                },
                {
                    img:require('@/assets/css/personnel/geyilun.png'),
                    name:'葛逸伦',
                    post:'策划师',
                    intro:'曾服务中铁、融创、奇瑞、上海银行及南京军区等大型客户，主导广告片、宣传片、短视频、直播、大型活动等项目整体策划，涵盖多领域成功案例百余起，拥有丰富的影片策划指导经验。'
                },
            ],
            sellList:[
                {
                    img:require('@/assets/css/personnel/wangtaowei.png'),
                    name:'王韬伟',
                    post:'市场部副总经理',
                    intro:'曾任职央企大区市场部经理,负责市场营销运营及渠道开发，市场开拓经验丰富。'
                },
                {
                    img:require('@/assets/css/personnel/fengzixin.png'),
                    name:'冯子芯',
                    post:'办公室主任',
                    intro:'毕业于天津理工大学，曾从事独立策划工作，现负责公司人员综合管理。'
                },
            ],

        }
    },
}
</script>
<style lang="less" scoped>
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
h3{
    font-size: 20Px;
    width: 20Px;
    word-wrap: break-word;
}
.report{
    width: 1200Px;
    color: #333333;
    margin: 0 auto;
}
ul li ul{
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s;
    -webkit-transform:max-height 1s ease-out;
    width: 200Px;
    font-size: 12Px;
    margin-left: -50Px;
    margin-bottom: 5Px;
    // text-align-last: left;
    text-align: justify;
    text-align-last: left;

}
ul li:hover ul{
    max-height: 200Px;
    transition: max-height 1s;
    -webkit-transform:max-height 1s ease-in;
}
ul{
    display: flex;
}
ul li{
    width: 150Px;
    display: inline-block;
    margin-left: 50Px;
    // text-align: center;
    cursor: pointer;
}
ul li img{
    width: 140PX;

}
b,em{
    font-weight: normal;
    display: block;
    font-style: normal;
    text-align: center;
}
b{
    font-size: 20Px;
}
em{
    font-size: 16Px;
    margin: 5Px 0;
}
</style>