// 热水服务
<template>
    <div class="container">
        <div class="img">
            <img :src="require('@/assets/css/banner/di.png')" alt="">
            <div class="blue"><div class="box">生活热水服务<br><i>DOMESTIC HOT WATER SERVICE</i></div><div class="p">生活热水 淋浴 直饮水 衍生消费系统</div></div>
        </div>
        <Primary :title='title1'></Primary>
        <Main :text='text1'></Main>
        <Gls :Title="Title1" :IMG='IMG1'></Gls>
        <Secondary :title='title2'></Secondary>
        <Tab></Tab>
        <Secondary :title='title3'></Secondary>
        <div class="contant">
            <div class="top" v-lazy-container="{ selector: 'img' }">
                <img :data-src='require("@/assets/css/hot/yi.png")'>
                <table border="1" cellpadding="1px" cellspacing="0px">
                    <thead>
                       <tr>
                        <th>一体计量水控机</th>
                    </tr> 
                    </thead>
                    <tbody>
                        <tr>
                            <td>分体水控机</td>
                        </tr>
                        <tr>
                            <td>分体电控机</td>
                        </tr>
                        <tr>
                            <td>一体电磁阀</td>
                        </tr>
                        <tr>
                            <td>电控取电盒子</td>
                        </tr>
                        <tr>
                            <td>IC发卡器</td>
                        </tr>
                        <tr>
                            <td>水控机电源</td>
                        </tr>
                        <tr>
                            <td>IC卡智控水卡云服务器</td>
                        </tr>
                    </tbody>                   
                </table>
            </div>
            <div class="bottom" v-lazy-container="{ selector: 'img' }">
                <img :data-src='require("@/assets/css/hot/er.png")' alt="">
                <p>需要考虑楼内信号问题，在信号不好区域可采用离线设备。对通讯运营商的选择也需要结合实际具体定夺。</p>
            </div>
        </div>
        <div class="img" >
            <img :src='require("@/assets/css/hot/2.png")'>
        </div>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Main from '@/common/main.vue'
import Gls from '@/common/gls.vue'
import Secondary from '../common/secondary.vue'
import Tab from '@/common/tab.vue'
export default {
    components:{
        Primary,
        Main,
        Gls,
        Tab,
        Secondary
    },
    data() {
        return {
            deviceType: '',
            // videoUrl:require('@/assets/hot.mp4'),
            title1:'热水以及延伸服务',
            text1:'随心所“浴”，热享共“赢”，瑞海热水服务体系，可一站式提供生活热水、淋浴、直饮水及衍生消费服务，以满足不同场景差异化、定制化热水需求。',
            Title1:'热源优势与厂家对比',
            IMG1:require('@/assets/css/hot/热源优势.png'),
            title2:'商业模式',
            title3:'整体热水服务的配套元素',
            IMG3:require('@/assets/css/hot/热源优势.png'),
        }
    },
    mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
    methods:{
         _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
    }
}
</script>
<style lang="less" scoped>
.img{
     width: 100%;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        // float: left;

        vertical-align: middle;
    }
    .reph{
         position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        left: 100Px;
        bottom: 0;
       .text{
           box-shadow: 10Px 10Px 3px 2px #5f7486e6;
            width: 450Px;
            // height: 230Px;
            background-color: #49545bd5;
            font-size: 22Px;
            line-height: 38Px;
            color: #fff;
            padding: 40Px;
       }
    }
}
.img{
    width: 80%;
    margin: auto;
    position: relative;
    overflow: hidden;
     img{
        width: 100%;
        height: 100%;
        // float: left;
        vertical-align: middle;
        z-index: 1;
        // position: absolute;
    }
 .blue{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // right: 0;
        left: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-style: normal;
            font-size: 24Px;
        }
        .i{
            font-size: 20Px;
        }
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 9;
   }
    }
    .origin{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // right: 0;
        left: 0;
        bottom: 0;
         .box{
        background-color: #f56a00e4;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-style: normal;
            font-size: 24Px;
        }
        .i{
            font-size: 20Px;
        }
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00e4;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 9999;
   }
    }
  
}
img{
    width: 100%;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.contant{
    width: 1200Px;
    height: 100%;
    margin: 0 auto;
    padding: 0 40Px;
}
.top img{
    width: 600Px;
    float: left;
    padding: 20Px;
    margin-bottom: 40Px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 10px 21px 20px 1px rgba(0, 0, 0, 0.2);
    margin-right: 20Px;
}
.top{
    overflow: hidden;
}
table{
    width:335Px;
    text-align: center;
    margin-top: 60Px;
    margin-left: 800Px;
}
.bottom img{
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 40Px;
    width: 600Px;
    float: right;
    padding: 20Px;
    box-shadow: 12px 20px 20px 1px rgba(0, 0, 0, 0.2);
}
.bottom p{
    margin-top: 300px;
    float: left;
    width: 350Px;
    word-wrap: break-word;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>