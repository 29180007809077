<template>
  <div id="app">
     <div class="main">
        <ul class="main_header">
          <li 
            :class="{btn:true, active:select==obj.name}"  
            v-for="(obj) in datascore" 
            @mousemove="change(obj)" 
            @click='path(obj.path)'
            :key="obj.id"
          >
          <a :href="obj.path" target="_blank"  :class="{btn:true, active:select==obj.name}" >{{obj.name}} </a>
          </li> 
        </ul>
       
        <div class="main_content">
           <ul class="one"
            v-for="obj in  datascore" 
            v-show='obj.name==select' 
            :key="obj.id"> 
               <li 
                  :class="{btn:true,active:children_select==obj1.c_name}"   
                  v-for="(obj1) in obj.content" 
                  @mousemove="children_change(obj1.c_name)" 
                  @click='children_path(obj1.path)'
                  :key="obj1.c_name"
                  >
                  
                  <a :href="obj1.path" target="_blank" :class="{btn:true,active:children_select==obj1.c_name}"   > {{obj1.c_name}}</a>
                 
                </li> 
                 <!--内容  -->
                <div class="one_content" 
                    v-show="children_select==obj1.c_name" 
                    v-for="(obj1,DE) in obj.content" 
                    :key="DE" 
                    >
                          <div class="one_content_one" v-for="(obj2) in obj1.children" :key="obj2.d_name" @click='skip(obj2.path)' target='_blank'> 
                            <a :href="obj2.path" class="one_content_one" target="_blank" >
                              <div class="img" v-lazy-container="{ selector: 'img' }">
                                <img :data-src="obj2.img" /><br/>
                              </div>
                              {{obj2.d_name}}
                            </a>
                              
                          </div>
                      </div>
           </ul>
           </div>
        </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // key:'0',
      select: "清洁供暖",
      children_select:'\xa0\xa0\xa0\xa0'+"空气源"+'\xa0\xa0\xa0\xa0',
      datascore: [
        {
          name: "清洁供暖",
          path:'/heating-cable?active=one',
          content: [
            {
              c_name:'\xa0\xa0\xa0\xa0'+"发热电缆"+'\xa0\xa0\xa0\xa0',
              path:'/heating-cable?active=one',
              children: [
                { d_name: "瑞海热控严选市面上热效率最高的发热电缆设备,采用世界最前沿的高灵敏度的合金电阻材料或碳纤维发热材料,可适用于各种应用场景。将发热电缆预埋在指定供暖区域，通电使发热电缆发热,将舒适、持续、稳定的热量以热传导(对流)的方式输送给用户,整体经济环保。", img:require('@/assets/css/首页/dianlan.jpg'),path:'/heating-cable'},
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"燃气锅炉"+'\xa0\xa0\xa0\xa0',
              path:'/gas-boiler?active=two',
              children: [
                { d_name: "燃气锅炉系统解决方案采用全预混冷凝锅炉，冷凝燃气锅可充分回收烟气中的余热和地凝结潜热，热效率将大幅提高，满足国家低氮排放标准，具有高效节能、清洁环保两大优势。",img:require('@/assets/css/首页/ranqi.jpg'),path:'/gas-boiler'},
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"空气源"+'\xa0\xa0\xa0\xa0',
              path:'/air-source?active=three',
              children: [
                { d_name: "瑞海热控的空气源热泵系统针对市场需求和智能化进步，不断革新升级，采用创新双级压缩机和喷气增焓技术，大幅提升了机组整体性能。实现了一台气源热泵机组，即可提供冷/热双供空调、地暖、热水供应、恒温储藏等多功能一体化服务。", img:require('@/assets/css/首页/kongqiyuan.png'),path:'/air-source'},
              ],
            },
          ],
        },
        {
          name: "热水服务",
          path:'/hot-water',
          content: [
            {
              // c_name: "",
              children: [
                { d_name: "随心所“浴”，热享共“赢”，瑞海热水服务体系，可一站式提供生活热水、淋浴、直饮水及衍生消费服务，以满足不同场景差异化、定制化热水需求。", img:require('@/assets/css/hot/热源优势.png'),path:'/hot-water',},
              ],
            },
          ],
        },
        {
          name: "综合能源应用",
          path:'/photovoltaic-array?active=one',
          content: [
            {
              c_name:'\xa0\xa0\xa0\xa0'+"光伏"+'\xa0\xa0\xa0\xa0',
              path:'/photovoltaic-array?active=one',
              children: [
                { d_name: "瑞海热控针对不同类型的客户，打造了多种光伏安装贷款模式供客户选择，客户可根据自己投资能力灵活调整，自我投资越高，回收周期越快。银行贷款最高可享10年期，客户在不投入自己实际资金前提下，由光伏所产生发电收益直接还款。十年之后，光伏剩余15年的所有收益全部归由客户(年收益根据客户保养情况，约1.2万元/年)。", img:require('@/assets/css/sun/1.png'),path:'/photovoltaic-array', },
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"电伴热"+'\xa0\xa0\xa0\xa0',
              path:'/electric-tracing?active=two',
              children: [
                { d_name: "瑞海电伴热防冻及保温系统采用自限温电热带或恒功率电热带为加热元件。采用国内最先进的PTC高分子材料组方,结构上增加电阻过渡层,工艺采用双芯双层超薄共挤技术。对需要防冻及保温的各式设备进行各种温度需求的伴热或加热,补充流体损失掉的热量，使温度保持在允许的范围内。",img:require('@/assets/css/dianbanre/1.jpg'),path:'/electric-tracing', },
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"碳能管理"+'\xa0\xa0\xa0\xa0',
              path:'/carbon-energy?active=three',
              children: [
                { d_name: "瑞海可帮助企业进行暖通领域的节能诊断与设备减排改造，采用智能运维，从源头降低整体碳排放量。可将企业在一定时间内直接或间接产生的温室气体排放总量，通过交易市场的形式抵消，完成政策要求。并针对企业实际情况，配套开发企业碳项目，赋予碳价值。", img:require('@/assets/css/碳能管理/1.png'),path:'/carbon-energy',},
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"能源港"+'\xa0\xa0\xa0\xa0',
              path:'/energy-port?active=four',
              children: [
                { d_name: "瑞海热控的能源港项目是以清洁能源整体开发为核心，以清洁能源综合产出，应用与配套化设施建设为延伸的智慧新能源项目。整体能源将以新兴的光伏、风电、生物能、地热能和氢能为供给，建设新产能设施，实现能源港内部字自给自足同时，多余能源辐射周边业态应用。通过智能化综合能源管理系统，对项目整体的能耗进行监控、优化、管理，以实现对能源港及周边产业提供专业的能源应用解决方案（供电、供冷/暖、通风、照明等）的要求.",img:require('@/assets/css/能源港/1.png'), path:'/energy-port',},
              ],
            },
          ],
        },
        {
          name: "瑞海智慧能源云平台",
          path:'/system?active=one',
          content: [
            {
              c_name:'\xa0\xa0\xa0\xa0'+"系统介绍"+'\xa0\xa0\xa0\xa0',
              path:'/system?active=one',
              children: [
                { d_name: "瑞海智能热控系统能根据用户的需求和预算，做出热源之间的权衡，因地制宜适配热源方案，达到整体最优。", img:require("@/assets/css/智慧能源云平台/2.jpg"),path:'/system', },
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"水路供暖智控系统"+'\xa0\xa0\xa0\xa0',
              path:'/waterway?active=two',
              children: [
                { d_name: "系统通过对信息采集数据研判，模拟演算出热网关键节点最优运行方案，下达指令对热源设备功率、循环水泵速率动态水力平衡设备阀门开度统筹调节，保障了热网在平衡基础上的节能运行。",img:require('@/assets/css/智慧能源云平台/beng.png'),path:'/waterway', },
              ],
            },
            {
              c_name:'\xa0\xa0\xa0\xa0'+"电路供暖智控系统"+'\xa0\xa0\xa0\xa0',
              path:'/circuit?active=three',
              children: [
                { d_name: "室内每个独立结构内部均安装有温度传感设备，温度感应精度±0.5℃。RH电路供暖智控系统内楼层分组控制器最大负载温控设备63个，有8种供暖模式与6个时间段可供选择。智慧电采暖系统设置有加热保护系统可根据加热类型与负载情况选择3-30分钟加热间隔用以保护发热设备，避免发热设备因过热损毁。", img:require('@/assets/css/首页/dian.png'),path:'/circuit',},
              ],
            },
          ],
        },
      ],
    };
  },
  components: {

  },
  methods: {
    change(obj) {
      this.select = obj.name;
      this.children_select = obj.content[0].c_name;
    },
    children_change(flag) {
      this.children_select = flag;
    },
    
  },
};
</script>
 
<style lang="less" scoped>

img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.one_content_one .img{
    margin-bottom: 20Px;
}
.main_header{
  font-size: 0;
  width: 825Px;
  list-style: none;
  border-collapse:collapse;
  border-bottom: 2Px solid #f56a00;
  display: inline-block;
}
.main_header li{
    font-size: 18Px;
    float: left;
    padding: 10Px 20Px;
    cursor: pointer;
}
.main_header li a{
  color: #000;
}

.main_header li.active{
    background-color: #f56a00;
    color: #fff;
    border-radius: 10Px 10Px 0 0;
}
.main_header li a.active{
  
    color: #fff;
}
.one li.active{
    // color: #f56a00;
    background-color: #f56a00;
    box-sizing: border-box;
    color: #fff;
}
.one li{
    float: left;
    margin-top: -6Px;
    border-radius: 0Px 0px 15px 15px;
    cursor: pointer;
}

.one li a{
  color: #000;
  line-height: 38Px;
  display: inline-block;
}
.one li a.active{
    color: #ffffff;
}


.one_content_one img{
    width: 825Px;
    margin: 10Px 0;
}
.one_content_one{
  margin-bottom: 20Px;
  color: #000;
    font-size: 14Px;
}
</style>