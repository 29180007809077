// 医院
<template>
    <div>
      <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt=""><div class="repet"><div class="text">瑞海热控致力于为用户提供节能环保和降低用户取暖价格的新型技术开发和利用。目前，可将空气源热泵、蓄热式发热电缆、低氮全预混燃气锅炉等清洁供热方式，根据用户实际使用环境和配套条件将以上清洁供热方式进行单独或混合配套，一站式从设计、施工、调试、质检、运行、维护、能源端满足用户差异化需求。</div></div>
        </div>
        <Primary :title="Title"></Primary>
        <Subroute></Subroute>
        <div class="bc">
            <Secondary :title='title'></Secondary>
            <el-row type="flex"  justify="space-between" >
      <el-col :span="10" class="top">
        <span>01</span>
          <b>供热需求复杂，需求量大</b><p>人流量大、用热（冷）时间长、不同区域用热差异大。需根据季节性因素，冬季提供采暖、夏季提供供冷；且一般具有饮用热水、生活热水、医疗热水需求；特属区域需提供消毒蒸汽。</p>
          <span>02</span>
          <b>对供热和通风品质要求高</b><p>医院供暖和通风需要对空气进行净化，主动减少细菌或病毒，在减少交叉感染前提下，最大程度提升室内空气品质。</p>
          <span>03</span>
          <b>需要有应急供热保障 </b><p>住院部、化验室、手术室等场所对环境温度及湿度有精细调控需求，为应对特属情况发生，需要制定应急预案，保障供热稳定。</p>
          </el-col>
      <el-col :span="10"><img :src="img1" alt="" class="t"></el-col>
    </el-row>
        </div>
    <Secondary :title='title1'></Secondary>
    <van-row type="flex" justify="space-between">
          <van-col span="10"><img :src='img2'></van-col>
           <van-col span="10">
               <p>瑞海热控提供的夏季供冷，冬季采暖方案，以电采暖为核心的多元化能源服务，为客户提供涵盖规划设计，投资建设运维一体化的冷暖供应等新型业务节能工作。节能工作也是完善医疗体制改革的重要保障，加强医院用能管理，提高能源的利用效率，帮助建设节能型医院，具有重要的社会经济意义。整体采取平台+智能运维+生态合作伙伴运行模式。</p>
           </van-col>
           
        </van-row>
        <Secondary :title='title2'></Secondary>
        <Pho :IMG='img3'></Pho>
        <Print :text='text7'></Print>
        <Print :text='text8'></Print>
        <Secondary :title='title3'></Secondary>
        <Pho :IMG='img4'></Pho>
        <Print :text='text11'></Print>
        <Secondary :title='title4'></Secondary>
        <!-- <Pho :IMG='img5'></Pho>
        <Print :text='text13'></Print> -->
        <van-row type="flex" justify="space-between">
           <van-col span="10">
               <p class="i">能源项目采用多能互补方式，燃气锅炉/空气源热泵/蓄热式发热电缆+太阳能电力输出，保安电源接入用户侧站内供热与外部热网有效结合，提高医院功能的安全性。冷热接入用户端，并根据末端特性精确计量调节，减少供能损失。一体化智慧平台建设综合平衡了系统技术性，经济性综合能源打造先进的服务控制平台，同时，综合能源项目采用多能互补方式供热，按照站内功能与站外热网结合功能，采用优先余热，利用高效电制冷调风的模式。项目采用智慧控制设计，一体化运行打造综合能源智慧控制平台和综合服务平台，项目布置紧凑，节约占地运行技术指标先进，环保效益突出。旨在实现“源-网-荷-储”泛在电力物联感知:通过用户侧泛在电力物联感知技术及综合能源管理平台的应用，实现供能、用能各个环节的实时监测和智能监控，构建具有能动性、智能化的工具。响应节能降耗“双控”号召，实现基地高能效运行。瑞海热控致力于建设用户侧泛在电力物联和智慧能源管理、综合能源服务展示平台，打造“智慧、绿色、低碳、高效”综合能源示范服务。</p>
           </van-col>
           <van-col span="10"><img :src='img5'></van-col>
        </van-row>
    </div>
</template>
<script>
import Subroute from '@/common/Subroute3.vue'
import Primary from '@/common/primary.vue'
import Secondary from '@/common/secondary.vue'
import Pho from '@/common/pho.vue'
import Print from '@/common/print.vue'
export default {
    components:{
       Subroute,
       Primary,
       Secondary,
       Pho,
       Print
    },
    data() {
        return {
            Title:'解决方案',
            title:'医院综合热能特点',
            title1:'瑞海医院综合能源应用解决方案',
            title2:'冷暖一体化系统',
            title3:'智慧运维一体化服务系统',
            title4:'分布式能源（EPC模式）',
            img1:require('@/assets/css/医院/3.jpg'),
            img2:require('@/assets/css/医院/2.jpg'),
            img3:require('@/assets/css/医院/1.png'),
            img4:require('@/assets/css/医院/4.jpg'),
            img5:require('@/assets/css/医院/hechu.png'),
            text6:'瑞海热控提供的夏季供冷，冬季采暖方案，以电采暖为核心的多元化能源服务，为客户提供涵盖规划设计，投资建设运维一体化的冷暖供应等新型业务节能工作。节能工作也是完善医疗体制改革的重要保障，加强医院用能管理，提高能源的利用效率，帮助建设节能型医院，具有重要的社会经济意义。整体采取平台+智能运维+生态合作伙伴运行模式。',
            text8:'制冷系统方面选用水冷离心机作为制冷主机，主机采用变频结合调节负荷自动根据工况调节负荷输出，减少功耗，电功率达到最佳能效。供暖系统方面采用低谷蓄能技术，充分利用低谷时段蓄热水箱，低温抽水加热至95摄氏度，供热时采用热换机，将95℃的热水交换50℃至55℃的热水送入医院风机盘管系统。热水系统方面采用太阳能结合电加热蓄能系统，日照良好利用太阳能，太阳能不足，或者没有利用低谷时段对蓄热电热水炉加热60℃，采用恒压方式供应生活用水。瑞海热控医院综合供暖方案集能源规划咨询设计、投资建设、运营维护、信息化服务及技术支撑为一体建设一体化能源站。为医院提供冷暖供应服务，建立用能监测和安全运行保障体系，搭建一站式服务平台，对医院用能实时监控，全面管理基于数据分析结果，有效调整并开展节能工作，通过平台可视化手段验证节能效果。',
            text11:'瑞海热控提供的医院智能运维一体化服务系统建立安全监控体系，打通医院分散系统的信息孤岛，实施集中管控各系统设备关键点位，提升安全生产风险管控方面的综合预防能力。以bim模型为载体，打通建筑及其配套系统的设计，施工，运维等各阶段数据实现建筑及设备系统运行故障，报警精准，定位追踪，提升后勤服务保障响应效率，建设医院运维全生命周期数据体系。后勤一站式服务，后勤业务信息系统功能实现了后勤业务高效透明，可追溯的闭环管理，提高了整个运维管理的效率水平和操作流程的规范程度，能好精细化管控，实现了实时分类分项采集，各方面能耗数据实现楼栋、楼层、科室能耗数据三级监控，在数据采集及存储的基础上，一全自动抄表方式解决人工抄表效率低等问题。通过数据模型深度节能分析优化重点能设备的启停及关键运行参数等措施为抓手，合理规划医院用能优化重点用能设备运行效率，实现医院好高效，精细化管理，满足医院节能降耗的管理要求。',
        }
    },
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            background-color: #f56a00;
            color: #fff;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
        }
   }
  

    .img img{
        width: 100%;
    }
    .el-row{
  // text-align: center;
    width: 1200Px;
    margin: 50Px auto;
    display:flex;
    img{
        width: 100%;
    }
    .t{
      margin-top: 22%;
    }
    p{
      font-size: 16Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;
    }
     span {
         margin-left: 70Px;
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
  
}
.top{
    margin-top: 40Px;
  }
 b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
    }
.bc{
  background-color: #eff0f2;
  padding: 40Px 0;
  
}
.van-row{
  width: 1200Px;
  margin: 0 auto;
  img{
    width: 100%;
  }
  p{
    margin-top: 15%;
  }
  .i{
    margin-top: 0;
  }
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>