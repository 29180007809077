// 政府机关
<template>
    <div>
        <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt=""><div class="repet"><div class="text">瑞海热控致力于为用户提供节能环保和降低用户取暖价格的新型技术开发和利用。目前，可将空气源热泵、蓄热式发热电缆、低氮全预混燃气锅炉等清洁供热方式，根据用户实际使用环境和配套条件将以上清洁供热方式进行单独或混合配套，一站式从设计、施工、调试、质检、运行、维护、能源端满足用户差异化需求。</div></div>
        </div>
        <Primary :title="Title"></Primary>
        <Subroute></Subroute>
        <div class="bc">
            <Secondary :title='title'></Secondary>
            <van-row type="flex"  justify="space-between">
                <van-col span="10">
                    <div class="main">
                <!-- <span>01</span> -->
        <p>
          政府机关其安全、正常、稳定运行至关重要，并与政府的服务质量、服务水平息息相关。为积极响应国家“资源节约型和环境友好型社会”的政策导向，根据国务院批准的《节能中长期专项规划》和《国务院关于加强节能工作的决定》等有关文件精神。政府机关供暖的安全性、稳定性、可靠性得到了提升，运维人员的工作负荷大大减轻，节约了运维人力成本和设备维护成本。政府办公楼的舒适性也有所提升，政府机关办公人员的工作效率和服务质量得到了保障。
        </p>
            </div>
                </van-col>
                <van-col span="10">
                    <img :src="img4" alt="">
                </van-col>
            </van-row>
            
    </div>
        <Secondary :title='title2'></Secondary>
        <van-row type="flex" justify="space-between">
      <van-col span="10"><img :src='img1'></van-col>
           <van-col span="10">
               <p class="tc">瑞海热控依托于智慧能效云平台系统的智能管理，空调系统的安全性、稳定性、可靠性得到了提升，运维人员的工作负荷大大减轻，节约了运维人力成本和设备维护成本。此外，政府办公楼的舒适性也有所提升，政府机关办公人员的工作效率和服务质量得到了保障。</p>
           </van-col>
           
        </van-row>
        <Secondary :title='title3'></Secondary>
        <van-row type="flex" justify="space-between">
      
           <van-col span="10">
               <p class="cen">瑞海热控通过对传统中心中央空调系统进行优化改造，达到最佳节能效果，项目中增加了中央空调节能优化控制系统软件、中央空调节能优化控制柜、冷冻水泵智能控制柜、冷却水泵智能控制柜等节能硬件设备及软件系统，通过中央空调节能控制系统软件实现对系统设备性能进行实时优化模拟计算，并实现中央空调系统整体性能动态寻优，获得在最优工况下，中央空调系统最低能耗时各设备的最优运行控制参数，实现系统层的节能优化控制，确保满足供冷负荷要求前提下，使中央空调系统运行的整体能耗最低。此外瑞海热控自主研发的软件可通过对项目的能耗调研、监控分析等，提出传统中央空调冷站系统节能改造与优化控制方案，并给出完善的项目施工方案与售后服务计划。</p>
           </van-col>
           <van-col span="10"><img :src='img2'></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
      <van-col span="10"><img :src='img3'></van-col>
           <van-col span="10">
               <p class="cen">中央空调系统设备间的运行是相互耦合且彼此影响联系的，同一个冷负荷需求，系统可以有很多种不同的运行方式来满足。瑞海热控设计的优化节能控制系统为基于系统行为的全局优化控制，是以能耗模型为基础的整个中央空调系统多维、主动寻优的节能控制系统。系统是建立在冷冻机房的每个设备及风系统整体性能特性的基础上，通过多维寻优的方法寻找满足工艺设计及冷量需求下整个冷冻机房及风系统的最佳能效点，从而实现最佳节能目标。在瑞海热控设计的新型节能控制系统的节能优化控制下，项目中央空调系统整体用电量可明显下降，进计算全年中央空调系统综合节能率将近20%。</p>
           </van-col>
           
        </van-row>
    </div>
</template>
<script>
import Subroute from '@/common/Subroute3.vue'
import Primary from '@/common/primary.vue'
import Secondary from '@/common/secondary.vue'
export default {
    components:{
       Subroute,
       Primary,
       Secondary,
    },
    data() {
        return {
            Title:'解决方案',
            title:'政府机关楼宇智能化管理特点',
            title2:'智慧能效云平台系统',
            title3:'中央空调系统',
            img1:require('@/assets/css/政府机关/1.png'),
            img2:require('@/assets/css/政府机关/2.png'),
            img3:require('@/assets/css/政府机关/3.jpg'),
            img4:require('@/assets/css/政府机关/4.jpg'),
            text2:'瑞海热控依托于智慧能效云平台系统的智能管理，空调系统的安全性、稳定性、可靠性得到了提升，运维人员的工作负荷大大减轻，节约了运维人力成本和设备维护成本。此外，政府办公楼的舒适性也有所提升，政府机关办公人员的工作效率和服务质量得到了保障。',
            text3:'瑞海热控通过对传统中心中央空调系统进行优化改造，为达到最佳节能效果，项目中增加了中央空调节能优化控制系统软件、中央空调节能优化控制柜、冷冻水泵智能控制柜、冷却水泵智能控制柜等节能硬件设备及软件系统，通过中央空调节能控制系统软件实现对系统设备性能进行实时优化模拟计算，并实现中央空调系统整体性能动态寻优，获得在最优工况下，中央空调系统最低能耗时各设备的最优运行控制参数，实现系统层的节能优化控制，确保满足供冷负荷要求前提下，使中央空调系统运行的整体能耗最低。此外瑞海热控自主研发的软件可通过对项目的能耗调研、监控分析等，提出传统中央空调冷站系统节能改造与优化控制方案，并给出完善的项目施工方案与售后服务计划。',
            text4:'中央空调系统设备间的运行是相互耦合且彼此影响联系的，同一个冷负荷需求，系统可以有很多种不同的运行方式来满足。瑞海热控设计的优化节能控制系统为基于系统行为的全局优化控制，是以能耗模型为基础的整个中央空调系统多维、主动寻优的节能控制系统。系统是建立在冷冻机房的每个设备及风系统整体性能特性的基础上，通过多维寻优的方法寻找满足工艺设计及冷量需求下整个冷冻机房及风系统的最佳能效点，从而实现最佳节能目标。在瑞海热控设计的新型节能控制系统的节能优化控制下，项目中央空调系统整体用电量可明显下降，进计算全年中央空调系统综合节能率将近20%。'
        }
    },
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            background-color: #f56a00;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
            color: #fff;
        }
   }
  
    .img img{
        width: 100%;
    }
     .el-row{
  text-align: center;
    width: 1200Px;
    margin: 50Px auto;
    display:flex;
    img{
        width: 100%;
    }
    p{
      font-size: 16Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;

    }
     span {
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
  
}
.top{
    margin-top: 40Px;
  }
 b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
    }
.bc{
    width: 100%;
  background-color: #eff0f2;
  padding: 40Px 0;
      margin: 0 auto;
      p{
        //   margin-top: -30Px;
        margin-top: 50Px;
          line-height: 24Px;
      }
      
}
.van-row{
    width: 1200Px;
    margin: 0 auto;
    .van-col{
        margin-top: 20PX;
        img{
        width: 100%;
        
    }
    }
    .tc{
        margin-top: 20%;
       
    }
    .cen{
        margin-top: 10%;
        
    }
  
    
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>