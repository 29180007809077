// 图片
<template>
    <div class="main">
        <div class="img" v-lazy-container="{ selector: 'img' }">
            <img :data-src="IMG" alt="">
        </div>
    </div>
</template>
<script>
export default {
  props: {
    IMG: {
      type: String,
    },
  },
};
</script>
<style lang="less" scoped>
img {
	image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode:nearest-neighbor;
}


.main{
    margin-top: 30Px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
img{
  margin: 0 auto;
  width: 1200Px;
}
</style>