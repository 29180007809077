// 水路供暖
<template>
  <div class="container">
    <div class="img">
      <img :src="require('@/assets/css/banner/zhikong.png')" alt="">
            <div class="reph">
                <div class="text">
                    瑞海热控结合十余年的供热开发、设计、运营经验与技术积累，自主研发形成了高集成度的一体化智慧供热管理系统。可对蓄热式发热电缆、空气源热泵、燃气锅炉等多种分布式热源模式进行融合管理，以满足不同应用场景对供热差异化、精细化、阶段式需求。
                </div>
            </div>
    </div>

    <Primary :title="title1"></Primary>
    <Subroute4></Subroute4>
    <div class="bc">
      <Secondary :title="title3"></Secondary>
      <Print :text="text3"></Print>
      <div class="v" v-lazy-container="{ selector: 'img' }">
        <img :data-src="img3" alt="">
      <p class="po">热源设备自适化运行，主要参考温度控制模块反馈数据，综合分析建筑场景内外部影响因素后，实时调整热源设备的运行条件，通过对供回水温度进行精细把控，动态控制楼宇内相对温度的。</p>
      </div>
      
    </div>
    <Pho :IMG="img6"></Pho>
    <Print :text="text4"></Print> 
    <div>
      <Print :text="text3"></Print>
      <Pho :IMG="img4"></Pho>
      <Print :text="text14"></Print>
      <!-- <van-row  type="flex" justify="space-between">
        <van-col span='10'>
          <p>循环水泵变频节能技术，主要是通过频率的变化调整水泵转速来改变系统循环水量。通过改变电动机定子电源的频率，最终达到改变其同步转速的目的。该技术克服了传统供热由于循环动力设备系统适配不当而造成的大量能源浪费同时，显著提升了整体水路管网热循环质量。</p>
        </van-col>
        <van-col span='12'>
          <img :src="img4" alt="">
        </van-col>
      </van-row> -->
    
    </div>
    <div class="pin">
      <img :src="img5" alt="">
      <div class="h">
        <h6>水力平衡调节</h6>
        <span></span>
        <p>通过提前布设在水路管网关键节点的动态水力平衡设备（流量调节器、压差调节器）进行调节。通过改变动态水力平衡各节点阀门开度，可使得水网整体趋于动态平衡。区域协同、整体联动的灵活调控方式不但规避了用户间冷热分布不均，也使得供热管理向精细化、节能化更进一步。</p>
      </div>
    </div>
  </div>
</template>
<script>
import Primary from "@/common/primary.vue";
import Subroute4 from "@/common/Subroute4.vue";
import Secondary from "@/common/secondary.vue";
import Print from "@/common/print.vue";
import Pho from "@/common/pho.vue";
export default {
  components: {
    Primary,
    Subroute4,
    Secondary,
    Print,
    Pho,
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.deviceType = "mobile";
    } else {
      this.deviceType = "pc";
    }
  },
  data() {
    return {
      deviceType: "",
      // videoUrl: require("@/assets/zhikong.mp4"),
      title1: "瑞海智慧供热管理系统",
      text1:
        "瑞海热控结合十余年的供热开发、设计、运营经验与技术积累，自主研发形成了高集成度的一体化智慧供热管理系统。可对蓄热式发热电缆、空气源热泵、燃气锅炉等多种分布式热源模式进行融合管理，以满足不同应用场景对供热差异化、精细化、阶段式需求。",
      title3: "水路供暖智控系统（空气源热泵、燃气锅炉）",
      text3:
        "RH水路供暖智控系统在实际供热运行过程中，通过对信息采集系统传回数据进行研判，水路供暖智控系统将通过大数据进行模拟演算，得出热网各关键节点最优运行方案，并下达指令对热源设备功率、循环水泵速率、动态水力平衡设备阀门开度统筹调节，保障了热网在平衡基础上的节能运行。",
      img3: require("@/assets/css/智慧能源云平台/circuit.png"),
      // text4:
      //   "热源设备自适化运行，主要参考温度控制模块反馈数据，综合分析建筑场景内外部影响因素后，实时调整热源设备的运行条件，通过对供回水温度进行精细把控，动态控制楼宇内相对温度的。",
      img4: require("@/assets/css/智慧能源云平台/beng.png"),
      text5:
        "循环水泵变频节能技术，主要是通过频率的变化调整水泵转速来改变系统循环水量。通过改变电动机定子电源的频率，最终达到改变其同步转速的目的。该技术克服了传统供热由于循环动力设备系统适配不当而造成的大量能源浪费同时，显著提升了整体水路管网热循环质量。",
      img5: require("@/assets/css/智慧能源云平台/reb.png"),
       img6: require("@/assets/css/智慧能源云平台/shui.png"),
       text14:'循环水泵变频节能技术，主要是通过频率的变化调整水泵转速来改变系统循环水量。通过改变电动机定子电源的频率，最终达到改变其同步转速的目的。该技术克服了传统供热由于循环动力设备系统适配不当而造成的大量能源浪费同时，显著提升了整体水路管网热循环质量。'
    };
  },
};
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        // float: left;

        vertical-align: middle;
    }
    .reph{
         position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        left: 100Px;
        bottom: 0;
       .text{
           box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            // height: 230Px;
            background-color: #f56a00;
            font-size: 22Px;
            line-height: 38Px;
            color: #fff;
            padding: 40Px;
       }
    }
}
.pin{
  width: 900Px;
  margin: 50Px auto;
  position: relative;
  height: 350Px;
  img{
    width: 450Px;
    position:relative;
    z-index: 998;
    display: block;
    // Box-shadow: 10px 10px 10px #eaeaea;
    border: 6Px solid #eaeaea;
  }
  .h{
    top: 50Px;
    background-color: #4f4e4e;
    position: absolute;
    width: 700Px;
    color: #fff;
    right: 0;
    padding: 40Px 40Px 40Px 300Px;
    box-sizing: border-box;
    h6{
      font-size: 24Px;
      margin: 0;
      display: inline-block;
      padding: 20Px 0;
      border-bottom: 2Px solid rgb(239, 239, 239);
      font-weight: normal;
    }
    p{
      font-size: 16Px;
      line-height: 24Px;
      margin-top: 20Px;
    }
  }
}

video {
  top: 0;
  display: block;
  margin: auto;
  max-width: 100%;
}
video {
  pointer-events: none;
}
video::-webkit-media-controls-enclosure {
  display: none;
}
img video::-webkit-media-controls-start-playback-button {
  display: none;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.van-col .i {
  font-size: 14px;
}
.ce {
  width: 800px;
  margin: 40px auto;
  display: block;
}
h4 {
  width: 1200px;
  margin: 0 auto;
  height: 60px;
  text-align: center;
  color: #343434;
  font-size: 18px;
  font-weight: normal;
  border-bottom: 3px solid #c8c9cb;
}
h5 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  color: #343434;
  font-size: 18px;
  font-weight: normal;
}
.van-col .e {
  font-size: 13px;
  margin-top: 25px;
}
.dashe {
  width: 1200px;
  margin: 40px auto;
  box-sizing: border-box;
  border: 2px dashed #c8c9cb;
}
.van-row {
  width: 1200Px;
  padding: 30Px 0;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
.img img {
  width: 100%;
}
.van-col img {
  width: 100%;
}
.IMG {
  padding: 30px 0;
  background-color: #eff0f2;
  margin: 0 auto;
  width: 1200px;
}
.IMG p {
  color: #343434;
  font-size: 16px;
  text-align: center;
}
.IMG img {
  width: 1200px;
}
.bc {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30Px;
  background-color: #eff0f2;
  padding: 20px 0;
  box-sizing: border-box;
  .v{
    width: 1200Px;
    margin: 0 auto;
    img{
    float: left;
    width: 1200Px;
    margin: 20Px auto;
    display: block;
  }
  p{
    float: left;
    margin-top: -100Px;
    margin-left: 40Px;
    background-color: rgba(0,0,0,.1);
    padding: 10Px;
    box-sizing: border-box;
    width: 800Px;
    
  }
  }
  
}
.van-col span {
  font-weight: 800;
  font-size: 50px;
  color: #c8c9cb;
}
.van-col b {
  margin-left: 10px;
  font-size: 22px;
  color: #343434;
  font-weight: normal;
}
.van-col p {
  font-size: 16Px;
  color: #000000;
  text-align-last: left;
  text-align: justify;
  margin-top: 25%;
}
.Top {
  margin-bottom: 40px;
}
.T {
  margin-top: 20px;
}
.B {
  margin-top: 20px;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>