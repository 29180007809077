// 电伴热
<template>
    <div class="container">
        <div class="img">
           <img :src="require('@/assets/css/banner/zonghepng.png')" alt="" class="ditu"><div class="div"><div class="box">综合能源管理<br><i>INTEGRATED ENERGY MANAGEMENT</i></div><div class="p">光伏 微电网 能源站 余热回收</div></div>
        </div>
        <Primary :title='title1'></Primary>
        <Main :text='text1'></Main>
        <!-- 导航切换 -->
        <Subroute2></Subroute2>
        <!-- 智能系统 -->
        <Scene></Scene>
        <!-- 电伴热智控 -->
        <Gls :Title="Title1" :IMG="IMG1"></Gls>
        <Print :text='text2'></Print>
        <Secondary :title="title2"></Secondary>
        <van-row gutter="20" v-lazy-container="{ selector: 'img' }">
          <van-col span="8"><img :data-src='require("@/assets/css/dianbanre/san.png")'><p>液体运输管道，需要管道温度恒定保证液体的流动性。输送管道距离长，所处环境复杂，容易受热不均。瑞海热控的管道伴热带设定液体流流动所需的温度为额定温度，可以及时补充流体损失的热量，使得温度保持在允许范围内。可以折叠交叉的特性，更加安全可靠、发热均匀。节能省钱，伴热花费仅是蒸汽伴热的一半。</p></van-col>
          <van-col span="8"><img :data-src='require("@/assets/css/dianbanre/yi.png")'><p>市政消防是人身和财产安全保障的重中之重，在遇到火警时必须保证消防管道供水通畅。而因为其使用不频繁，所以需要在一款低功耗情况下还能保证管道供水在极端环境下顺畅的伴热网络。瑞海热控的管道伴热带设定智能启动温度，配合物联网集中控制技术，根据管道的温度，进行局部的，区域的补温。实现长时间低能耗智能伴热。</p></van-col>
          <van-col span="8"><img :data-src='require("@/assets/css/dianbanre/er.png")'><p>建筑和天沟融雪需要伴热带耐腐蚀、升温快。瑞海热控的融雪伴热带使用PTC发热材料，耐腐蚀、升温迅速。特殊的排线工艺，可以给室外狭窄的复杂的地形伴热，铺设轻松，施工方便和维护成本低。结合瑞海热控的温度和天气传感器，根据预设气温、湿度、降雪等关键参数实现自动运行、节能降耗的目的。</p></van-col>
        </van-row>
        <h2></h2>
        <van-row gutter="20">
          <van-col span="4"><img :src='require("@/assets/css/dianbanre/隧道地铁.png")'><i>隧道地铁</i></van-col>
          <van-col span="4"><img :src='require("@/assets/css/dianbanre/tiangou.png")'><i>天沟融雪</i></van-col>
          <van-col span="4"><img :src='require("@/assets/css/dianbanre/石油化工.png")'><i>石油化工</i></van-col>
          <van-col span="4"><img :src='require("@/assets/css/dianbanre/消防管道.png")'><i>消防管道</i></van-col>
          <van-col span="4"><img :src='require("@/assets/css/dianbanre/shuiguan.png")'><i>水管防冻</i></van-col>
          <van-col span="4"><img :src='require("@/assets/css/dianbanre/tuoliu.png")'><i>电厂脱硫</i></van-col>
        </van-row>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Main from '@/common/main.vue'
import Subroute2 from '@/common/Subroute2.vue'
import Gls from '../common/gls.vue'
import Print from '../common/print.vue'
import Secondary from '../common/secondary.vue'
import Scene from '../common/scene.vue'
export default {
    components:{
        Primary,
        Main,
        Subroute2,
        Gls,
        Print,
        Secondary,
        Scene,
    },
    methods:{
_isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
   },
   mounted() {
        if (this._isMobile()) {
            this.deviceType = 'mobile'
        } else {
            this.deviceType = 'pc'
        }
    },
    data() {
        return {
          deviceType: '',
            // videoUrl:require('@/assets/nengyuan.mp4'),
            title1:'新能源综合应用',
            text1:'随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。',
            Title1:'电伴热智控',
            IMG1:require('@/assets/css/dianbanre/1.jpg'),
            text2:'瑞海热控的电伴热系统有温度梯度小、热稳定时间较长、无污染、使用寿命长等的特点，广泛应用于工业伴热知石油化工、电厂脱硫、隧道地铁、罐体管道、船舶、仪衷等和商业伴煦加消防管谶天沟胜霆、水管防冻、道路融雪、混凝土发热。、热水管保养等。瑞海热控的电伴热合作伙伴拥有着领先的技术和多项专利，在电伴热温度控制、绝缘保护、防爆保护等方面一直位于市场的最上游。结合合作伙伴技术上的优势，瑞海热控在电伴热的督能控制上有了新的突破。瑞海热控的自限温伴热技术能够根据需求，自动限制伴热带的温度，并随被加热体的温度自动调节输出功率。瑞海热控的温度、湿度、气压等传感器能自动检测环境特征，自动判斯并实施最适合受热体的加热计划，满足受热体升温、保温、防冻等各种需求。瑞海热控的大数据天气预测系统还可以对极寒，暴雪等极端天气有自动预警功能。',
            title2:'经典应用场景',
        }
    },
}
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin:auto;
    overflow: hidden;
    position: relative;
    .ditu{
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
    
    .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-size: 26Px;
            font-style: normal;
        }
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 99;
   }
    }
}
.G{
    width: 100%;
    padding: 0;
    background-color: rgba(240,240,240);
}
img{
  width: 100%;
  margin-left: -1PX;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.subroute{
    margin-top: 100px;
}
.van-row{
    width: 1200Px;
    margin: 0 auto;
    text-align: center;
}
.van-row p{
    text-align-last: left; 
    font-size: 14Px;
    text-align: justify;
}
.van-col i{
    text-align: center;
    font-style: normal;
    font-size: 16Px;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>