// 二级标题+图片
<template>
    <div class="main">
        <div class="title">
            <h2>{{Title}}</h2>
        </div>
        <div class="img" v-lazy-container="{ selector: 'img' }">
            <img :data-src="IMG" alt="">
        </div>
    </div>
</template>
<script>
export default {
  props: {
    Title: {
      type: String,
    },
    IMG: {
      type: String
    }
  }
}
</script>
<style lang="less" scoped>
.main{
    text-align: center;
    margin-bottom: 40Px;
}
img{
  margin: 0 auto;
  width: 1200Px;
}
h2{
  font-size: 28Px;
  font-weight: normal;
}

</style>