<template>
  <div class="subroute">
    <van-row gutter="24"  type="flex" justify="space-between">
      <van-col span="5">
      <router-link to="/photovoltaic-array?active=one"  @click.native="doSomething('one')"  title="光伏"  :class="active==='one'?classActive:classUnActive">光伏</router-link>
      </van-col>
      <van-col span="5">
      <router-link to="/electric-tracing?active=two" @click.native="doSomething('two')" title="电伴热"  :class="active==='two'?classActive:classUnActive">电伴热</router-link>
      </van-col>
      <van-col span="5">
      <router-link to="/carbon-energy?active=three" @click.native="doSomething('three')"  title='碳能管理'  :class="active==='three'?classActive:classUnActive">碳能管理</router-link>
      </van-col>
      <van-col span="5">
      <router-link to="/energy-port?active=four" @click.native="doSomething('four')"  title='能源港'  :class="active==='four'?classActive:classUnActive">能源港</router-link>
      </van-col>
      </van-row>      
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
 
  data(){
    return {
      active:this.$route.query.active,
      classActive:"classActive",
      classUnActive:"classUnActive"
    }
  },
  created(){

  },
 
  methods: {
    doSomething(st){
      this.active=st
    }
  },
}
</script>
<style lang="less" scoped>
.van-row{
  width: 1200Px;
  margin: 60px auto;
}
 a {
  color: #f56a00;
  background: #ffffff;
  border: 1px solid #f56a00;
  display: block;
  width: 100%;
  height: 98Px;
  line-height: 98Px;
  font-size: 22Px;
  text-align: center;
  border-radius: 10Px;
}
 a.router-link-exact-active{
	background-color: #f56a00;
	color: #fff;
}
.classActive:hover{
     background-color: #f56a00c2;
    color: #fff;
}
.classUnActive:hover{
    background-color: #f56a00;
    color: #fff;
}
</style>