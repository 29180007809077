// 新闻资讯
<template>
    <div>
        <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt="">
            <!-- <div class="repet"><div class="text">随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。</div></div> -->
        </div>
        <Primary :title="Title"></Primary>
        <Secondary :title="title1"></Secondary>
        <Abs></Abs>
        <Secondary :title="title2"></Secondary>
        <News :ltopic='ltopic1' :rtopic='rtopic1' :ltime='ltime1' :rtime='rtime1' :lsection='lsection1' :rsection='rsection1' :lskip='lskip1' :rskip='rskip1'></News>
        <News :ltopic='ltopic2' :rtopic='rtopic2' :ltime='ltime2' :rtime='rtime2' :lsection='lsection2' :rsection='rsection2' :lskip='lskip2' :rskip='rskip2'></News>
        <New :ltopic='ltopic3' :ltime='ltime3'  :lsection='lsection3' :lskip='lskip3' :rskip='rskip3'></New>
        <Secondary :title="title3"></Secondary>
        <News :ltopic='ltopic4' :rtopic='rtopic4' :ltime='ltime4' :rtime='rtime4' :lsection='lsection4' :rsection='rsection4' :lskip='lskip4' :rskip='rskip4'></News>
        <News :ltopic='ltopic5' :rtopic='rtopic5' :ltime='ltime5' :rtime='rtime5' :lsection='lsection5' :rsection='rsection5' :lskip='lskip5' :rskip='rskip5'></News>
        <New :ltopic='ltopic6' :ltime='ltime6' :lsection='lsection6' :lskip='lskip6' :rskip='rskip6'></New>
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Secondary from '@/common/secondary.vue'
import News from '@/common/news.vue'
import New from '@/common/new.vue'
import Abs from '@/common/abs.vue'
export default {
    components:{
       Primary,
      Secondary,
      News,
      Abs,
      New
    },
    data() {
        return {
            Title:'资讯中心',
            title1:'瑞海新闻',
            title2:'行业热点',
            title3:'地铁专题项目',
            lskip1:'https://mp.weixin.qq.com/s/y1W5T9rmZp5uUlGc_8eEvQ',
            rskip1:'https://mp.weixin.qq.com/s/V9BWBzxWf8Xa-I7fU2Z5jQ',
            ltopic1:'太原某商城专门店被淹，对传统供暖弊端...',
            rtopic1:'干货!中国供暖行业发展环境分析!',
            ltime1:'2020-12-27',
            rtime1:'2020-12-27',
            lsection1:'昨日下午，太原万象城某专卖店中的暖气管发生爆裂，服装店转眼成了水帘洞。如视频中所见。大部分商品被浸泡，给商家及顾客带来的损失可想而知。随着冬季供暖季的到来，各家各户在享受集中供暖带来的温暖同时，却也有一些“温暖”的烦恼。由于传统水暖管线老.....',
            rsection1:'集中供暖的出现使得北方寒冷的冬季，室内家居生活变得温暖舒适。然而传统的供暖原理以“烧开水”的方式进行集中供暖，势必会造成过度取暖和能源浪费。此外，集中供暖还存在一次性投资大，运行费用高，无论是否需要，暖气始终全天供热，同时存在楼宇间供热不...',
            lskip2:'https://mp.weixin.qq.com/s/ojYUYdehVoAtV2lix6k02g',
            rskip2:'https://mp.weixin.qq.com/s/KuufvbnZKWhi7J-F1YEcjA',
            ltopic2:'蓝天保卫战下-电采暖的低碳优势',
            rtopic2:'供暖大势所趋——天然气热电联产',
            ltime2:'2021-12-27',
            rtime2:'2020-12-25',
            lsection2:'今年入冬以来北方各地的雾霾天数较往年相比大幅度减少，这与北方很多地区开展蓝天保卫战行动有着密切的联系。随着“媒改电”政策的进一步推行，较传统集中供暖有着清洁环保绿色等巨大优势的电采暖逐渐走入人们的视野。本文将为您介绍电采暖较燃煤集中供暖的...',
            rsection2:'我国热电联产项目最早出现在一些北方城市，当时主要集中在工业领域，后发展到北方居民集中供暖。如今，天然气热电联产是目前能源综合利用效率最高且环保低碳的—种能源利用方式，但也存在一些行业发展的问题。',
            ltopic3:'全网首发!可交互太原市供暖区域地图!',
            ltime3:'2020-12-27',
            lsection3:'我们面临着一个更重要的问题—你知道自己家属于哪个热力公司供热吗?没人喜欢看—张又一张的复杂地图。同样，也没人喜欢看完一段又一段的文字以后还要去眼花缭乱的地图上寻找自己的地址。这对大多数人来说都是无聊的事情。别担心，瑞海已经为您精心制作了一个....',
            lskip3:'https://mp.weixin.qq.com/s/mq79NdsdIRNIxyM-C7IDTw',
            rskip3:'https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MDk5MDc2Mg==&hid=11&sn=b66b896e5659ec439a69e81a59b1102b&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6302017f&lang=zh_CN&ascene=7&fontgear=2',
            ltopic4:'最全太原地铁2号线票价站点信息大全',
            rtopic4:'在太原坐地铁能“换钱”?',
            ltime4:'2020-12-28',
            rtime4:'2020-12-28',
            lsection4:'12月26日，太原地铁2号线正式对市民开通。太原地铁时代正式到来，这里小编为大家整理了太原地铁2号线票价、站点、票价图、购票图、路线图等相关内容。',
            rsection4:'2020年9月北京市率先推出市民绿色出行后，可通过高德、百度等平台进行碳交易“变现”，获得—卡通充值码等奖品，实现了覆盖公交、地铁、自行车、步行全绿色低碳出行碳普惠模式。太原地铁建成后也将推行此类模式，那么究竟什么是“碳普惠”呢，这种模式...',
            lskip4:'https://mp.weixin.qq.com/s/LbIz8qV7wDTnwg35pxopRA',
            rskip4:'https://mp.weixin.qq.com/s/HZRs1lVY3e9LBFzQnx3I6w',
            ltopic5:'原来这就是“超级城市”背后的秘密',
            rtopic5:'太原地铁，究竟能给我们带来什么?',
            ltime5:'2020-12-28',
            rtime5:'2020-12-28',
            lsection5:'上个班996，一天12个小时就奉献给事业了，你以为就完事了吗?通勤路上再消磨掉2个小时，还能有啥闲情逸致?此时，我们只想说...根据国家统计局北京调查总队的最新数据，2018年北京市15-39岁青年人群的通勤时间更长，达到了112分钟。起源于美国，精进于日本...',
            rsection5:'1863年，英国伦敦建成世界上第—条地铁;1965年，中国的第一条地铁在北京正式通车;截至2020年，中国各城市建设的地铁总里程约5000km，位居世界第一。纵观城市发展和轨道交通建设的历程，地铁建设将为城市带来源源不断地生命力，而TOD模式是轨道交通和城...',
            lskip5:'https://mp.weixin.qq.com/s/CIyI-7H27PypuAc2xKGRPA',
            rskip5:'https://mp.weixin.qq.com/s/zcVtyPO8OgCjW4WsXm60vg',
            ltopic6:'太原市轨道交通2号线—期工程顺利通过竣工验收',
            ltime6:'2020-12-28',
            lsection6:'2020年12月6日，太原市轨道交通2号线一期工程顺利通过竣工验收，标志着工程建设完美收官,为初期运营获得了“通行证”。',
            lskip6:'https://mp.weixin.qq.com/s/JgXoDWqaaTd9GTAfovf7QA',
            rskip6:'https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MDk5MDc2Mg==&hid=13&sn=1dbdefb96a920eeddedd3f8b6d207fe6&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6302017f&lang=zh_CN&ascene=7&fontgear=2',
        }
    },
}
</script>
<style lang="less" scoped>
    .img img{
        width: 100%;
    }
    .pho{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            height: 230Px;
            background-color: #f56a00;
            color: #fff;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
        }
   }
   @media screen and (max-width:1900px) {
        .pho {
            width:100%;
        }
    }

</style>