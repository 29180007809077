<template>
  <div id="main"></div>
</template>
<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
export default {
  created() {
    // 注册必须的组件
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      BarChart,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
    ]);
  },
  mounted() {
    var myChart = echarts.init(document.getElementById("main"));
    let option = {
      title: {
        text: "室温优化，节约能源",
        left: "8%",
      },
      legend: {
        // top:'5%',
        data: ["使用前", "使用后"],
      },
      animationEasing: "elasticOut",
      tooltip: {},
      xAxis: {
        data: [
          "0:00",
          "0:15",
          "0:30",
          "0:45",
          "1:00",
          "1:15",
          "1:30",
          "1:45",
          "2:00",
          "2:15",
          "2:30",
          "2:45",
          "3:00",
          "3:15",
          "3:30",
          "3:45",
          "4:00",
          "4:15",
          "4:30",
          "4:45",
          "5:00",
          "5:15",
          "5:30",
          "5:45",
          "6:00",
          "6:15",
          "6:30",
          "6:45",
          "7:00",
          "7:15",
          "7:30",
          "7:45",
          "8:00",
          "8:15",
          "8:30",
          "8:45",
          "9:00",
          "10:15",
          "10:30",
          "10:45",
          "11:00",
          "11:15",
          "11:30",
          "11:45",
          "12:00",
          "12:15",
          "12:30",
          "12:45",
          "13:00",
          "13:15",
          "13:30",
          "13:45",
          "14:00",
          "14:15",
          "14:30",
          "14:45",
        ],
      },
      yAxis: {},
      series: [
        {
          name: "使用前",
          type: "bar",
          smooth: true,
          data: [
            17.64, 17.6, 17.83, 18.04, 19.44, 20.3, 22.11, 23.88, 18.6, 20.33,
            18.45, 20.06, 18.54, 18.45, 19.66, 18.11, 17.49, 17.4, 18.33, 18.37,
            19.02, 19.95, 20.57, 22, 25, 23.07, 21.85, 20.7, 20.15, 19.2, 18.88,
            18.09, 20.03, 17.62, 18.09, 17.85, 18.37, 19.2, 19.62, 20.83, 22.17,
            23.91, 22.58, 20.93, 20.05, 18.91, 18.1, 18.11, 20.49, 17.51, 17.67,
            18.43, 18.08, 20.35, 20.04, 20.7, 21.9, 23.02, 21.87, 20.71, 19.85,
          ],
          animationDelay: function (idx) {
        return idx * 10;
      }
        },
        {
          name: "使用后",
          type: "bar",
          data: [
            17.2, 17.46, 17.78, 18.44, 18.24, 19.39, 19.13, 17.51, 20.02, 19.72,
            19.1, 19.5, 18.67, 17.96, 17.61, 17.93, 17.08, 18.2, 18.07, 18.59,
            19.14, 19.34, 19.91, 19.67, 17.7, 20.04, 19.26, 19.28, 18.83, 18.81,
            17.9, 17.67, 17.07, 18.16, 18.15, 18.29, 18.89, 18.59, 18.72, 20.1,
            19.99, 20.38, 17.85, 19.01, 18.48, 18.05, 18.45, 17.56, 17.57,
            17.58, 20.01, 18.03, 18.33, 18.76, 19.36, 19.9, 20.52, 20.36, 19.58,
            19.34,
          ],
         animationDelay: function (idx) {
        return idx * 10 + 100;
      }
        },
        
      ],
  animationDelayUpdate: function (idx) {
    return idx * 5;
  }
    };
    myChart.setOption(option);
    
  },
};
</script>
<style lang="less" scoped>
#main {
  padding-top: 40Px;
  // width: 1400Px;
  width:600Px;
  height: 400Px;
  margin: 0 auto;
  box-sizing: border-box;
}
</style>