// 碳能管理
<template>
    <div class="container">
        <div class="img">
             <img :src= "require('@/assets/tan.jpg')">
        </div>
        <Primary :title='title1'></Primary>
        <Main :text='text1'></Main>
        <!-- 导航切换 -->
        <Subroute2></Subroute2>
        <!-- 碳能管理背景 -->
        <div class="bg">
            <Secondary :title="Title1"></Secondary>
        <van-row type="flex" justify="space-between">
           <van-col span="10">
               <p>瑞海热控碳能管理体系，主要着手于绿色制造相关评价方法、数据库、管理系统和公共服务平台的应用与服务，通过生命周期评价（LCA）系统、各类绿色评价系统、各类环保合规管理等系统应用与碳能管理专家团队技术支撑，可为客户量身定制碳能管理服务，是国内领先、拥有多元数据体系支撑的一站式解决方案提供商。 </p>
           </van-col>
           <van-col span="10"><img :src='IMG1'></van-col>
        </van-row>
        </div>
        
        
        <!-- 碳交易简介 -->
        <Secondary :title="title2"></Secondary>
        <div class="Pho" v-lazy-container="{ selector: 'img' }">
            <img :data-src='require("@/assets/css/碳能管理/2.png")'>
            <div class="L">
                <p>碳交易是为促进全球温室气体减排，减少全球二氧化碳排放所采用的市场机制。把市场机制作为解决二氧化碳为代表的温室气体减排问题的新路径，即把二氧化碳排放权作为一种商品，从而形成了二氧化碳排放权的交易。</p>
            </div>
            <div class="B">
                <p>基本原理是：合同的一方通过支付另一方获得温室气体减排额，买方可以将购得的减排额用于减缓温室效应从而实现其减排的目标。在6种被要求排减的温室气体中，二氧化碳（CO2）为最大宗，所以这种交易以每吨二氧化碳当量（tCO2e）为计算单位，通称为“碳交易”。其交易市场称为碳市场（Carbon Market）。瑞海热控作为传统领域向低碳环保转型发展的先行者，拥有多位碳资产管理专家，可为企业量身定制碳能管理服务。</p>
            </div>
        </div>
        <Secondary :title="title4"></Secondary>
        <Table></Table>
        <!-- <pho :IMG='require("@/assets/css/碳能管理/table.png")'></pho> -->
        <Secondary :title="title6" class=""></Secondary>
        <div class="contai">
            <span>01</span>
          <b>为行业提供的服务</b><p>
              <i>专用系统：</i>在瑞海碳能管理平台上可以为行业建立独立的低碳绿色发展平台或绿色制造评价专用系统，可支持协会标准、数据库建设、课题申报验收等工作，可定制新的功能模块、增强对政府工作任务的支撑能力。<br>
<i>合作推动：</i>结合行业工作需要，为绿色相关标准工作组、行业数据库建设、示范技术/示范产品/示范企业的推广等，提供 LCA、评价工具等方面的合作。
          </p>
          <span>02</span>
          <b>为企业提供的服务</b><p>
              <i>专用系统：</i>在瑞海碳能管理平台上可以为企业单独定制，建立独立的绿色制造评价专用系统，系统内置绿色工厂、绿色供应链、绿色设计产品等评价指标，以供企业在发展过程中持续创建与改进，有助于企业申报评价和评分。<br>
              <i>标杆示范：</i>瑞海热控可在省内或全国范围组织、宣传、推广该企业通过全生命周期低碳绿色评价的示范技术、示范产品、示范项目，打造低碳绿色标杆。<br>
              <i>节能改造，低碳收益：</i>瑞海可帮助企业在综合能源应用与暖通领域进行节能诊断并定制化节能改造方案，辅助以智能运维系统，可从源头降低企业整体碳排放量。在阶段内直接或间接产生的温室气体排放总量，可通过交易市场的形式抵消，完成政策要求。<br>
              <i>碳资产，新发掘：</i>针对符合国家低碳标准的企业，瑞海将着重开发企业碳资产，帮助企业发掘生产过程中的碳价值，进而形成效益，为企业谋取额外的长期碳收入，助力其在如今的碳市场中占据一席之地。<br>
              <i>各类项目申报：</i>帮助企业申请如工信部、财政部绿色制造服务商招标项目申报等。
          </p>
        </div>
        
    </div>
</template>
<script>
import Primary from '@/common/primary.vue'
import Main from '@/common/main.vue'
import Subroute2 from '@/common/Subroute2.vue'
import Secondary from '@/common/secondary.vue'
import Table from '@/common/table.vue'
// import Pho from '../common/pho.vue'
export default {
    components:{
        Primary,
        Main,
        Subroute2,
        Secondary,
        Table
        // Pho,
        // Graphic,
    },
    data() {
        return {
            deviceType: '',
            title1:'新能源综合应用',
            text1:'随着国家相关政策、项目的落地推行，新能源已经成为日后发展的必由之路。瑞海热控专注于新能源领域开发应用，结合自身优势，利用自主研发的智能云控制系统，赋能新能源领域。旨在深化传统能源领域改革，帮助合作客户提供节能减排的综合新能源利用方案。',
            Title1:'碳能管理背景介绍',
            IMG1:require('@/assets/css/碳能管理/1.png'),
            title2:'碳交易简介',
            title3:'瑞海碳能管理服务体系',
            title4:'服务概述',
            // text4:'作为全生命周期绿色管理专委会的会员单位，瑞海热控应用WebLCA 平台，为国内能源、建筑、金属、化工、运输、污染等领域上下游企业提供了全生命周期可持续生产的消费提供支持与技术服务，在生命周期评价和绿色制造领域具有广泛影响。',
            title5:'碳能管理技术支撑',
           title6:'客户服务类型 ',
            IMG2:require('@/assets/css/碳能管理/3.png'),
            // text4:'瑞海将着重开发企业碳资产，重拾企业碳价值，整合碳中和效益，为企业谋取额外的长期碳收入，助力其在未来碳市场中占据一席之地。',
            IMG3:require('@/assets/css/碳能管理/4.png'),
            IMG5:require('@/assets/css/碳能管理/table.png'),
            IMG6:require('@/assets/css/碳能管理/quan.png'),
            IMG7:require('@/assets/css/碳能管理/lv.png'),
        }
    },
}
</script>
<style lang="less" scoped>
.van-row{
    width: 1200Px;
    margin: 0 auto;
    // text-align: center;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 20Px;
}
.van-row p{
    margin-top: 70Px;
    text-align-last: left; 
    font-size: 16Px;
}
.van-row i{
    font-size: 22Px;
    padding-left: 10Px;
    border-left: 3Px solid #f56a00;
    font-style: normal;
    float: left;
    margin-top: 20Px;
}
.G{
    width: 100%;
    padding: 0;
    background-color: rgba(240,240,240);
}
.Pho{
    margin: 0 auto;
    width: 1200Px;
}
img{
    width: 100%;
    margin-left: -1PX;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.subroute{
    margin-top: 100px;
}
.bg{
    width: 100%;
    height: 100%;
    background-color: #eff0f2;
    padding: 20Px 0;
}
.Pho img{
    float: left;
}
.Pho .L{
    float: right;
    margin-right: 40Px;
    margin-top: -350Px;
    width: 300Px;
    padding: 10PX;
    background-color: rgba(0,0,0,.6);
}
.Pho .L p{
    color: rgb(230, 230, 230);
    font-size: 12Px;
    width: 300Px;
}
.Pho .B{
    width: 800Px;
    float: left;
    padding: 0 15PX;
    background-color: rgba(0,0,0,.6);
    margin-top: -100Px;
    margin-left: 80Px;
    margin-bottom: 50Px;
}
.Pho .B p{
     color: rgb(230, 230, 230);
     font-size: 12Px;
}
.contai{
    width: 1200Px;
    margin: 0 auto;
    span{
        font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
    }
    b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
i{
    font-style: normal;
    font-weight: 800;
    font-size: 16Px;
    color: rgba(0, 0, 0, 0.882);
    line-height: 30Px;
}
p{
    font-size: 16Px;
}
}
.img{
    margin:auto;
    width: 80%;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>