<template>
    <div>
      <div class="threeImg">
      <div class="iconleft" @click="zuohua">
      </div>
      <div class="Containt">
        <ul :style="{'left':calleft + 'px', width: widthData + 'px'} " v-on:mouseover="stopmove()" v-on:mouseout="move()" class="imgBoxoul">
          <li v-for="(item,index) in dataList" :key="index" @click="gotodetails(item.id)" ref="lis">
            <!-- <p class="item-title">{{item.title}}</p> -->
            <img :src="item.thumb" />
             <!-- <p class="item-title">{{item.title}}</p> -->
          </li>
        </ul>
      </div>
      <div class="iconright" @click="youhua">
      </div>
    </div>
    <div class="threeImg">
      <div class="iconleft" @click="zuohua">
      </div>
      <div class="Containt">
        <ul :style="{'left':calleft + 'px', width: widthData + 'px'} " v-on:mouseover="stopmove()" v-on:mouseout="move()" class="imgBoxoul">
          <li v-for="(item,index) in dataList" :key="index" @click="gotodetails(item.id)" ref="lis">
            <!-- <p class="item-title">{{item.title}}</p> -->
           
             <p class="item-title">{{item.title}}</p>
          </li>
        </ul>
      </div>
      <div class="iconright" @click="youhua">
      </div>
    </div>
    </div>
</template>

 
<script>
export default {
  data() {
    return {
      calleft: 0,
      speed:1,
      dataList:[
          {thumb:require('@/assets/css/case/11.jpg'),title:'假日阳光别墅'},
          {thumb:require('@/assets/css/case/12.jpg'),title:'某印刷包装有限公司'},
          {thumb:require('@/assets/css/case/13.jpg'),title:'国际商务中心'},
          {thumb:require('@/assets/css/case/14.jpg'),title:'锦江之星酒店'},
          {thumb:require('@/assets/css/case/15.png'),title:'假日广场'},
          {thumb:require('@/assets/css/case/16.jpg'),title:'新世纪家园别墅'},
          {thumb:require('@/assets/css/case/11.jpg'),title:'假日阳光别墅'},
          {thumb:require('@/assets/css/case/12.jpg'),title:'某印刷包装有限公司'},
      ]
    };
  },
 
  created() {
    this.move();
  },
  mounted() {
    var imgBox = document.getElementsByClassName("imgBoxoul")[0];
      imgBox.innerHTML += imgBox.innerHTML;
  },
//   props: ["dataList"],
  computed: {
    widthData(){
      return 240 * Number(this.dataList.length*2)
    }
  },
  methods: {
    //移动
    move() {
      this.timer = setInterval(this.starmove, 30);
    },
    //开始移动
    starmove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -1150) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopmove() {
      clearInterval(this.timer);
    },
    //点击按钮左移
    zuohua() {
      this.calleft -= 240;//this.speed = 1
      if (this.calleft <= -1200) {
        this.calleft = 0;
      }
    },
    //点击按钮右移
    youhua() { //this.speed = -1
      this.calleft += 240;
      if (this.calleft >= 0) {
        this.calleft = -1200;
      }
    },
    
  }
};
</script>
 
<style>
 
.threeImg {
  position: relative;

}
.threeImg .Containt{
  z-index: 1;
}
.threeImg .Containt ul {
  margin: 0 auto;
  width: 1200Px;
  position: absolute;
  left: 0px;
  cursor: pointer;
  /* height: 100%; */
  height: 400Px;
  z-index: 10000;
}
.threeImg .Containt ul li {
    text-align: center;
  float: left;
  width: 300Px;
  height: 300Px;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
}
 
.threeImg .Containt ul li img {
 width: 300Px;
}
.Containt {
  position: relative;
  /* padding: 60px 0; */
  overflow-y: auto;
  width: 1200Px;
  height: 400px;
  overflow: hidden;
  margin: 0 auto;
}

</style>