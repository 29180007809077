// 二级标题下介绍
<template>
  <div>
    <div class="primary">
      <span>{{ text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>
<style lang="less" scoped>
.primary {
  margin: 0 auto;
  width: 1200Px;
  box-sizing: border-box;
}
span {
  line-height: 30Px;
  font-size: 16Px;
  text-align: justify;
  text-align-last: left;
  width: 100%;
}
</style>