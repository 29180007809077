// 二级标题
<template>
  <div>
    <div class="primary">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="less" scoped>
.primary {
  clear:both;
  text-align: center;
}
h2 {
  font-weight: normal;
  font-size: 28Px;
  color: #000000;
}
</style>