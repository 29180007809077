// 写字楼
<template>
    <div>
        <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt=""><div class="repet"><div class="text">瑞海热控致力于为用户提供节能环保和降低用户取暖价格的新型技术开发和利用。目前，可将空气源热泵、蓄热式发热电缆、低氮全预混燃气锅炉等清洁供热方式，根据用户实际使用环境和配套条件将以上清洁供热方式进行单独或混合配套，一站式从设计、施工、调试、质检、运行、维护、能源端满足用户差异化需求。</div></div>
        </div>
        <Primary :title="Title"></Primary>
        <Subroute></Subroute>
        <div class="bc">
            <Secondary :title='title'></Secondary>
            <van-row type="flex"  justify="space-between">
                <van-col span="10">
                    <div class="main">
        <p>
          采暖面积大、人流密度大，冬季零下低温状态下，写字楼商务中心及商场如没有供暖系统，将大大影响商场顾客率、购买力，很大程度降低收入。地下多配有仓库，仓库有着大量塑料、纸巾等易燃物品，消防安全至关重要。在最低采暖成本下，选择到供暖效果佳、安装便捷性、寿命长等采暖成本问题。
        </p>
            </div>
                </van-col>
                <van-col span="10">
                    <img :src="img1" alt="">
                </van-col>
            </van-row>
            
    </div>
        <Secondary :title='title1'></Secondary>
        <van-row type="flex" justify="space-between">
          <van-col span="10"><img :src='img2'></van-col>
           <van-col span="10">
               <p class="tc">瑞海热控提供的写字楼商务中心综合能源项目定位为能源“源·网·荷·储”一体的示范项目。为写字楼及各类商务中心供能提供安全可靠、稳定节能环保的清洁能源,助力企业绿色低碳转型,实现可持续发展。</p>
           </van-col>
        </van-row>
        <Secondary :title='title2'></Secondary>
       <Pho :IMG='img3'></Pho>
       <van-row type="flex" justify="space-between">
          
           <van-col span="10">
               <p>瑞海解决项目整合电采暖、燃气、空气源等商业分布式能源、储能系统、建筑节能服务等绿色能源综合服务，利用瑞海战略合作的研究中心共同开发的综合能源方案设计软件和综合能源管理平台等技术。提供领先的绿色能源综合解决方案服务，满足企业高效清洁绿色用能要求。同时瑞海热控实现了分布式清洁供暖的突破，有效利用峰谷电差来进行储能式供热，以最低成本实现最大社会效益，实现了对传统技术路线缺陷和不足的弥补和升级。对热能应用行业发展和智能低碳城市建设都具有重大积极意义。瑞海热控坚持变革与创新，打造勇于创新和探索的技术、工程团队，通过利用AI算法提供个性化的节能控制策略，降低能源系统运行维护费用，为客户实现降本增效、节能减排，提高经济效益和社会效益。</p>
           </van-col>
           <van-col span="10"><img :src='img7' class="t"></van-col>
        </van-row>
        <!-- <Print :text='text15'></Print> -->
        <Secondary :title='title5'></Secondary>
        <img v-lazy="img4" class="center">
        <Print :text="text6"></Print>
        <!-- <van-row type="flex" justify="space-between">
           <van-col span="10"><img :src='img4' class="tc"></van-col>
           <van-col span="10">
               <p>瑞海智慧控制系统利用智慧化的管理和应用系统,各类角色应用全面,面向建筑空间管理的决策层管理、物业工程管理、工程管理人员、工程执行人员、客服人员、建筑体关联的使用人员等诸多角色实际需求，实现助力运营效能。通过物联网技术、新设备应用等方式，在降低能源损耗、节省人力投入、降低运维门槛和成本、形成数据资产等多方面赋能，节能降损，达到健康安全的设计初衷，打造绿色写字楼。通过对基于UE4引擎的BIM技术、云计算、物联网等综合运用，搭建数字化三维模型，同时在BIM模型基础上开发VR场景，形成BIM+VR的创新组合软件平台，优化设计方案、提升施工效率、提高运营水平，为项目全生命周期进行数字化实施，发挥最大价值。物联网信息的接入是有效的安全监控手段，深燃大厦综合能源项目充分结合“互联网+”和“大数据”的特点，通过自主开发的综合能源管理平台进行数据收集、统计、分析和反馈，融合BIM+VR 技术，实时对设备动态监测，实现能源站的稳定、高效、安全运维。从管理、使用的角度出发，实现覆盖各专业领域 : 如领导决策层，物业管理层，物业执行层，工单执行层，员工使用层等，并通过物联网、AI、云计算等技术实现智慧化。</p>
           </van-col>
          
        </van-row> -->
        <Secondary :title='title3'></Secondary>
        <van-row type="flex" justify="space-between">
         
           <van-col span="10">
               <p >瑞海智慧控制系统利用智慧化的管理和应用系统,各类角色应用全面,面向建筑空间管理的决策层管理、物业工程管理、工程管理人员、工程执行人员、客服人员、建筑体关联的使用人员等诸多角色实际需求，实现助力运营效能。通过物联网技术、新设备应用等方式，在降低能源损耗、节省人力投入、降低运维门槛和成本、形成数据资产等多方面赋能，节能降损，达到健康安全的设计初衷，打造绿色写字楼。
从管理、使用的角度出发，实现覆盖各专业领域 : 如领导决策层，物业管理层，物业执行层，工单执行层，员工使用层等，并通过物联网、AI、云计算等技术实现智慧化。
通过对基于UE4引擎的BIM技术、云计算、物联网等综合运用，搭建数字化三维模型，同时在BIM模型基础上开发VR场景，形成BIM+VR的创新组合软件平台，优化设计方案、提升施工效率、提高运营水平，为项目全生命周期进行数字化实施，发挥最大价值。物联网信息的接入是有效的安全监控手段，深燃大厦综合能源项目充分结合“互联网+”和“大数据”的特点，通过自主开发的综合能源管理平台进行数据收集、统计、分析和反馈，融合BIM+VR 技术，实时对设备动态监测，实现能源站的稳定、高效、安全运维。
</p>
           </van-col>
            <van-col span="10"><img :src='img5' class="cen"></van-col>
        </van-row>
        <Secondary :title='title4'></Secondary>
        <van-row type="flex" justify="space-between">
          <van-col span="10"><img :src='img6'></van-col>
           <van-col span="10">
               <p class="tc">瑞海热控智慧用电管理解决方案为写字楼商务中心提供预警服务，在提供用电安全保障的同时，也有效降低了写字楼的用电能耗和运营成本，为写字楼带来实际效益。各线路的电流、电压、功率等数据集中实时传输到云平台，通过手机或电脑即可轻松掌控上万条用电线路运行情况，无需现场巡检，提升写字楼管理效率。对各类线路设置了单独或批量的定时开关，做到精细化管理。依托于“电力物联＋大数据+AI”，全方位监测各项电力数据，对用电安全隐患预判分析，降低电气火灾风险。</p>
           </van-col>
           
        </van-row>
    </div>
</template>
<script>
import Subroute from '@/common/Subroute3.vue'
import Primary from '@/common/primary.vue'
import Secondary from '@/common/secondary.vue'
import Pho from '@/common/pho.vue'
import Print from '@/common/print.vue'
export default {
    components:{
       Subroute,
       Primary,
       Secondary,
       Pho,
       Print
    },
    data() {
        return {
            Title:'解决方案',
            title:'写字楼楼宇智能化管理用能特点',
            title1:'能源“源·网·荷·储”一体',
            title2:'绿色能源综合服务',
            title3:'BIM+VR的创新组合软件平台',
            title4:'电管理',
            title5:'综合节能',
            img1:require('@/assets/css/写字楼商务中心/1.jpg'),
            img2:require('@/assets/css/写字楼商务中心/yuanw.png'),
            img5:require('@/assets/css/写字楼商务中心/8.png'),
            img3:require('@/assets/css/写字楼商务中心/1.png'),
            img4:require('@/assets/css/写字楼商务中心/tup.png'),
            img6:require('@/assets/css/智慧能源云平台/5.png'),
            img7:require('@/assets/css/写字楼商务中心/7.jpg'),
            text6:'瑞海智慧控制系统利用智慧化的管理和应用系统,各类角色应用全面,面向建筑空间管理的决策层管理、物业工程管理、工程管理人员、工程执行人员、客服人员、建筑体关联的使用人员等诸多角色实际需求，实现助力运营效能。通过物联网技术、新设备应用等方式，在降低能源损耗、节省人力投入、降低运维门槛和成本、形成数据资产等多方面赋能，节能降损，达到健康安全的设计初衷，打造绿色写字楼。通过对基于UE4引擎的BIM技术、云计算、物联网等综合运用，搭建数字化三维模型，同时在BIM模型基础上开发VR场景，形成BIM+VR的创新组合软件平台，优化设计方案、提升施工效率、提高运营水平，为项目全生命周期进行数字化实施，发挥最大价值。物联网信息的接入是有效的安全监控手段，深燃大厦综合能源项目充分结合“互联网+”和“大数据”的特点，通过自主开发的综合能源管理平台进行数据收集、统计、分析和反馈，融合BIM+VR 技术，实时对设备动态监测，实现能源站的稳定、高效、安全运维。从管理、使用的角度出发，实现覆盖各专业领域 : 如领导决策层，物业管理层，物业执行层，工单执行层，员工使用层等，并通过物联网、AI、云计算等技术实现智慧化。',
        }
    },
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin:auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            background-color: #f56a00;
            color: #fff;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
        }
   }
  
    .img img{
        width: 100%;
    }
    img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.el-row{
  text-align: center;
    width: 1200Px;
    margin: 50Px auto;
    display:flex;
    img{
        width: 100%;
    }
    p{
      font-size: 16Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;
    }
   
.top{
    margin-top: 40Px;
  }
 b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
    }
    
.bc{
    width: 100%;
  background-color: #eff0f2;
  padding: 40Px 0;
    //   width: 1200Px;
      margin: 0 auto;
     
      p{
          margin-top: 50Px;
          line-height: 24Px;
      }
      
}
.van-row{
    width: 1200Px;
    margin: 0 auto;
    .van-col{
        margin-top: 20PX;
        img{
        width: 100%;
        
    }
    p{
        
    }
    }
    .tc{
        margin-top: 12%;
        
    }
    .cen{
        margin-top: 10%;
       
    }
}

// .small{
//   display: block;
//   width: 800Px;
//   margin: 0 auto;
// }
.center{
    width: 1200Px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20Px;
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>