// 电路供暖
<template>
  <div class="container">
    <div class="img">
      <img :src="require('@/assets/css/banner/zhikong.png')" alt="">
            <div class="reph">
                <div class="text">
                    瑞海热控结合十余年的供热开发、设计、运营经验与技术积累，自主研发形成了高集成度的一体化智慧供热管理系统。可对蓄热式发热电缆、空气源热泵、燃气锅炉等多种分布式热源模式进行融合管理，以满足不同应用场景对供热差异化、精细化、阶段式需求。
                </div>
            </div>
    </div>
    
    <Primary :title="title1"></Primary>
    <Subroute4></Subroute4>
    <div class="bc">
    <Secondary :title="title3"></Secondary>
    <div class="dashe">
      <van-row type="flex" justify="space-between">
        <van-col span="12" v-lazy-container="{ selector: 'img' }"><img :data-src='img3'/></van-col>
        <van-col span="10">
          <img :src="require('@/assets/css/智慧能源云平台/dianlu.jpg')" class="rt" alt="">
          <p class="rb">
RH电路供暖智控系统采用4级管控机制。由云端数据平台、楼宇通信设备、楼层通信设备、温度控制设备组成。系统各级数据响应时间小于5毫秒，实时数据采集、记录、回传间隔1分钟。室内每个独立结构内部均安装有温度传感设备，温度感应精度±0.5℃。RH电路供暖智控系统内楼层分组控制器最大负载温控设备63个，有8种供暖模式与6个时间段可供选择。智慧电采暖系统设置有加热保护系统可根据加热类型与负载情况选择3-30分钟加热间隔用以保护发热设备，避免发热设备因过热损毁。
          </p>
        </van-col>
      </van-row>
    </div>
    </div>
    <van-row  type="flex" justify="space-between">
      <van-col  span="12">
        <Chart></Chart>
      </van-col>
      <van-col  span="12" v-lazy-container="{ selector: 'img' }">
        <img :data-src="require('@/assets/css/智慧能源云平台/chart.png')" class="t">
      </van-col>
    </van-row>
    
    <Print :text="text4"></Print>
  </div>
</template>
<script>
import Primary from "@/common/primary.vue";
import Subroute4 from '@/common/Subroute4.vue';
import Secondary from "@/common/secondary.vue";
import Print from "@/common/print.vue";
import Chart from "@/common/chart.vue";
export default {
  components: {
    Primary,
    Subroute4,
    Secondary,
    Print,
    // Pho,
    Chart,
  },
  data() {
    return {
      deviceType: '',
      // videoUrl:require('@/assets/zhikong.mp4'),
      title1: "瑞海智慧供热管理系统",
      text1:
        "瑞海热控结合十余年的供热开发、设计、运营经验与技术积累，自主研发形成了高集成度的一体化智慧供热管理系统。可对蓄热式发热电缆、空气源热泵、燃气锅炉等多种分布式热源模式进行融合管理，以满足不同应用场景对供热差异化、精细化、阶段式需求。",
      title3:'电路供暖智控系统（蓄热式发热电缆）',
      img3:require("@/assets/css/智慧能源云平台/dianlu.png"),
      text4:'系统依托于大数据、云平台可对实时运行数据进行分析、处理、及时修正各项运行参数，以此适配建筑物与相变储热材料的蓄热规律，做到蓄、放热同步，以此精确调控室内温度。可实现平均加热6-8小时即可满足全天供热需求的同时，延长有效供暖时常的一倍以上，相比传统粗放型电地暖效率提升近两倍，节省燃气供暖能源消耗的一半以上，在无需场地、设备、环境等大规模投资建设前提下，提升了整体运行效率。',
    };
  },

};
</script>
<style lang="less" scoped>
.img{
    width: 80%;
    margin: auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        // float: left;

        vertical-align: middle;
    }
    .reph{
         position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        left: 100Px;
        bottom: 0;
       .text{
           box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            // height: 230Px;
            background-color: #f56a00;
            font-size: 22Px;
            line-height: 38Px;
            color: #fff;
            padding: 40Px;
       }
    }
}
video{
        display: block;
        margin: auto;
        max-width: 100%;
}
video{
  pointer-events: none;
}
video::-webkit-media-controls-enclosure {
  display: none;
}
img video::-webkit-media-controls-start-playback-button {
 display: none; 
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.van-col .i{
    font-size: 14Px;
}
.ce{
    width: 800Px;
    margin: 40Px auto;
    display: block;
}
h4{
    width: 1200Px;
    margin: 0 auto;
    height: 60Px;
    text-align: center;
    color: #343434;
    font-size: 18Px;
    font-weight: normal;
    border-bottom: 3Px solid #c8c9cb;
}
h5{
    width: 1200Px;
    margin: 0 auto;
    margin-top: 20Px;
    text-align: center;
    color: #343434;
    font-size: 18Px;
    font-weight: normal;
}
.van-col .e{
    font-size: 14Px;
    margin-top: 25Px;
}
.dashe {
  width: 1200Px;
  margin: 40Px auto;
  box-sizing: border-box;
  border: 2Px dashed #c8c9cb;
  .rt{
   margin-right:50Px;
   margin-top: 20%;
  }
  .rb{
    margin-top: 40Px;
    margin-right: 50Px;
    font-size: 16Px;
    color: #000;
  }
}
.van-row {
  width: 1200Px;
  padding: 30Px 0;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
.img img {
  width: 100%;
}
.van-col img {
  width: 450Px;
}
.IMG {
  padding: 30Px 0;
  background-color: #eff0f2;
  margin: 0 auto;
  width: 1200Px;
}
.IMG p {
  color: #343434;
  font-size: 16Px;
  text-align: center;
}
.IMG img {
  width: 1200Px;
}
.bc {
  width: 100%;
  margin-top: 20Px;
  background-color: #eff0f2;
  padding: 20Px 0;
}
.van-col span {
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
}
.van-col b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
.van-col p {
  font-size: 14Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;
}
.Top {
  margin-bottom: 40Px;
}
.T {
  margin-top: 20Px;
}
.B {
  margin-top: 20Px;
}
.t{
  margin-top: 12%;
}
@media screen and (max-width:1900px) {
    .img {
        width:100%;
    }
}
</style>