// 工业园区
<template>
    <div>
        <div class="pho">
            <img :src="require('@/assets/css/banner/fengche.png')" alt=""><div class="repet"><div class="text">瑞海热控致力于为用户提供节能环保和降低用户取暖价格的新型技术开发和利用。目前，可将空气源热泵、蓄热式发热电缆、低氮全预混燃气锅炉等清洁供热方式，根据用户实际使用环境和配套条件将以上清洁供热方式进行单独或混合配套，一站式从设计、施工、调试、质检、运行、维护、能源端满足用户差异化需求。</div></div>
            <!-- <img :src='require("@/assets/css/banner/1.png")'> -->
        </div>
        <Primary :title="Title"></Primary>
        <Subroute></Subroute>
           <div class="bc">
            <Secondary :title='title'></Secondary>
            <el-row type="flex"  justify="space-between" >
      <el-col :span="10" class="top">
        <span>01</span>
          <b>运行时间有周期性变化</b><p>年运行时间不同,各行业根据设备的检修周期、产品销售情况，季节性影响等因素，年运行时间不同（炼油行业一般8400M/a,化工行业 8000h/a，生物医药行业6000h/a，纺织行业4000h/a）。</p>
          <span>02</span>
          <b>热负荷较大，供热参数较高</b><p>需要热源的温度和负荷不同，一般石化企业、水泥，冶金企业需要的热负荷较大，供热参数较高，纺织、电子行业企业需要的负荷及供热参数较低。</p>
          <span>03</span>
          <b>供热期间需要稳定，不能间断</b><p>企业对供热的依赖程度不同，石化、化工企业供热要求稳定，不能间断，如果供热中断将影响工艺生产，产品性能，甚至发生重大的事故。有些企业如电子行业，本身需求就是间断的，供热负荷有波动对企业影响很小。</p>
          </el-col>
      <el-col :span="10"><img :src="img1" alt="" class="t"></el-col>
    </el-row>
    </div>
        <Secondary :title='title2'></Secondary>
        <van-row type="flex" justify="space-between">
            <van-col span="10"><img :src='img5'></van-col>
           <van-col span="10">
               <p class="i">瑞海方案将厂区的电力、蒸汽、压缩空气及制冷等各能源系统标准物联接入泛能网平台，实现电、热/蒸汽、压缩空气等多种能源系统的互联互通，为用户提供数字化图表和小时、日、月多维度能源报表，准确掌握用能数据。通过手机APP即可实时在线监测空气源热泵、锅炉、空压、制冷等能源设备运行情况，诊断压力、温度等运行参数和指标情况，及时发现安全、能耗等方面异常,帮助企业按需及时进行设备运维，降低故障率，实现安全平稳运行，释放节能空间。泛能网基于海量能源数据和70多个针对能源设备的AI算法模型，精准分析空压、制冷等系统运行情况，智能推送优化策略和建议，助力设备高效运行。</p>
           </van-col>
        </van-row>
        <!-- <Pho :IMG='img2'></Pho>
        <Print :text='text5'></Print> -->
        <Secondary :title='title3'></Secondary>
        <van-row type="flex" justify="space-between">
            
           <van-col span="10">
               <p class="mt">现有的清洁能源采暖方式中，热泵采暖技术具有节能环保、能效比高、运行稳定的优势，然而常规的水/地源热泵不具备抽水打井条件或地埋管场地、低环温空气源热泵不能满足暖气片高温供水等问题。由于配电容量有限，电锅炉蓄热难以增容，而燃气接管费较高、需要符合消防安全的专用机房。</p>
           </van-col>
           <van-col span="10"><img :src='img2'></van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
            <van-col span="10"><img :src='img3'></van-col>
           <van-col span="10">
               <p class="mt">瑞海方案采用蓄联热泵技术方案，通过空气源热泵和相变蓄能技术耦合,为温度提升热泵提供热源，以“双级耦合、多能互补”的方式实现供水温度65℃，通过综合技术创新有效地突破了单一技术运用的客观限制，实现了系统综合能效提升30%左右。在低温条件下稳定性、经济性、可靠性等诸多方面优势显著。</p>
           </van-col>
        </van-row>
        
        <!-- <Print :text='text13'></Print> -->
        <Secondary :title='title4'></Secondary>
        <div class="buto">
        <span>01</span><b>供热可靠性提升</b>
        <p>
          将蓄能技术和热泵技术,交叉互联综合利用,克服各自的限制和性能弱点，拓展了各自使用条件边界，规避了环温降低和结霜化霜的衰减影响和水温波动，实现60℃至65℃稳定供水，室内采暖效果良好。
        </p>
        <span>02</span><b>运行经济性显著</b>
        <p>
          通过综合技术创新有效地突破了单一技术运用的客观限制，实现了系统综合能效提升。系统升温快、效率高，蓄热装置发挥蓄能调节和能量补偿优势，实现采暖费相比原采暖收费标准大幅降低。
        </p>
        <span>03</span><b>维修保养大幅减少</b>
        <p>
          该系统中空气源热泵出水工况改变，压缩机始终处在高效、稳定的区间运行，设备寿命提高、故障率降低。智能远程控制实现了全采暖季数据采集、远程运维，除开机调试和停电原因到场外，基本实现现场“零维保”。
        </p>
        <span>04</span><b>供热改造投资省</b>
        <p>
          该项目通过双级耦合、相变蓄能的方式，有效利用配电余量，在不增容前提下实现了供热设施的替换。项目采用整体式机房，设备及工程安装的单位面积投资低于单一空气源热泵系统的投资。
        </p>
        <span>05</span><b>无污染、零排放</b>
        <p>
          采用清洁电力为驱动，立足先进节能技术，低成本、高能效、零排放的解决能源供应问题，具备良好的经济价值和环保效益，对“推进清洁能源、减少雾霾天气”有着积极的示范意义。
        </p>
      </div>
    </div>
</template>
<script>
import Subroute from '@/common/Subroute3.vue'
import Primary from '@/common/primary.vue'
import Secondary from '@/common/secondary.vue'
export default {
    components:{
       Subroute,
       Primary,
       Secondary,
    },
    data() {
        return {
            Title:'解决方案',
            title:'工业园区多楼宇综合供热特点',
            title2:'数字能源管理方案',
            title3:'工业园区空气源热泵采暖方案',
            img1:require('@/assets/css/工业园区/1.jpg'),
            img3:require('@/assets/css/工业园区/2.png'),
            img2:require('@/assets/css/工业园区/3.png'),
            img4:require('@/assets/css/空气源/1.png'),
            img5:require('@/assets/css/工业园区/shuzi.jpg'),
            img6:require('@/assets/css/工业园区/cainuan.png'),
            text13:'采用蓄联热泵技术方案，通过空气源热泵和相变蓄能技术耦合,为温度提升热泵提供热源，以“双级耦合、多能互补”的方式实现供水温度65°℃，通过综合技术创新有效地突破了单一技术运用的客观限制，实现了系统综合能效提升30%左右。在低温条件下稳定性、经济性、可靠性等诸多方面优势显著。',
            title4:'空气源热泵采暖方案优势',
        }
    },
}
</script>
<style lang="less" scoped>
.pho{
    width: 80%;
    margin:auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    }
   .repet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // left: 0;
        right: 120Px;
        bottom: 0;
        .text{
            box-shadow: 10Px 10Px 3px 2px #f56a00d5;
            width: 450Px;
            background-color: #f56a00;
            font-size: 22Px;
            line-height: 38Px;
            padding: 40Px;
            color: #fff;
        }
   }
  
.van-row{
  width: 1200Px;
  margin: 20Px auto;
  img{
    width: 100%;
  }
  p{
    margin-top: 15%;
  }
  .i{
    margin-top: 20Px;
  }
}
    .img img{
        width: 100%;
    }
       .el-row{
    width: 1200Px;
    margin: 50Px auto;
    display:flex;
    img{
        width: 100%;
    }
    p{
      font-size: 16Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;
    }
     span {
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
  
}
.top{
    margin-top: 10Px;
  }
 b {
  margin-left: 10Px;
  font-size: 22Px;
  color: #343434;
  font-weight: normal;
}
    }
.bc{
  background-color: #ffffff;
  padding: 40Px 0;
  .main{
      width: 1200Px;
      margin: 0 auto;
  }
}
.el-row{
    width: 1200Px;
    margin: 50Px auto;
    display:flex;
    img{
        width: 100%;
    }
    .t{
      margin-top: 22%;
    }
    p{
      font-size: 16Px;
  color: #575757;
  text-align-last: left;
  text-align: justify;
    }
     span {
         margin-left: 70Px;
  font-weight: 800;
  font-size: 50Px;
  color: #c8c9cb;
  
}
}
.buto{
    width: 1200Px;
    margin: 0 auto;
    text-align: center;
    span{
      
        font-weight: 800;
        font-size: 50Px;
        color: rgb(216, 216, 216);
        margin-left: 60px;
    }
    p{
      text-align: left;
    }
    b{
      display: inline-block;
    font-weight: normal;
    font-size: 22Px;
    margin-left: -20Px;
    }
}
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>